import React from 'react'
import {
  FormControl,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  makeStyles,
  useTheme,
  Box
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography/Typography'
import dotIcon from '../../../assets/images/dot_icon.png'
import { validateCategory } from '../../../utils/formValidators/serviceFormValidators'

const GenderList = [
  { id: 'male', title: 'Male' },
  { id: 'female', title: 'Female' },
  { id: 'other', title: 'Other' },
  { id: 'unknown', title: 'Unknown' }
]

export interface IProps {
  name?: string
  label: string
  type?: any
  value?: any
  error?: null | undefined
  onChange?: any
  options?: any
  required: boolean
  width?: number
}

export const WelloSelectForm: React.FC<IProps> = ({
  name,
  label,
  type,
  value,
  error,
  onChange,
  options,
  width,
  required
}: IProps) => {
  return (
    <FormControl
      variant='outlined'
      {...(error && { error: true })}
      size='small'
    >
       <Box py={1} px={0.25} display='flex' flexDirection='row'>
      <Typography
        style={{
          color: useTheme().palette.text.primary,
          textTransform: 'uppercase'
        }}
        variant='subtitle2'
      >
        &nbsp;&nbsp;{label} &nbsp;
        {value !== undefined && value.length > 0 && (
          <img src={dotIcon} alt='icon' height='10'></img>
        )}
      </Typography>
      </Box>
      <MuiSelect
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        required={false}
        style={{
          borderRadius: 6,
          color: useTheme().palette.text.primary,
          maxWidth: 480,
          // height: 38,
          width: width,
          fontFamily: 'Open Sans',
          fontSize: 12,
          fontWeight: 600,
          lineHeight: 'normal',
          backgroundColor: useTheme().palette.common.white
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        displayEmpty
      >
        <MenuItem dense value=''>
          {type}
        </MenuItem>
        {options.map((option: any) => (
          <MenuItem dense key={option.id} value={option.id} id={`${option.id}gender`}>
            {option.title}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}
