import React from 'react'
import {
  FormControl,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  makeStyles,
  useTheme,
  Box
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography/Typography'
import dotIcon from '../../../assets/images/dot_icon.png'
import { R4 } from '@ahryman40k/ts-fhir-types'


export interface IProps {
  name?: 'gender'
  label: string
  type?: any
  value?: any
  error?: null | undefined
  onChange?: any
  options?: any
  required: boolean
  width?: number,
  genderList?:string[]
}

const useStyles = makeStyles((theme) => ({
  select: {
    margin: theme.spacing(1),
    minWidth: 145,
    width: 'auto'
  },
  label: {
    color: theme.palette.grey[50],
    fontSize: '0.8em'
  },

  inputBoxStyle: {
    borderRadius: 6,
    color: theme.palette.grey[50],
    height: 38,
    fontSize: 13,
    backgroundColor: theme.palette.common.white
  }
}))

export const WelloSelectGender: React.FC<IProps> = ({
  name,
  label,
  type,
  value,
  error,
  onChange,
  options,
  width,
  required,
  genderList
}: IProps) => {
  const classes = useStyles()
  const genderData:string[] =genderList ? genderList:[]
  const GenderList: R4.ICoding[] = [
    {
      id: 'female',
      display: genderData.length >0 ? genderData[0]:'Female',
      system: 'http://hl7.org/fhir/ValueSet/administrative-gender'
    },
    {
      id: 'male',
      display: genderData.length >0 ? genderData[1]:'Male',
      system: 'http://hl7.org/fhir/ValueSet/administrative-gender'
    },
   
    {
      id: 'other',
      display: genderData.length >0 ? genderData[2]:'Other',
      system: 'http://hl7.org/fhir/ValueSet/administrative-gender'
    },
    {
      id: 'unknown',
      display:  genderData.length >0 ? genderData[3]:'Unknown',
      system: 'http://hl7.org/fhir/ValueSet/administrative-gender'
    }
  ]
  
  return (
    <FormControl
      variant='outlined'
      size='small'
      {...(error && { error: true })}
      // className={classes.select}
    >
       <Box py={1} px={0.25} display='flex' flexDirection='row'>
      <Typography
        style={{
          color: useTheme().palette.text.primary,
          fontFamily: 'Open Sans',
          fontSize: 12,
          fontWeight: 600,
          lineHeight: 'normal',
        }}
        variant='subtitle2'
      >
        &nbsp;&nbsp;{label} &nbsp;
        {value !== undefined && value.length > 0 && (
          <img src={dotIcon} alt='icon' height='10'></img>
        )}
      </Typography>
      </Box>
      <MuiSelect
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        required={false}
        style={{
          borderRadius: 6,
          color: useTheme().palette.text.primary,
          maxWidth: 480,
          // height: 38,
          width: width,
          fontSize: 13,
          backgroundColor: useTheme().palette.common.white
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        displayEmpty
      >
        <MenuItem dense value=''>
          {type}
        </MenuItem>
        {GenderList.map((gender: any) => (
          <MenuItem dense key={gender.id} value={gender.id}>
            {gender.display}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}
