/* eslint-disable react/jsx-key */
import React from 'react'
import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  checked: {
    '&, & + $label': {
      color: useTheme().palette.primary.main
    }
  },
  label: {
    color: '#100e7b',
    fontSize: 14,
    fontFamily: 'Open Sans',
    fontWeight: 'normal'
  }
}))

export interface IProps {
  name: string
  label?: string
  value?: any
  onChange?: any
  items: any
}

export const WelloRadioGroup: React.FC<IProps> = ({
  name,
  label,
  value,
  onChange,
  items
}: IProps) => {
  const classes = useStyles()
  return (
    <FormControl>
      <Typography
        variant='subtitle2'
        style={{
          color: useTheme().palette.common.black,
          textTransform: 'uppercase'
        }}
      >
        {label} &nbsp;
      </Typography>
      <MuiRadioGroup
        name={name}
        value={value}
        onChange={onChange}
        defaultValue={items[0].id}
      >
        {items.map((item: any) => (
          <Box flexDirection='row'>
            <FormControlLabel
              classes={{
                label: classes.label
              }}
              key={item.id}
              id={name}
              value={item.id}
              onClick={() => {
                alert(item.id)
              }}
              control={
                <Radio
                  disableRipple
                  color='primary'
                  size='small'
                  classes={{
                    checked: classes.checked
                  }}
                />
              }
              label={item.title}
            />
            {item.title === 'Male' ? (
              <TextField
                error
                id='standard-error'
                label='Error'
                defaultValue='Hello World'
              />
            ) : (
              <span></span>
            )}

            {item.title === 'Female' ? (
              <TextField
                error
                id='standard-error'
                label='Error'
                defaultValue='Hello World'
              />
            ) : (
              <span></span>
            )}
          </Box>
        ))}
      </MuiRadioGroup>
    </FormControl>
  )
}
