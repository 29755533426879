import React from 'react'
import { makeStyles, Grid } from '@material-ui/core'

import { DisplayTab } from './DisplayTab'

interface TabProps {
  preSelectedTab?: string
  onTabChange: (selectedTab: string) => void
}

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 2
  },
  display: {
    padding: theme.spacing(1)
  }
}))
const menu = ['Grid', 'Cards']

export const DisplayTabs: React.FC<TabProps> = ({
  preSelectedTab,
  onTabChange
}: TabProps) => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = React.useState(preSelectedTab)

  const handleChange = (selectedTab: string) => {
    onTabChange(selectedTab)
    setSelectedTab(selectedTab)
  }

  return (
    <div className={classes.title}>
      <Grid container direction='row' justify='center' alignItems='center'>
        {menu.map((value, i) => (
          <Grid item className={classes.display} key={value}>
            <DisplayTab
              textTitle={value}
              isSelectd={selectedTab === value}
              onClick={() => handleChange(value)}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
