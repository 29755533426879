import React from 'react'
import { makeStyles, FormControl, useTheme,Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

export interface IProps {
  name: string
  label: string
  type?: any
  icon?: any
  value?: any
  error?: null | undefined
  onChange?: any
  required: boolean
  fileType?: any
  [other: string]: any
  style?: any
}

const useStyles = makeStyles((theme) => ({
  label: {
    color: useTheme().palette.text.primary,
    textTransform: 'uppercase'
  },
  distance: {
    marginTop: 10
  },
  fileInput: {
    marginTop: 10,
    color: useTheme().palette.primary.main
  },
  labelProps: {
    display: 'none',
    padding: 2,
    cursor: 'pointer'
  }
}))

export const WelloInputFile: React.FC<IProps> = ({
  name,
  label,
  type,
  value,
  error,
  onChange,
  fileType,
  icon,
  other,
  style
}: IProps) => {
  const classes = useStyles()
  return (
    <FormControl {...(error && { error: true })}>
      <Box paddingLeft={1.5}>
      <Typography
        style={{
          color: useTheme().palette.text.primary,
          textTransform: 'uppercase'
        }}
        variant='subtitle2'
      >
       {label}
      </Typography>
      </Box>
      <input
        variant='outlined'
        name={name}
        id='upload-photo'
        value={value}
        type='file'
        required={false}
        className={classes.labelProps}
        accept={type}
        onChange={onChange}
        {...other}
        {...(error && { error: true, helperText: error })}
      />

      <label htmlFor='upload-photo'>
        <Box display='flex'>
       <Box p={0.8}>{icon}</Box> 
       <Box><Typography className={classes.fileInput} variant='subtitle2'>
          {fileType}
        </Typography></Box>
        </Box>
        
      </label>
      <label htmlFor='error'>
        <Box paddingTop={1}>
        <Typography
          style={{
            color: useTheme().palette.error.main,
            marginLeft: 5
          }}
          variant='subtitle2'
        >
          {error}
        </Typography>
        </Box>
      </label>
    </FormControl>
  )
}
