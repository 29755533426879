/* eslint-disable react/jsx-key */
import React from 'react'
import { makeStyles, Typography, Grid } from '@material-ui/core'
import { PatientProfileCard } from './PatientProfileCard'
import { R4 } from '@ahryman40k/ts-fhir-types'
import moment from 'moment'
import {
  getNameOfPatient,
  getProfilePicPatient
} from '../../../utils/fhirHelpers/patientHelper'

interface patientListProps {
  patientData: R4.IPatient[]
  date?: string
}

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1
  },
  display: {
    padding: theme.spacing(1)
  },
  paperNotification: {
    padding: 3,
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  root: {
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    minWidth: 1305,
    minHeight: 500
  }
}))

function getAge(value: string): string {
  return moment().diff(moment(value, 'DD-MM-YYYY'), 'years') + 'y'
}

export const PatientDataGrid: React.FC<patientListProps> = ({
  patientData,
  date
}: patientListProps) => {
  const classes = useStyles()

  return (
    <div>
      <Typography align='left' variant='h6' color='textSecondary'>
        &nbsp;&nbsp;{date}{' '}
        <span className={classes.paperNotification}>
          {' '}
          {patientData.length}{' '}
        </span>
      </Typography>

      {/* <Grid container direction='row'>
        {patientData.map((value, i) => (
          <Grid item className={classes.display} key={value.id}>
            <PatientProfileCard
              patientHealthInfo='Normal Health'
              patientName={getNameOfPatient(patientData[i])}
              gender={patientData[i].gender}
              profileImage={getProfilePicPatient(patientData[i])}
              age={getAge(patientData[i].birthDate)}
            />
          </Grid>
        ))}
      </Grid> */}
    </div>
  )
}
