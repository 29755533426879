import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper } from '@material-ui/core'
interface TopProps {
  count?: number
  onSelectChange: (count: number) => void
}
const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1
  },
  display: {
    padding: theme.spacing(1),
    width: 15
  },
  formControl: {
    margin: theme.spacing(0),
    backgroundColor: '#4543d9',
    textAlign: 'center'
  },
  inlay: {
    display: 'block',
    height: 24,
    width: 54,
    position: 'relative'
  },

  underline: {
    '&&&:before': {
      borderBottom: 'none',
      alignContent: 'center',
      textAlign: 'center'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  }
}))

export const TopbarLogout: React.FC = () => {
  const classes = useStyles()

  return <Paper className={classes.inlay} variant='outlined'></Paper>
}
