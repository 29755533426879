import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  TextField,
  Typography,
  TextFieldProps,
  Chip,
  Checkbox
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import React, { useState } from 'react'
import WelloFormItemLabel from './WelloFormItemLabel'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { CloseSharp } from '@material-ui/icons'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

interface WelloMultiSelectFhirCodingProps {
  title: string
  textProps: TextFieldProps
  selectedValues: R4.ICoding[]
  availableOptions: R4.ICoding[]
  isInEditMode: boolean
  placeHolderString: string
  singleWord: string
  pluralWord: string
  displayDoneLabel?: boolean
}

export const WelloMultiSelectFhirCoding: React.FunctionComponent<WelloMultiSelectFhirCodingProps> = (
  props: WelloMultiSelectFhirCodingProps
) => {
  const [selectedItems, setSelectedItems] = useState<R4.ICoding[]>(
    props.selectedValues
  )

  function removeItem(item: R4.ICoding) {
    const tempSelectedItems: R4.ICoding[] = selectedItems.filter(
      (selectedItem) => {
        return selectedItem.code !== item.code
      }
    )

    setSelectedItems(tempSelectedItems)
  }
  return (
    <Box display={'flex'} flexDirection='column' flexGrow={1}>
      <WelloFormItemLabel
        title={props.title}
        displayDoneLabel={props.displayDoneLabel}
      />
      <Box>
        <Autocomplete
          fullWidth
          loadingText={'Searching'}
          multiple={true}
          disableCloseOnSelect
          limitTags={2}
          id={'id_auto_comp_' + props.singleWord}
          value={selectedItems}
          renderTags={(value: R4.ICoding[]) => {
            return (
              <Typography>
                {value.length > 1
                  ? 'Selected ' + value.length + ' ' + props.pluralWord
                  : 'Selected ' + value.length + ' ' + props.singleWord}
              </Typography>
            )
          }}
          options={props.availableOptions}
          ListboxProps={{ id: '' }}
          renderOption={(option, { selected }) => {
            return (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  color='primary'
                />
                <Typography id={'auto_complete_id_' + option.code}>
                  {option.display}
                </Typography>
              </React.Fragment>
            )
          }}
          getOptionLabel={(option) => option.display ?? ''}
          onChange={(event, value) => {
            if (value as R4.ICoding[]) {
              setSelectedItems(value as R4.ICoding[])
            }
          }}
          renderInput={(params) => {
            return (
              <TextField
                variant='outlined'
                {...params}
                size='small'
                id={'tv_for_auto_comp_' + props.singleWord}
                placeholder={
                  selectedItems.length > 0 ? '' : props.placeHolderString
                }
              />
            )
          }}
        />
      </Box>
      <Box display='flex' flexDirection='row' justifyContent='wrap' py={1}>
        {selectedItems.map((item) => {
          return (
            <Box key={'key_' + item.code} paddingRight={1}>
              {' '}
              <Chip
                id={'chip_selected_item_' + props.singleWord + '_' + item.code}
                label={item.display}
                variant='default'
                deleteIcon={
                  <CloseSharp
                    id={
                      props.singleWord +
                      'selected_item_' +
                      item.code +
                      '_delete_icon'
                    }
                    color='primary'
                  ></CloseSharp>
                }
                style={{
                  borderRadius: '4px'
                }}
                onDelete={() => {
                  removeItem(item)
                }}
              ></Chip>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
