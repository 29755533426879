/* eslint-disable */
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { Alert } from '@material-ui/lab'
import React, { useState } from 'react'
import { Controls } from '../PrmitiveComponent/component/Controls'
import { AppType } from '../Schedular/AppType'
import { TimeTabs } from '../Schedular/TimeTabs'
import { DoctorSelectWithSearch } from './DoctorSearch'
import { SettingsMenus } from './SettingsMenus'

export interface IUnitAdmin {
  children?: React.ReactNode
  menu?: string[]
}

const data = [
  { id: 109, name: 'Dr. Ram Kapoor', schedule: 2 },
  {
    id: 370,
    name: 'Dr. Sanjay Kapoor',
    schedule: 0
  }
]

const checkMenu = ['People', 'Cohorts']
const repeats = ['Yes', 'No']
const appType = ['In-Person', 'Phone', 'Video']
const workingHoursHomenu = ['profile', 'working hours']
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '8px',
    height: '100vh'
  },
  Paper: {
    maxHeight: '100%',
    height: '100%',
    backgroundColor: '#dcdcdc'
  },
  tablePaper: {
    maxHeight: '100%',
    height: '100%',
    scrollbarWidth: 'auto',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },

  image: {
    width: 60,
    height: 60
  }
}))

export const Settings: React.FC = () => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = React.useState(checkMenu[0])
  const [selectedRepeat, setSelectedRepeat] = React.useState(repeats[1])
  const [settingsTab, setSelectedSettingsTab] = React.useState(
    workingHoursHomenu[0]
  )
  const [openPopup, setOpenPopup] = useState(false)
  const [recordForEdit, setRecordForEdit] = useState(null)
  const [expertsList, SetExpertsList] = React.useState<any[]>([])
  const [type, setType] = React.useState<any[]>([appType[0]])
  const [selectedDoctor, setSelectedDoctor] = React.useState<any>()
  let availableOptions = [appType[0]]
  const handleChangeTab = (selectedTab: string) => {
    setSelectedTab(selectedTab)
  }

  const handleSettingsChangeTab = (settingsTab: string) => {
    setSelectedSettingsTab(settingsTab)
  }

  const handleApptype = (settingsTab: string) => {
    availableOptions.push(settingsTab)
    // alert(availableOptions.sort())
    setType(availableOptions.sort())
    alert(type.sort())
  }

  const handleRepeateChangeTab = (selectedRepeat: string) => {
    // setType(selectedRepeat)
  }

  const onCancel = (value: boolean) => {
    setOpenPopup(value)
  }

  return (
    <Box width='100%'>
      <Box display='flex' flexDirection='row'>
        <Box width={1 / 5} style={{ overflow: 'auto' }} flexGrow>
          <Paper className={classes.Paper}>
            <Grid>
              <Grid item>
                <Typography
                  variant='h6'
                  color='primary'
                  style={{ marginLeft: 10 }}
                >
                  Settings
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='subtitle2' style={{ marginLeft: 10 }}>
                  HealthVille
                </Typography>
              </Grid>
              <br></br>
              <Grid item>
                <SettingsMenus
                  preSelectedTab={selectedTab}
                  onTabChange={(e) => handleChangeTab(e)}
                  menu={checkMenu}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Box>
          {' '}
          <Divider
            orientation='horizontal'
            flexItem
            style={{ width: 2, color: 'black' }}
          />
        </Box>
        <Box p={0.3} width={1 / 4} style={{ overflow: 'none' }}>
          <Paper style={{ background: useTheme().palette.background.default }}>
            <DoctorSelectWithSearch
              onValuesChanged={(doctor) => {
                setSelectedDoctor(doctor)
                alert(doctor)
              }}
              displayField='name'
              valueField='id'
              // isSelected={true}
              availableOptions={data}
              selectedSchedule={0}
            />
          </Paper>
        </Box>
        <Box
          p={1}
          flexGrow={1}
          flexDirection='column'
          // style={{ overflow: 'auto' }}
        >
          <Paper style={{ backgroundColor: '#ffffff', opacity: 0.8 }}>
            <Grid container direction='row'>
              <Grid item xs={4}>
                <Grid container direction='column' justify='flex-start'>
                  <Grid item xs>
                    <Box p={1}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <Avatar
                          className={classes.image}
                          alt='Profile'
                          src='test.jpg'
                        />{' '}
                        &nbsp;
                        <Grid item xs>
                          <Typography
                            variant='h4'
                            color='primary'
                            style={{ marginLeft: 5 }}
                          >
                            {' '}
                            Dr. Kundan Jha
                          </Typography>
                        </Grid>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container direction='column' justify='flex-start'>
                  <Grid item xs style={{ marginTop: 20 }}>
                    <TimeTabs
                      preSelectedTab={settingsTab}
                      onTabChange={(e: any) => handleSettingsChangeTab(e)}
                      menu={workingHoursHomenu}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid
                  container
                  justify='flex-end'
                  style={{ marginLeft: 50, marginTop: 20 }}
                >
                  <Controls.WelloButton
                    type='button'
                    text='Add Working Hours'
                    size='small'
                    backgroundColor='#4543d9'
                    color='#ffffff'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Box p={1}>
            <Alert
              onClose={() => {}}
              severity='warning'
              style={{ backgroundColor: '#ffffff', opacity: 0.8 }}
            >
              <Typography
                variant='h4'
                style={{ color: useTheme().palette.common.black }}
              >
                Draw over the time slots by pressing and holding your mouse and
                select availability schedule.
              </Typography>
            </Alert>
          </Box>

          <Box p={1} id='calendarView' style={{ overflow: 'none' }}>
            <Grid container direction='row'>
              <Grid item>
                <AppType
                  menuLabel='Appointment Type'
                  preSelectedOptions={type}
                  onTabChanges={(e: any) => handleApptype(e)}
                  menu={appType}
                  onValuesChanged={(selectedOptions) => {
                    alert(selectedOptions)
                  }}
                />
              </Grid>
              <Grid item>
                <Grid container direction='column' justify='flex-start'>
                  <Grid item xs>
                    {/* <AppType
                      menuLabel='Repeats'
                      preSelectedTab={selectedRepeat}
                      onTabChange={(e: any) => handleRepeateChangeTab(e)}
                      menu={repeats}
                    /> */}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container direction='column' justify='flex-end'>
                  <Grid item>
                    <Tooltip title='Settings'>
                      <IconButton aria-label='Settings'>
                        <ArrowForwardIcon
                          style={{
                            marginLeft: 222,
                            marginTop: 13,
                            color: useTheme().palette.common.black
                          }}
                          onClick={() => {
                            // navigateToPath("/settings");
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* <Calendar
              onCalendarSelectionChanged={(selectedValues: any[]) => {
                alert(selectedValues.length)
              }}
            ></Calendar> */}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
