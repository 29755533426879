import React from 'react'
import {
  Dialog,
  DialogTitle,
  makeStyles,
  Paper,
  Typography,
  Grid,
  ThemeProvider,
  DialogActions,
  DialogContent,
  useTheme
} from '@material-ui/core'
import { theme } from '../../../Common/Theme/theme'

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    maxWidth: 1325,
    maxHeight: 'auto',
    // backgroundColor: '#ffffff',
    variant: 'outlined'
    // scrollbarWidth: 'auto',
    // overflow: 'scroll',
    // '&::-webkit-scrollbar': {
    //   display: 'none'
    // }
  },
  root: {
    flexGrow: 1
  },
  TopPaper: {
    height: 20,
    backgroundColor: theme.palette.primary.main
  },

  topHeader: {
    paddingTop: theme.spacing(1),
    color: theme.palette.common.white
  }
}))

export default function Popup(props: any) {
  const { title, children, openPopup, setOpenPopup } = props
  const classes = useStyles()
  const handleClose = () => {
    setOpenPopup(false)
  }

  return (
    // <ThemeProvider theme={theme}>
    <Dialog
      open={openPopup}
      classes={{ paper: classes.paperContainer }}
      onClose={handleClose}
      disableBackdropClick
    >
      <DialogTitle
        id='scroll-dialog-title'
        style={{
          backgroundColor: useTheme().palette.primary.main,
          color: useTheme().palette.common.white,
          alignContent: 'center',
          textAlign: 'center',
          height: 20,
          paddingTop: 5
        }}
      >
        <Grid container justify='center' spacing={0}>
          <Typography variant='h6' className={classes.topHeader}>
            {props.title}{' '}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: useTheme().palette.background.default,
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
    // </ThemeProvider>
  )
}
