import { Box, TextField, Typography, TextFieldProps } from '@material-ui/core'

import React from 'react'
import WelloFormItemLabel from './WelloFormItemLabel'

interface WelloTextFieldProps {
  title: string
  textProps: TextFieldProps
  displayDoneLabel?: boolean
}





export const WelloTextField: React.FunctionComponent<WelloTextFieldProps> = (
  props: WelloTextFieldProps
) => {
  return (
    <Box display={'flex'} flexDirection='column' flexGrow={1}>
      <WelloFormItemLabel
        title={props.title}
        displayDoneLabel={props.displayDoneLabel}
      />
      <Box>
        <TextField
          variant='outlined'
          fullWidth
        
          size='small'
          {...props.textProps}
        ></TextField>
      </Box>
    </Box>
  )
}
