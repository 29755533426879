import React from 'react'
import { makeStyles, Box, Typography, useTheme } from '@material-ui/core'
import { Controls } from '../PrmitiveComponent'

interface IProps {
  isSelected?: boolean
  textTitle?: string
  onClick?: () => void
}
const useStyles = makeStyles((theme) => ({}))

export const AppointmentTypeMenu: React.FC<IProps> = ({
  isSelected = true,
  textTitle = 'text',
  onClick
}: IProps) => {
  const classes = useStyles()

  return (
    <div onClick={onClick}>
      {isSelected ? (
        <div>
          <Controls.WelloButtonTab
            type='button'
            text={textTitle}
            size='small'
            variant='outlined'
            backgroundColor='#ffffff'
            color='#3c3c3c'
          />
        </div>
      ) : (
        <Box p={1}>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 600,
              fontFamily: 'Open Sans Regular'
            }}
          >
            {textTitle}
          </Typography>
        </Box>
      )}
    </div>
  )
}
