import React from 'react'
import {
  FormControl,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  makeStyles,
  useTheme
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography/Typography'
import dotIcon from '../../../assets/images/dot_icon.png'



export interface IProps {
  name?: 'occupation'
  label: string
  type?: any
  value?: any
  error?: null | undefined
  onChange?: any
  options?: any
  required: boolean
  width?: number
  occupations:any
}

export const WelloOccupation: React.FC<IProps> = ({
  name,
  label,
  type,
  value,
  error,
  onChange,
  options,
  width,
  occupations,
  required
}: IProps) => {
  return (
    <FormControl
      variant='outlined'
      {...(error && { error: true })}
      size='small'
    >
      <Typography
        style={{
          color: useTheme().palette.text.primary,
          textTransform: 'uppercase'
        }}
        variant='subtitle2'
      >
        &nbsp;&nbsp;{label} &nbsp;
        {value !== undefined && value.length > 0 && (
          <img src={dotIcon} alt='icon' height='10'></img>
        )}
      </Typography>
      <MuiSelect
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        required={false}
        style={{
          borderRadius: 6,
          color: useTheme().palette.text.primary,
          maxWidth: 480,
          // height: 38,
          width: width,
          fontSize: 13,
          backgroundColor: useTheme().palette.common.white,
          padding: 2
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        displayEmpty
      >
        <MenuItem value='' disabled>
          {type}
        </MenuItem>
        {occupations.map((occupation: any) => (
          <MenuItem key={occupation.id} value={occupation.id}>
            <div style={{ display: 'inline-flex' }}>
              &nbsp; {occupation.title}
            </div>
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}
