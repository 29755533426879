import {
  AppBar,
  Badge,
  Box,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Toolbar,
  useTheme
} from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase'
import MailIcon from '@material-ui/icons/Mail'
import SearchIcon from '@material-ui/icons/Search'
import SettingsIcon from '@material-ui/icons/Settings'
import React from 'react'
import { AccountDropDown } from '../HomePage/AccountDropDown/AccountDropDown'
import { AppointmentList } from '../HomePage/Appointments/AppointmentList'
import { PatientsList } from '../HomePage/Patients/PatientsList'
import { AppBarTabs, TabDetail } from './Components/AppBarTabs'
import Popup from '../../PrmitiveComponent/component/Helper/Popup'
import { CreateDoctorSchedule } from '../HomePage/Appointments/CreateDoctorSchedule'
import { Settings } from '../../Settings/Settings'

export interface IhomePageProps {
  appTitle: string
  logOutLabel: string
  userName?: string
  menu: TabDetail[]
  user: any[]
  children?: React.ReactNode
  onTabChange: (selectedTab: string) => void
  selected?: string
  count: number
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },

  pageStyle: {
    padding: theme.spacing(2),
    color: theme.palette.background.default
  },

  topPaper: {
    display: 'inline',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },

  search: {
    position: 'relative',
    borderRadius: 6,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white'
    },
    marginLeft: 0,

    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },

  searchIcon: {
    padding: theme.spacing(0, 1),
    marginTop: 10,
    height: '70%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#5f6061'
  },
  inputRoot: {
    color: 'inherit'
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1.3em + ${theme.spacing(2)}px)`,
    transition: theme.transitions.create('width'),

    borderRadius: '1',
    [theme.breakpoints.up('sm')]: {
      width: '15ch',
      '&:focus': {
        width: '15ch'
      }
    }
  }
}))
export const HomePage: React.FC<IhomePageProps> = ({
  appTitle,
  logOutLabel,
  userName,
  menu,
  count,
  user,
  onTabChange
}: IhomePageProps) => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = React.useState(menu[0].name)
  // const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  // const [anchorEl, setAnchorEl] = React.useState();

  const handleChangeTab = (selectedTab: string) => {
    onTabChange(selectedTab)
    setSelectedTab(selectedTab)
  }

  return (
    <div>
      <AppBar
        position='static'
        style={{ background: useTheme().palette.background.default }}
        elevation={0}
      >
        <Toolbar>
          <AccountDropDown user={user}></AccountDropDown>
          <AppBarTabs
            preSelectedTab={selectedTab}
            onTabChange={(e: any) => handleChangeTab(e)}
            menu={menu}
            totalCount={count}
          />
          <IconButton aria-label='mails' color='primary'>
            <Badge badgeContent={count} color='default'>
              <MailIcon color='primary' />
            </Badge>
          </IconButton>

          <SettingsIcon color='primary' />

          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon /> &nbsp; &nbsp; &nbsp; &nbsp;
            </div>

            <InputBase
              style={{
                color: '#5f6061',
                height: 44,
                width: 134,
                fontSize: 13,
                fontWeight: 500
              }}
              placeholder='Search (Ctrl+F)  &nbsp; &nbsp; &nbsp; &nbsp;'
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'Search Patients' }}
            />
          </div>
        </Toolbar>
      </AppBar>
      <Grid
        container
        justify='center'
        alignContent='center'
        alignItems='center'
        xs={12}
        style={{ backgroundColor: useTheme().palette.background.default }}
      >
        <Grid
          item
          justify='flex-start'
          xl
          alignContent='stretch'
          style={{
            flexGrow: 1,
            padding: 4,
            margin: 8,
            borderRadius: 8,
            backgroundColor: '#ececec'
          }}
        >
          {selectedTab === menu[0].name && <Settings />}
          {selectedTab === menu[1].name && <PatientsList />}
        </Grid>
      </Grid>
    </div>
  )
}
