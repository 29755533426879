import { Box, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import React, { useState } from 'react'
import { useForm } from 'react-cool-form'
import FocusWithin from 'react-focus-within'
import Requestor from '../api/requestor'
import { convertFormOutputWithSchema } from '../utils/fieldUtils'
import { getFormDataFromObservations } from '../utils/observationFormUtils'
import ObservationForm from '../component/observationForm'

export const ComposableFormFromCoolForm: React.FC<GeneratedFormProps> = (
  props: GeneratedFormProps
) => {
  const [formValues, setFormValues] = useState<any>(props.data)
  const [displayProcessing, setDisplayProcessing] = useState<boolean>(false)
  const [isDirty, setIsDirty] = useState<boolean>(false)

  const { form, use, submit, getState, field, reset, setValue } = useForm({
    defaultValues: formValues,
    onStateChange: (formState) => {
      setIsDirty(formState.isDirty)

      setFormValues(formState.values)
    },

    onSubmit: (values) => {
      setDisplayProcessing(true)
      console.log('onSubmit: upper', values)
      const convertedValue = convertFormOutputWithSchema(values, props.schema)
      console.log(
        '--------onSubmit: convertedValue--------------',
        convertedValue
      )
      Requestor.mapFormDataToMappingOverlay(
        props.OCAFormProviderURL + '/' + 'mapFormOutput',
        props.OCAFormProviderHeader,
        convertedValue
      )
        .then((schema) => {
          console.log('onSubmit: ', schema)

          props.onSubmit(
            schema,
            (data) => {
              console.log('onSubmit: ', schema)

              if (data['id'] !== undefined && data['id'] !== null) {
                setFormValues({ ...formValues, id: data['id'] })
              }
              setDisplayProcessing(false)
              setIsDirty(false)
            },
            (data: any) => {
              console.log('--Error--- ', 'error')
              setDisplayProcessing(false)
            }
          )
        })
        .catch((e) => {
          console.log(`Error: ${e.message}`)
          setDisplayProcessing(false)
        })
    },
    onReset: (values, options, e) => {
      setFormValues(getFormDataFromObservations([], props.schema))
    },
    onError: (errors) => console.log('onError: ', errors)
  })

  return (
    <FocusWithin
      onBlur={() => {
        console.log('You clicked outside of me!')
        if (isDirty) {
          submit()
        }
      }}
    >
      {({ focusProps, isFocused }) => (
        <div
          {...focusProps}
          style={{
            width: '100%'
          }}
        >
          {props.schema !== null && props.schema !== undefined ? (
            <form ref={form}>
              <ObservationForm
                schema={props.schema}
                formValues={formValues}
                displayDelete={
                  props.data !== undefined &&
                  props.data !== null &&
                  getState('id') !== undefined &&
                  getState('id') !== null
                }
                onDelete={(onSuccess, onError) => {
                  props.onDelete(
                    props.data['id'],
                    (data: any) => {
                      console.log('-----------sucess--------------', data)

                      reset(getFormDataFromObservations([], props.schema))
                    },
                    (data: any) => {
                      onError(data)
                    }
                  )
                }}
                getState={getState}
                setValue={setValue}
              />
            </form>
          ) : (
            <Typography
              component='p'
              style={{ color: 'red' }}
              className='error'
            >
              Schema is missing!
            </Typography>
          )}
        </div>
      )}
    </FocusWithin>
  )
}

export default ComposableFormFromCoolForm
