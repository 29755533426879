import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  AddressTypeKind,
  ContactPointSystemKind,
  ContactPointUseKind,
  HumanNameUseKind,
  IdentifierUseKind,
  PatientGenderKind
} from '@ahryman40k/ts-fhir-types/lib/R4'
import moment from 'moment'

export function getNameOfPatient(
  patient: R4.IPatient,
  useKind?: HumanNameUseKind
): string | undefined {
  let name: string | undefined
  if (patient != null) {
    if (Array.isArray(patient.name) && patient.name.length) {
      if (useKind) {
        const selectedName: R4.IHumanName | undefined = patient.name.find(
          (element) => element.use === useKind
        )
        if (selectedName)
          if (Array.isArray(selectedName.given) && selectedName.given) {
            name = selectedName.given[0]
          }
      } else {
        if (Array.isArray(patient.name[0].given) && patient.name[0].given) {
          name = patient.name[0].given[0]
        }
      }
    }
  }

  return name
}

export function getMiddleNameOfPatient(
  patient: R4.IPatient,
  useKind?: HumanNameUseKind
): string | undefined {
  let middleName: string | undefined
  if (patient != null) {
    if (Array.isArray(patient.name) && patient.name.length) {
      if (useKind) {
        const selectedName: R4.IHumanName | undefined = patient.name.find(
          (element) => element.use === useKind
        )
        if (selectedName)
          if (Array.isArray(selectedName.given) && selectedName.given) {
            middleName = selectedName.given[1]
          }
      } else {
        if (Array.isArray(patient.name[0].given) && patient.name[0].given) {
          middleName = patient.name[0].given[1]
        }
      }
    }
  }

  return middleName
}

export function getLastNameOfPatient(
  patient: R4.IPatient,
  useKind?: HumanNameUseKind
): string | undefined {
  let lastName: string | undefined
  if (patient != null) {
    if (Array.isArray(patient.name) && patient.name.length) {
      if (useKind) {
        const selectedName: R4.IHumanName | undefined = patient.name.find(
          (element) => element.use === useKind
        )
        if (selectedName)
          if (selectedName.family && selectedName.family) {
            lastName = selectedName.family
          }
      } else {
        if (patient.name[0].family && patient.name[0].family) {
          lastName = patient.name[0].family
        }
      }
    }
  }

  return lastName ?? ' '
}

export function getTelecomOfPatient(
  patient: R4.IPatient,
  useKind?: ContactPointSystemKind
): string | undefined {
  let phone: string | undefined
  if (patient != null) {
    if (Array.isArray(patient.telecom) && patient.telecom.length) {
      if (useKind) {
        const selectedContactPoint:
          | R4.IContactPoint
          | undefined = patient.telecom.find(
          (element) => element.system === useKind
        )
        phone = selectedContactPoint?.value
      } else {
        phone = patient.telecom[0]?.value
      }
    }
  }

  return phone
}

export function getEmailOfPatient(
  patient: R4.IPatient,
  useKind?: ContactPointSystemKind
): string | undefined {
  let email: string | undefined
  if (patient != null) {
    if (Array.isArray(patient.telecom) && patient.telecom.length) {
      if (useKind) {
        const selectedContactPoint:
          | R4.IContactPoint
          | undefined = patient.telecom.find(
          (element) => element.system === useKind
        )
        email = selectedContactPoint?.value
      } else {
        email = patient.telecom[1]?.value
      }
    }
  }

  return email
}

export function getProfilePicPatient(patient: R4.IPatient): string {
  let phone: string | undefined
  if (patient != null) {
    if (Array.isArray(patient.photo) && patient.photo.length) {
      if (patient.photo[0].contentType.match(/^image/)) {
        if (patient.photo[0].data) {
          phone =
            'data:' +
            patient.photo[0].contentType +
            ';base64,' +
            patient.photo[0].data
        }
      }
    }
  }

  return phone
}

export function getAgeOfPatient(patient: R4.IPatient): number {
  let age = 0
  if (patient != null) {
    if (patient.birthDate) {
      const ageData = moment(patient.birthDate).format('DD-MM-YYYY')
      age = moment().diff(moment(ageData, 'DD-MM-YYYY'), 'years')
    }
  }

  return age
}
export function getAddressOfPatient(
  patient: R4.IPatient,
  useKind?: AddressTypeKind
): string {
  return 'dd'
}


export function titleCase(str: string) {
  return str.replace(/\b(\S)/g, (t: string) => t.toUpperCase())
}
