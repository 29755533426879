import React from 'react'
import { TextField, FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  inputBoxStyle: {
    spellcheck: true,
    backgroundColor: '#ffffff',
    fontSize: 13,
    '&::placeholder': {
      color: '#4d4d4d',
      fontSize: 13
    }
  },

  label: {
    color: '#4d4d4d',
    fontSize: 12,
    fontWeight: 600
  }
}))

export interface IProps {
  name: string
  label: string
  type?: 'text'
  width?: number
  placeholder?: any
  value?: any
  error?: null | undefined
  onChange?: any
  required: boolean
  size?: 'small' | 'medium' | 'large'
  [other: string]: any
}

export const WelloTextArea: React.FC<IProps> = ({
  name,
  label,
  type = 'text',
  value,
  error,
  onChange,
  placeholder,
  width,
  size = 'small',
  other
}: IProps) => {
  const classes = useStyles()
  return (
    <FormControl>
      <Typography className={classes.label} variant='subtitle2'>
        &nbsp;&nbsp;{label}
      </Typography>
      <TextField
        id={name}
        variant='outlined'
        name={name}
        placeholder={placeholder}
        value={value}
        multiline
        rows={4}
        size={size}
        color='#4543d9'
        width={width}
        required
        onChange={onChange}
        {...other}
        {...(error && { error: true, helperText: error })}
      />
    </FormControl>
  )
}
