/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Avatar,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import PublishIcon from '@material-ui/icons/Publish'
import React from 'react'
import {
  validateServiceName,
  validateMethodOfDelivery,
  validateBenefits,
  validateHowTreatmentWorks,
  validateRate,
  validateCategory,
  validateMinimumDuration,
  validatePhoto
} from '../../../../utils/formValidators/serviceFormValidators'
import * as ServiceMapping from '../../../../utils/ServiceHelper/Services/ServiceMapping'
import { Controls } from '../../../PrmitiveComponent/component/Controls'
import {
  Form,
  UseForm
} from '../../../PrmitiveComponent/component/Helper/UseForm'

export interface IFormLabels {
  nameLabel?: string
  titleLabel?: string
  emailLabel?: string
  phoneNumberLabel?: string
  genderLabel?: string
  photoLabel?: string
}
export interface IUnitAdmin {
  children?: React.ReactNode
  count: number
  submitButtonText?: string
  resetButtonText?: string
  onValidSubmit?: (serviceMapping?: any) => void
  preServiceMapping?: any
  onClickToCancel: (val: boolean) => void
  formLabels?: IFormLabels
  popupSate: boolean
}

const errorValues = {
  methodOfDelivery: '',
  summary: '',
  email: '',
  recommendedDuration: ''
}

function getInitializeValues(serviceMapping?: any) {
  let initialFValues = {
    id: '0',
    methodOfDelivery: '',
    tid: '',
    summary: '',
    serviceName: '',
    recommendedDuration: '',
    minimumDuration: '',
    category: '',
    rate: 0,
    rateValidForDays: 0,
    photoUri: '',
    videoUpload: '',
    referenceLinks: '',
    howTreatmentWorks: '',
    testimonials: '',
    benefits: [],
    imgName: '',
    seasonal: [],
    allergies: '',
    targetVPKProfile: '',
    medications: '',
    professionalHazards: '',
    specialConditions: '',
    biomarkers: '',
    severity: '',
    disorders: [],
    region: [],
    gender: [],
    ageRange: [],
    overallWellness: []
  }
  if (serviceMapping != null) {
    initialFValues = {
      id: '0',
      methodOfDelivery: '',
      tid: '',
      summary: '',
      serviceName: '',
      recommendedDuration: '',
      minimumDuration: '',
      category: '',
      rate: 0,
      rateValidForDays: 0,
      photoUri: '',
      videoUpload: '',
      referenceLinks: '',
      howTreatmentWorks: '',
      testimonials: '',
      benefits: [],
      imgName: '',
      seasonal: [],
      allergies: '',
      targetVPKProfile: '',
      medications: '',
      professionalHazards: '',
      specialConditions: '',
      biomarkers: '',
      severity: '',
      disorders: [],
      region: [],
      gender: [],
      ageRange: [],
      overallWellness: []
    }
  }
  return initialFValues
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },

  mainPage: {
    maxWidth: 960,
    minWidth: 960,
    height: 'auto',
    backgroundColor: '#cdcdcd',
    borderRadius: 6,
    overflow: 'Auto'
  },

  buttonStyle: {
    fontSize: 14,
    fontWeight: 600,
    display: 'inline'
  },

  distance: {
    marginTop: 10
  },

  footer: {
    marginTop: 20
  },

  large: {
    width: 60,
    height: 60
  },

  cameraContainer: {
    color: '#333333',
    fontSize: 10
    // marginLeft: 275,
  },
  contactInfoHeader: {
    marginTop: 20,
    color: '#4d4d4d',
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 260
  },
  ageLabel: {
    height: 39,
    width: 59,
    borderRadius: 6,
    backgroundColor: '#ffffff',
    marginTop: 16,
    textAlign: 'center'
  },
  overlapping: {
    height: 20,
    display: 'inline-flex',
    align: 'center',
    marginLeft: 5,
    marginTop: 14,
    fontSize: 12,
    fontWeight: 600
  },
  textLabel: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 'bold',
    color: useTheme().palette.common.black,
    fontStyle: 'bold',
    lineHeight: 'normal',
    letterSpacing: 'auto'
  },
  topHeader: {
    color: theme.palette.common.white,
    marginBottom: 5
  },
  paperContainer: {
    maxWidth: 1325,
    maxHeight: 'auto',
    // backgroundColor: '#ffffff',
    variant: 'outlined'
    // scrollbarWidth: 'auto',
    // overflow: 'scroll',
    // '&::-webkit-scrollbar': {
    //   display: 'none'
    // }
  }
}))

export const AddService: React.FC<IUnitAdmin> = ({
  children,
  count,
  submitButtonText,
  resetButtonText,
  preServiceMapping,
  onClickToCancel,
  onValidSubmit,
  formLabels = {},
  popupSate = false
}: IUnitAdmin) => {
  const classes = useStyles()
  const initialFValues: any = getInitializeValues(preServiceMapping)

  function validate(fieldValues = values) {
    const temp = { ...errors }
    console.log(fieldValues)
    if ('methodOfDelivery' in fieldValues) {
      temp.methodOfDelivery = validateMethodOfDelivery(
        fieldValues.methodOfDelivery,
        true
      )
    }

    if ('photoUri' in fieldValues) {
      temp.photoUri = validatePhoto(fieldValues.photoUri, true)
    }
    if ('category' in fieldValues) {
      temp.category = validateCategory(fieldValues.category, true)
    }

    if ('benefits' in fieldValues) {
      temp.benefits = validateBenefits(fieldValues.benefits, true)
    }

    if ('howTreatmentWorks' in fieldValues) {
      temp.howTreatmentWorks = validateHowTreatmentWorks(
        fieldValues.howTreatmentWorks,
        true
      )
    }

    if ('minimumDuration' in fieldValues) {
      temp.minimumDuration = validateMinimumDuration(
        fieldValues.minimumDuration,
        true
      )
    }

    if ('rate' in fieldValues) {
      temp.rate = validateRate(fieldValues.rate, true)
    }

    if ('serviceName' in fieldValues) {
      temp.serviceName = validateServiceName(fieldValues.serviceName, true)
    }

    setErrors({
      ...temp
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
    console.log('here 5')
  }

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    uploadPic,
    resetForm
  } = UseForm(initialFValues, errorValues, true, validate)

  const cancel = () => {
    onClickToCancel(false)
    resetForm()
  }
  const handleClose = () => {
    onClickToCancel(false)
    resetForm()
  }

  function handleSubmit(e: any) {
    console.log('In validation')
    e.preventDefault()
    if (validate()) {
      console.log('validation success')
      const formData = {
        tid: 'dev-lab1',
        methodOfDelivery: values.methodOfDelivery,
        serviceName: values.serviceName,
        summary: values.summary,
        recommendedDuration: values.recommendedDuration,
        minimumDuration: values.minimumDuration,
        category: values.category,
        rate: values.rate,
        rateValidForDays: values.rateValidForDays,
        videoUpload: values.videoUpload,
        referenceLinks: values.referenceLinks,
        howTreatmentWorks: values.howTreatmentWorks,
        benefits: values.benefits,
        photoUri: values.photoUri,
        targetConsumer: {
          allergies: values.allergies,
          targetVPKProfile: values.targetVPKProfile,
          medications: values.medications,
          professionalHazards: values.professionalHazards,
          specialConditions: values.specialConditions,
          biomarkers: values.biomarkers,
          severity: values.severity,
          disorders: values.disorders,
          region: values.region,
          gender: values.gender,
          ageRange: values.ageRange,
          overallWellness: values.overallWellness.toString()
        },
        seasonal: values.seasonal
      }

      console.log('Converted patient value')
      console.log(formData)
      if (formData) {
        if (onValidSubmit) onValidSubmit(formData)
        resetForm()
      } else {
        console.log('validation error')
      }
    }
  }

  function onSeasonSelect(item: any) {
    console.log('checked')
    values.seasonal.push(item.name)
    errors.seasonal = ''
  }

  function onSeasonDeSelect(item: any) {
    const index = values.seasonal.indexOf(item.name)
    if (index > -1) values.seasonal.splice(index, 1)
  }

  function onAgeRangeSelect(item: any) {
    console.log('checked')
    values.ageRange.push(item.name)
  }

  function onAgeRangeDeSelect(item: any) {
    const index = values.ageRange.indexOf(item.name)
    if (index > -1) values.ageRange.splice(index, 1)
  }

  function onGenderSelect(item: any) {
    console.log('checked')
    values.gender.push(item.name)
  }

  function onGenderDeSelect(item: any) {
    const index = values.gender.indexOf(item.name)
    if (index > -1) values.gender.splice(index, 1)
  }

  function onRegionSelect(item: any) {
    console.log('checked')
    values.region.push(item.name)
  }

  function onRegionDeSelect(item: any) {
    const index = values.region.indexOf(item.name)
    if (index > -1) values.region.splice(index, 1)
  }

  function onWellNessSelect(item: any) {
    console.log('checked')
    values.overallWellness.push(item.name)
  }

  function onWellNessDeSelect(item: any) {
    const index = values.overallWellness.indexOf(item.name)
    if (index > -1) values.overallWellness.splice(index, 1)
  }

  return (
    <Dialog
      open={popupSate}
      classes={{ paper: classes.paperContainer }}
      onClose={handleClose}
      disableBackdropClick
    >
      <DialogTitle
        id='scroll-dialog-title'
        style={{
          backgroundColor: useTheme().palette.primary.main,
          color: useTheme().palette.common.white,
          alignContent: 'center',
          textAlign: 'center',
          height: 48
        }}
      >
        <Typography variant='h3' className={classes.topHeader}>
          Add Service
        </Typography>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: useTheme().palette.background.default,
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0
        }}
      >
        <Paper className={classes.mainPage} square={true} variant='outlined'>
          <div className={classes.distance}></div>
          <Form onSubmit={handleSubmit} title='Add Service'>
            <Grid container className={classes.root} spacing={0}>
              <Grid item xs={12}>
                <Grid container justify='center' spacing={0}>
                  <Grid key={1} item>
                    {/* {children} */}
                    <Grid container className={classes.root} spacing={0}>
                      <Grid item xs={12}>
                        <Grid key={12} item>
                          <Grid
                            container
                            justify='flex-start'
                            direction='column'
                            style={{ paddingLeft: 10 }}
                          >
                            &nbsp; &nbsp;
                            <Controls.WelloSelectForm
                              name='methodOfDelivery'
                              required={true}
                              label={
                                formLabels?.titleLabel ?? 'METHOD OF DELIVERY'
                              }
                              type='Select Method of Delivery'
                              value={values.methodOfDelivery}
                              options={ServiceMapping.getMethodOfDelivery().sort()}
                              width={480}
                              onChange={handleInputChange}
                              error={errors.methodOfDelivery}
                            />
                            <br></br>
                            <Controls.WelloInput
                              name='serviceName'
                              required={false}
                              label='SERVICE NAME'
                              type='text'
                              value={values.serviceName}
                              error={errors.serviceName}
                              placeholder='Service Name'
                              width={480}
                              onChange={handleInputChange}
                              charsAllowed={30}
                            />
                            <br></br>
                            <Controls.WelloInput
                              name='summary'
                              required={false}
                              label='SERVICE DESCRIPTION'
                              value={values.summary}
                              error={errors.summary}
                              placeholder='Service Description'
                              onChange={handleInputChange}
                              type='text'
                              charsAllowed={30}
                            />
                            <br></br>
                            <Grid container spacing={1}>
                              <Grid item xs={3}>
                                <Controls.WelloInput
                                  name='recommendedDuration'
                                  label='RECOMMENDED DURATION'
                                  value={values.recommendedDuration}
                                  placeholder='Recommended Duration'
                                  onChange={handleInputChange}
                                  required={true}
                                  width={240}
                                  type='text'
                                  error={errors.recommendedDuration}
                                  charsAllowed={30}
                                />
                              </Grid>

                              <Grid item xs={8}>
                                <div style={{ marginLeft: 100 }}>
                                  <Controls.WelloInput
                                    name='minimumDuration'
                                    label='Minimum Duration'
                                    value={values.minimumDuration}
                                    onChange={handleInputChange}
                                    placeholder='Minimum Duration'
                                    required={true}
                                    width={230}
                                    type='text'
                                    error={errors.minimumDuration}
                                    charsAllowed={30}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <br></br>
                            <Controls.WelloSelectForm
                              name='category'
                              required={true}
                              label={formLabels?.titleLabel ?? 'CATEGORY'}
                              type='Select Category'
                              value={values.category}
                              options={ServiceMapping.getCategory().sort()}
                              width={480}
                              onChange={handleInputChange}
                              error={errors.category}
                            />
                            <br></br>
                            <Grid container>
                              <Grid item xs={3}>
                                <Controls.WelloInput
                                  name='rate'
                                  label='RATE'
                                  value={values.rate}
                                  error={errors.rate}
                                  placeholder='Rate'
                                  onChange={handleInputChange}
                                  required={true}
                                  width={240}
                                  type='number'
                                  charsAllowed={30}
                                />
                              </Grid>

                              <Grid item xs={8}>
                                <div style={{ marginLeft: 100 }}>
                                  <Controls.WelloInput
                                    name='rateValidForDays'
                                    label='RATE VALID FOR DAYS'
                                    value={values.rateValidForDays}
                                    error={errors.rateValidForDays}
                                    onChange={handleInputChange}
                                    required={true}
                                    width={230}
                                    placeholder='Rate Valid For Days'
                                    charsAllowed={30}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <br></br>
                            <Grid container>
                              <Grid item xs={3}>
                                {values.imgName.length === 0 && (
                                  <Controls.WelloInputFile
                                    style={{ display: 'none' }}
                                    name='photoUri'
                                    error={errors.photoUri}
                                    required={false}
                                    label='SERVICE PHOTO'
                                    icon={<AccountCircle />}
                                    type='image/x-png,image/gif,image/jpeg'
                                    fileType='Add Photo'
                                    onChange={uploadPic}
                                  />
                                )}
                                {values.imgName.length !== 0 && (
                                  <Controls.WelloInputFile
                                    style={{ display: 'none' }}
                                    name='photoUri'
                                    required={false}
                                    label='SERVICE PHOTO'
                                    icon={<AccountCircle />}
                                    type='image/x-png,image/gif,image/jpeg'
                                    fileType='Change Photo'
                                    onChange={uploadPic}
                                  />
                                )}
                              </Grid>
                              &nbsp;&nbsp;
                              <Grid item xs={4}>
                                {values.imgName.length !== 0 && (
                                  <Avatar
                                    alt='Remy Sharp'
                                    src={values.photoUri}
                                    className={classes.large}
                                  />
                                )}
                              </Grid>
                            </Grid>
                            <br></br>
                            <Controls.WelloInput
                              name='referenceLinks'
                              label='REFERENCE LINKS'
                              value={values.referenceLinks}
                              onChange={handleInputChange}
                              required={true}
                              width={480}
                              placeholder='Reference Links'
                              type='text'
                              charsAllowed={30}
                            />
                            <br></br>
                            <Controls.WelloInput
                              name='howTreatmentWorks'
                              label='HOW TREATMENT WORKS'
                              value={values.howTreatmentWorks}
                              onChange={handleInputChange}
                              error={errors.howTreatmentWorks}
                              required={true}
                              width={480}
                              placeholder='How Treatment Works'
                              type='text'
                              charsAllowed={30}
                            />
                            <br></br>
                            <Controls.WelloMultiSelectBox
                              name='benefits'
                              label='BENEFITS'
                              value={values.benefits}
                              error={errors.benefits}
                              options={ServiceMapping.benefits}
                              width={480}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <br></br>
                          <Typography
                            variant='h4'
                            style={{
                              marginLeft: 10,
                              textTransform: 'uppercase'
                            }}
                          >
                            Season{' '}
                            <span style={{ color: 'red' }}>* &nbsp;&nbsp;</span>
                          </Typography>
                          <div style={{ display: 'flex', marginLeft: 10 }}>
                            {ServiceMapping.availableOptions.map(
                              (option: any) => (
                                <Controls.WelloCheckbox
                                  key={option}
                                  name={option.name}
                                  label={option.name}
                                  id={option.name}
                                  error={errors.seasonal}
                                  onChange={(value) => {
                                    if (value) {
                                      onSeasonSelect(option)
                                    } else {
                                      onSeasonDeSelect(option)
                                    }
                                  }}
                                />
                              )
                            )}
                          </div>
                          {/* <span style={{ color: 'red' }}>
                        {errors.seasonal} &nbsp;&nbsp;
                      </span> */}
                          <br></br>
                          <Typography
                            variant='h5'
                            style={{
                              marginLeft: 10,
                              color: useTheme().palette.text.primary
                            }}
                          >
                            Target Consumer
                          </Typography>
                          <Divider
                            style={{
                              height: 4,
                              color: useTheme().palette.primary.main,
                              textTransform: 'uppercase'
                            }}
                          />
                          <br></br>
                          <Typography
                            variant='h4'
                            style={{
                              marginLeft: 10,
                              textTransform: 'uppercase'
                            }}
                          >
                            Age Range{' '}
                            <span style={{ color: 'red' }}>* &nbsp;&nbsp;</span>
                          </Typography>
                          <div style={{ display: 'flex', marginLeft: 10 }}>
                            {ServiceMapping.ageRange.map((option: any) => (
                              <Controls.WelloCheckbox
                                key={option}
                                name={option.name}
                                label={option.name}
                                id={option.name}
                                onChange={(value) => {
                                  if (value) {
                                    onAgeRangeSelect(option)
                                  } else {
                                    onAgeRangeDeSelect(option)
                                  }
                                }}
                              />
                            ))}
                          </div>
                          <br></br>
                          <Typography
                            variant='h4'
                            style={{
                              marginLeft: 10,
                              textTransform: 'uppercase'
                            }}
                          >
                            Gender{' '}
                            <span style={{ color: 'red' }}>* &nbsp;&nbsp;</span>
                          </Typography>
                          <div style={{ display: 'flex', marginLeft: 10 }}>
                            {ServiceMapping.genderList.map((option: any) => (
                              <Controls.WelloCheckbox
                                key={option}
                                name={option.name}
                                label={option.name}
                                id={option.name}
                                onChange={(value) => {
                                  if (value) {
                                    onGenderSelect(option)
                                  } else {
                                    onGenderDeSelect(option)
                                  }
                                }}
                              />
                            ))}
                          </div>
                          <br></br>
                          <Typography
                            variant='h4'
                            style={{
                              marginLeft: 10,
                              textTransform: 'uppercase'
                            }}
                          >
                            Region{' '}
                            <span style={{ color: 'red' }}>* &nbsp;&nbsp;</span>
                          </Typography>
                          <div style={{ display: 'flex', marginLeft: 10 }}>
                            {ServiceMapping.regionList.map((option: any) => (
                              <Controls.WelloCheckbox
                                key={option}
                                name={option.name}
                                label={option.name}
                                id={option.name}
                                onChange={(value) => {
                                  if (value) {
                                    onRegionSelect(option)
                                  } else {
                                    onRegionDeSelect(option)
                                  }
                                }}
                              />
                            ))}
                          </div>
                          <Controls.WelloMultiSelectBox
                            name='disorders'
                            label='DISORDERS'
                            value={values.disorders}
                            error={errors.disorders}
                            options={ServiceMapping.medicalConditions.sort()}
                            width={480}
                            onChange={handleInputChange}
                          />
                          <br></br>
                          <br></br>
                          &nbsp;&nbsp;{' '}
                          <Controls.WelloSelectForm
                            name='severity'
                            required={true}
                            label={'SEVERITY'}
                            type='Select Severity'
                            value={values.severity}
                            options={ServiceMapping.getSeverity().sort()}
                            width={480}
                            onChange={handleInputChange}
                            error={errors.severity}
                          />
                          <br></br>
                          <br></br>
                          &nbsp;&nbsp;{' '}
                          <Controls.WelloInput
                            name='bioMarkers'
                            required={false}
                            label='BIO MARKERS'
                            type='text'
                            value={values.bioMarkers}
                            error={errors.bioMarkers}
                            placeholder='Bio Markers'
                            width={480}
                            onChange={handleInputChange}
                            charsAllowed={30}
                         
                          />
                          <br></br>
                          <br></br>
                          &nbsp;&nbsp;{' '}
                          <Controls.WelloSelectForm
                            name='specialConditions'
                            required={true}
                            label={'SPECIAL CONDITIONS'}
                            type='Select Special Conditions'
                            value={values.specialConditions}
                            options={ServiceMapping.getSplConditions().sort()}
                            width={480}
                            onChange={handleInputChange}
                            error={errors.specialConditions}
                          />
                          <br></br>
                          <br></br>
                          &nbsp;&nbsp;{' '}
                          <Controls.WelloSelectForm
                            name='professionalHazards'
                            required={true}
                            label={'PROFESSIONAL HAZARDS'}
                            type='Select Professional Hazards'
                            value={values.professionalHazards}
                            options={ServiceMapping.getHazzards().sort()}
                            width={480}
                            onChange={handleInputChange}
                            error={errors.professionalHazards}
                          />
                          <br></br>
                          <br></br>
                          &nbsp;&nbsp;{' '}
                          <Controls.WelloInput
                            name='medications'
                            required={false}
                            label='MEDICATIONS'
                            type='text'
                            value={values.medications}
                            error={errors.medications}
                            placeholder='Medications'
                            width={480}
                            onChange={handleInputChange}
                            charsAllowed={30}
                          />
                          <br></br>
                          <br></br>
                          &nbsp;&nbsp;{' '}
                          <Controls.WelloInput
                            name='targetVPKProfile'
                            required={false}
                            label='TARGET VPK PROFILE'
                            type='text'
                            value={values.targetVPKProfile}
                            error={errors.targetVPKProfile}
                            placeholder='Target VPK Profile'
                            width={480}
                            onChange={handleInputChange}
                            charsAllowed={30}
                          />
                          <br></br>
                          <br></br>
                          &nbsp;&nbsp;{' '}
                          <Controls.WelloInput
                            name='allergies'
                            required={false}
                            label='ALLERGIES'
                            type='text'
                            value={values.allergies}
                            error={errors.allergies}
                            placeholder='Allergies'
                            width={480}
                            onChange={handleInputChange}
                            charsAllowed={30}
                          />
                          <br></br>
                          <Typography
                            variant='h4'
                            style={{
                              marginLeft: 10,
                              textTransform: 'uppercase'
                            }}
                          >
                            OverAll Wellness{' '}
                            <span style={{ color: 'red' }}>* &nbsp;&nbsp;</span>
                          </Typography>
                          <div style={{ display: 'flex', marginLeft: 10 }}>
                            {ServiceMapping.wellness.map((option: any) => (
                              <Controls.WelloCheckbox
                                key={option}
                                name={option.name}
                                label={option.name}
                                id={option.name}
                                onChange={(value) => {
                                  if (value) {
                                    onWellNessSelect(option)
                                  } else {
                                    onWellNessDeSelect(option)
                                  }
                                }}
                              />
                            ))}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* <div className={classes.footer}></div>
                <Divider />
                <div className={classes.distance}></div>
                <Grid container justify='flex-end'>
                  <div className={classes.buttonStyle}>
                    <Controls.WelloButton
                      type='button'
                      text={resetButtonText ?? 'Cancel (esc)'}
                      size='small'
                      variant='outlined'
                      backgroundColor='#fefefe'
                      color='#4543d9'
                      onClick={cancel}
                    />
                    <Controls.WelloButton
                      type='submit'
                      text={submitButtonText ?? 'Add Service (Alt+S)'}
                      size='small'
                      variant='outlined'
                      backgroundColor='#4543d9'
                      color='#cdcdcd'
                    />
                  </div>
                </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#cdcdcd' }}>
        <Controls.WelloButton
          type='button'
          text={resetButtonText ?? 'Cancel (esc)'}
          size='small'
          variant='outlined'
          backgroundColor='#fefefe'
          color='#4543d9'
          onClick={cancel}
        />
        <Controls.WelloButton
          type='button'
          text={submitButtonText ?? 'Add Service (Alt+S)'}
          size='small'
          variant='outlined'
          backgroundColor='#4543d9'
          color='#cdcdcd'
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  )
}
