/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useTheme
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import React, { useState } from 'react'
import {
  getNameOfPatient,
  getTelecomOfPatient,
  getMiddleNameOfPatient,
  getLastNameOfPatient,
  getAgeOfPatient
} from '../../../../utils/fhirHelpers/patientHelper'
import {
  getFhirPatientObjectFromForm,
  getFhirRelatedObjectFromForm,
  getFhirRelatedObjectHSForm
} from '../../../../utils/formHelper'
import { martialList } from '../../../../utils/ServiceHelper/Services/ExpertService'
import {
  validateEmail,
  validateMobileNumber,
  validateDob,
  validateName,
  validateProfilePic,
  validateGender,
  validateLastName,
  validateFathersName,
  validateNationality,
  validateMaritalStatus,
  validateAddress,
  validateHusbandsName,
  validateMiddleName,
  validateMobileNumberForAdd,
  validateOtherData,
  validateLRNumber
} from '../../../../utils/formValidators/formValidators'
import { Controls } from '../../../PrmitiveComponent/component/Controls'
import {
  Form,
  UseForm
} from '../../../PrmitiveComponent/component/Helper/UseForm'
import male from '../../../../assets/images/m.png'
import female from '../../../../assets/images/f.png'

export interface IFormLabels {
  nameLabel?: string
  mNameLabel?: string
  lNameLabel?: string
  emailLabel?: string
  phoneNumberLabel?: string
  genderLabel?: string
  addressLabel?: string
  photoLabel?: string
  identityLabel?: string
  maritalStatusLabel?: string
  nationalityLabel?: string
  occupationLabel?: string
  fatherNameLabel?: string
  husbandNameLabel?: string,
  referenceIdLabel?:string,
  addressTypeLabel?:string,
  doblabelForKey?:string,
}

export interface IPlaceHolderLablesLabels {
  nameLabel?: string
  mNameLabel?: string
  lNameLabel?: string
  emailLabel?: string
  phoneNumberLabel?: string
  genderLabel?: string
  addressLabel?: string
  photoLabel?: string
  identityLabel?: string
  maritalStatusLabel?: string
  nationalityLabel?: string
  occupationLabel?: string
  fatherNameLabel?: string
  husbandNameLabel?: string,
  addPatient?:string,
  addPhoto?:string,
  doblabel?:string,
  referenceIdLabel?:string,
  addressTypeLabel?:string
}
export interface IUnitAdmin {
  children?: React.ReactNode
  count: number
  submitButtonText?: string
  resetButtonText?: string
  onValidSubmit?: (
    patientDetail: R4.IPatient,
    relatedPersonDetail?: R4.IRelatedPerson,
    relatedPersonHusbandDetail?:R4.IRelatedPerson,
    occupation?: string,
    nationality?: string
  ) => void
  preAddedPatient?: R4.IPatient
  preAddedRelatedPatient?: R4.IRelatedPerson
  onClickToCancel: (val: boolean) => void
  formLabels?: IFormLabels,
  placeholderLables?:IPlaceHolderLablesLabels,
  popupSate: boolean
}

const errorValues = {
  name: '',
  phone: '',
  email: '',
  address: ''
}

function getInitializeValues(
  patient?: R4.IPatient,
  relatedPerson?: R4.IRelatedPerson
) {
  let initialFValues = {
    id: '0',
    name: '',
    middleName: '',
    lastName: '',
    gender: '',
    phone: '',
    email: '',
    photoUri: '',
    imgName: '',
    idProof: '',
    dob: '',
    age: 0,
    width: 60,
    type: '',
    maritalStatus: '',
    addressType: 'home',
    nationality: 'india',
    occupation: '',
    fathersName: '',
    husbandName: '',
    lrNumber:'',
    address: '',
    otherData:''
  }
  if (patient != null || relatedPerson != null) {
    initialFValues = {
      id: patient?.id ?? '',
      name: getNameOfPatient(patient) ?? '',
      middleName: getMiddleNameOfPatient(patient) ?? '',
      lastName: getLastNameOfPatient(patient) ?? '',
      gender: '',
      phone:
        getTelecomOfPatient(patient, R4.ContactPointSystemKind._phone) ?? '',
      email:
        getTelecomOfPatient(patient, R4.ContactPointSystemKind._email) ?? '',
      photoUri: '',
      imgName: '',
      idProof: '',
      dob: patient.birthDate ?? '',
      age: getAgeOfPatient(patient),
      width: 60,
      type: '',
      maritalStatus: '',
      addressType: 'home',
      nationality: 'india',
      occupation: '',
      fathersName: '',
      husbandName: '',
      lrNumber:'',
      address:'',
      otherData:''
    }
  }
  return initialFValues
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },

  topHeader: {
    color: theme.palette.common.white,
    marginBottom: 5
  },

  mainPage: {
    maxWidth: 960,
    height: 'auto',
    backgroundColor: '#cdcdcd'
  },
  distance: {
    marginTop: 10
  },

  large: {
    width: 60,
    height: 60
  },
  ageLabel: {
    height: 39,
    width: 59,
    borderRadius: 6,
    backgroundColor: '#ffffff',
    marginTop: 16,
    textAlign: 'center'
  },
  overlapping: {
    height: 20,
    display: 'inline-flex',
    align: 'center',
    marginLeft: 5,
    marginTop: 14,
    fontSize: 12,
    fontWeight: 600
  },
  textLabel: {
    textTransform: 'uppercase'
  }
}))

export const NewPatient: React.FC<IUnitAdmin> = ({
  children,
  count,
  submitButtonText,
  resetButtonText,
  preAddedPatient,
  preAddedRelatedPatient,
  onClickToCancel,
  onValidSubmit,
  formLabels = {},
  placeholderLables={},
  popupSate = false
}: IUnitAdmin) => {
  const classes = useStyles()
  const initialFValues: any = getInitializeValues(
    preAddedPatient,
    preAddedRelatedPatient
  )
  const [openPopup, setOpenPopup] = useState(popupSate)

  function validate(fieldValues = values) {
    const temp = { ...errors }
    console.log(fieldValues)
    if ('email' in fieldValues)
      temp.email = validateEmail(fieldValues.email, true)
    if ('name' in fieldValues) {
      temp.name = validateName(fieldValues.name, true)
    }
    if ('lastName' in fieldValues) {
      temp.lastName = validateLastName(fieldValues.lastName, true)
    }
    if ('dob' in fieldValues) temp.dob = validateDob(fieldValues.dob, true)
    if ('phone' in fieldValues) {
      temp.phone = validateMobileNumberForAdd(fieldValues.phone, true)
    }
    if ('photoUri' in fieldValues)
      temp.photoUri = validateProfilePic(fieldValues.photoUri, true)
    if ('address' in fieldValues) temp.address = validateAddress(fieldValues.address, false)

    if ('lrNumber' in fieldValues)
    temp.lrNumber = validateLRNumber(fieldValues.lrNumber, true)
    
    if ('gender' in fieldValues)
      temp.gender = validateGender(fieldValues.gender, true)
      if ('fathersName' in fieldValues)
      temp.fathersName = validateFathersName(fieldValues.fathersName, false)
      
      if ('husbandName' in fieldValues)
      temp.husbandName = validateHusbandsName(fieldValues.husbandName, false)
      if ('middleName' in fieldValues)
      temp.middleName = validateMiddleName(fieldValues.middleName, false)

    // if ('fathersName' in fieldValues)
    //   temp.fathersName = validateFathersName(fieldValues.fathersName, true)

    if ('nationality' in fieldValues)
      temp.nationality = validateNationality(fieldValues.nationality, true)

      if ('occupation' in fieldValues)
      {
        if(fieldValues.occupation === 'other')
        {
          if ('otherData' in fieldValues)
              temp.otherData = validateOtherData(fieldValues.otherData, true)
        }
      }


    if ('maritalStatus' in fieldValues)
      temp.maritalStatus = validateMaritalStatus(
        fieldValues.maritalStatus,
        true
      )
    setErrors({
      ...temp
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
    console.log('here 5')
  }

  

  function handleSubmit() {
    console.log('In validation')
    if (validate()) {
      console.log('validation success')
      console.log(values)
      const pat: R4.IPatient | undefined = getFhirPatientObjectFromForm(values)
      const relatedPat:
        | R4.IRelatedPerson
        | undefined = getFhirRelatedObjectFromForm(values)
      const relatedPatHs: R4.IRelatedPerson | undefined =
        getFhirRelatedObjectHSForm(values)
        const occupation: string | undefined = values.occupation === 'other' ? values.otherData : values.occupation
      const nationality: string | undefined = values.nationality

      console.log('Converted patient value')
      console.log(pat)
      console.log(relatedPat)
      if (pat) {
        if (onValidSubmit)
          onValidSubmit(pat, relatedPat,relatedPatHs, occupation, nationality)
        popupSate = false
        setOpenPopup(popupSate)
      } else {
        console.log('validation error')
      }
    }
  }
  const cancel = () => {
    onClickToCancel(false)
    setOpenPopup(false)
  }
  const handleClose = () => {
    onClickToCancel(false)
    setOpenPopup(false)
  }
  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    handleInputChangeForPhoto,
    uploadPic,
    handleDateChange,
    handlePhoneChange
  } = UseForm(initialFValues, errorValues, true, validate)


  const occupations = [
    { id: 'govtOfficer', title: 'Govt. Officer' },
    { id: 'Politician', title: 'Politician' },
    { id: 'selfEmp', title: 'Self Employed' },
    { id: 'businessman', title: 'Businessman' },
    { id: 'Doctor', title: 'Doctor' },
    { id: 'Hospitality service', title: 'Hospitality service' },
    { id: 'Pvt Sector Executive', title: 'Pvt Sector Executive' },
    { id: 'Chartered Accountant', title: 'Chartered Accountant' },
    { id: 'Finance Professional', title: 'Finance Professional' },
    
  ].sort((a, b) =>
  a.title! > b.title! ? 1 : b.title! > a.title! ? -1 : 0
  )
  return (
    <Dialog
      open={openPopup}
      maxWidth='md'
      fullWidth
      onClose={handleClose}
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>{placeholderLables?.addPatient ?? 'Add Patient'}</DialogTitle>
      <DialogContent dividers={true}>
        {/* <Paper className={classes.mainPage} square={true} variant='outlined'> */}
        <div className={classes.distance}></div>
        <Form onSubmit={handleSubmit} title={placeholderLables?.addPatient ?? 'Add Patient'}>
          <Box display='flex' flexDirection='column'>
            <Box display='flex' width='100%'   justifyContent='center'>
              <Box 
                   paddingLeft={3}
                   >
                {values.imgName.length === 0 && (
                  <Controls.WelloInputFile
                    style={{ display: 'none' }}
                    name='photoUri'
                    label={formLabels?.photoLabel ?? 'PHOTO'}
                    icon={<AccountCircle />}
                    required={true}
                    type='image/x-png,image/gif,image/jpeg'
                    fileType={placeholderLables?.addPhoto ?? 'Add photo'}
                    onChange={uploadPic}
                    error={errors.photoUri}
                  />
                )}
                {values?.imgName?.length !== 0 && (
                  <>
                    <Box px={1}>
                      <Avatar
                        alt='avatar'
                        src={values.photoUri}
                        className={classes.large}
                      />
                      <label>
                        <Typography
                          style={{
                            color: useTheme().palette.primary.main,
                            textTransform: 'uppercase'
                          }}
                          variant='subtitle2'
                        >
                          change
                        </Typography>
                        <input
                          id='file-input'
                          type='file'
                          name='photoUri'
                          style={{ display: 'none' }}
                          onChange={uploadPic}
                          accept='image/*'
                        />
                      </label>
                    </Box>
                  </>
                )}
              </Box>
              <Box
                display='flex'
                width='67%'
                // paddingLeft={10}
              >
                <Box px={1}  width='34%'
                    >
                  <Controls.WelloInput
                    name='name'
                    required={false}
                    label={formLabels?.nameLabel ?? 'FIRST NAME'}
                    type='text'
                    value={values.name}
                    error={errors.name}
                    placeholder={placeholderLables?.nameLabel ?? 'First Name'}
                    size='small'
                    onChange={handleInputChange}
                    charsAllowed={100}
                  />
                </Box>
                <Box px={1}  width='34%'  
                    >
                  <Controls.WelloInput
                    name='middleName'
                    required={false}
                    label={formLabels?.mNameLabel ?? 'MIDDLE NAME'}
                    type='text'
                    value={values.middleName}
                    error={errors.middleName}
                    placeholder={placeholderLables?.mNameLabel ?? '(opttional)'}
                    // width={235}
                    size='small'
                    onChange={handleInputChange}
                    charsAllowed={100}
                  />
                </Box>
                <Box   px={1} width='34%' 
                     >
                  <Controls.WelloInput
                    name='lastName'
                    required={false}
                    label={formLabels?.lNameLabel ?? 'LAST NAME'}
                    type='text'
                    size='small'
                    value={values.lastName}
                    error={errors.lastName}
                    placeholder={placeholderLables?.lNameLabel ?? 'Last Name'}
                    // width={235}
                    onChange={handleInputChange}
                    charsAllowed={100}
                  />
                </Box>
                <Box px={1} >
                  {values.gender.length !== 0 && values.dob.length !== 0 && (
                    <Paper className={classes.ageLabel}>
                      {values.gender === 'male' && (
                        <Typography
                          color='primary'
                          className={classes.overlapping}
                        >
                          <img src={male} alt='' height={15.3} width={5.33} />{' '}
                          &nbsp;
                          {values.age === 0
                            ? values.age + 'm'
                            : values.age + 'y'}
                        </Typography>
                      )}

                      {values.gender === 'other' && (
                        <Typography
                          color='primary'
                          className={classes.overlapping}
                        >
                          {/* <img
                                src={male}
                                alt=''
                                height={15.3}
                                width={5.33}
                              />{' '} */}
                          &nbsp;
                          {values.age === 0
                            ? values.age + 'm'
                            : values.age + 'y'}
                        </Typography>
                      )}

                      {values.gender === 'unknown' && (
                        <Typography
                          color='primary'
                          className={classes.overlapping}
                        >
                          &nbsp;
                          {values.age === 0
                            ? values.age + 'm'
                            : values.age + 'y'}
                        </Typography>
                      )}

                      {values.gender === 'female' && (
                        <Typography
                          color='primary'
                          className={classes.overlapping}
                        >
                          <img src={female} alt='' height={15.3} width={5.33} />{' '}
                          &nbsp;
                          {values.age === 0
                            ? values.age + 'm'
                            : values.age + 'y'}
                        </Typography>
                      )}
                    </Paper>
                  )}
                </Box>
              </Box>
            </Box>

            <Box display='flex' py={1}    width='80%'>
              <Box
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                  ? 26
                  : 27
                }
              >
                <Controls.WelloDate
                  name='dob'
                  label={formLabels?.doblabelForKey ?? 'DATE OF BIRTH'}
                  placeholder={placeholderLables?.doblabel ?? 'dd/mm/yyyy'}
                  required={false}
                  value={values.dob}
                  width={285}
                  size='small'
                  error={errors.dob}
                  onChange={handleDateChange}
                />
              </Box>
              <Box paddingLeft={2}>
                <Controls.WelloSelectGender
                  name='gender'
                  required={true}
                  label={formLabels?.genderLabel ?? 'GENDER'}
                  type={placeholderLables?.genderLabel ?? 'Select Gender'}
                  value={values.gender}
                  width={283}
                  onChange={handleInputChange}
                  error={errors.gender}
                />
              </Box>
            </Box>
            <Box display='flex' py={1}>
              <Box
                paddingRight={1}
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                  ? 26
                  : 27
                }
              >
                <Controls.WelloSelectForm
                  name='maritalStatus'
                  required={true}
                  label={formLabels?.maritalStatusLabel ?? 'MARITAL STATUS'}
                  type={placeholderLables?.maritalStatusLabel ?? 'Select Marital Status'}
                  options={martialList()}
                  value={values.maritalStatus}
                  error={errors.maritalStatus}
                  width={285}
                  onChange={handleInputChange}
                />
              </Box>
              <Box paddingLeft={1}>
                <Controls.WelloSelectNationality
                  name='nationality'
                  required={true}
                  label={formLabels?.nationalityLabel ?? 'NATIONALITY'}
                  type={placeholderLables?.nationalityLabel ?? 'Select Nationality'}
                  value={values.nationality}
                  error={errors.nationality}
                  width={283}
                  onChange={handleInputChange}
                />
              </Box>
            </Box>

            <Box display='flex' py={1}>
              <Box
                paddingRight={1}
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                  ? 26
                  : 27
                }
              >
                <Controls.WelloOccupation
                  name='occupation'
                  required={true}
                  label={formLabels?.occupationLabel ?? 'OCCUPATION'}
                  type={placeholderLables?.occupationLabel ?? 'Select Occupation (Optional)'}
                  value={values.occupation}
                  width={285}
                  onChange={handleInputChange}
                  occupations={occupations}
                />
                 {values.occupation === 'other' &&(
                 <Box paddingTop ={1} width='100%' 
                     >
                       
                  <Controls.WelloInput
                    name='otherData'
                    required={false}
                    label={'Other Value'}
                    type='text'
                    size='small'
                    value={values.otherData}
                    error={errors.otherData}
                    placeholder='Occupation'
                     width={285}
                    onChange={handleInputChange}
                    charsAllowed={50}
                  />
                </Box>
                 )}
            </Box>
            <Box px={1}>
                  <Controls.WelloInput
                    name='lrNumber'
                    required={false}
                    label={formLabels?.referenceIdLabel ?? 'REFERENCE ID'}
                    type='text'
                    size='small'
                    value={values.lrNumber}
                    error={errors.lrNumber}
                    placeholder={placeholderLables?.referenceIdLabel ?? 'Reference Id'}
                    width={283}
                    onChange={handleInputChange}
                    charsAllowed={30}
                  />
                </Box>
            </Box>

            <Box display='flex' py={1}>
              <Box
                paddingRight={1}
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                  ? 26
                  : 27
                }
              >
                <Controls.PhoneNumber
                  name='phone'
                  label={formLabels?.phoneNumberLabel ?? 'PHONE NUMBER'}
                  placeholder={placeholderLables?.phoneNumberLabel ?? 'Phone Number'}
                  type='tel'
                  size='small'
                  value={values.phone}
                  required={false}
                  width={285}
                  error={errors.phone}
                  onChange={handleInputChange}
                />
              </Box>
              <Box paddingLeft={1}>
                <Controls.WelloEmailInput
                  name='email'
                  label={formLabels?.emailLabel ?? 'EMAIL ADDRESS'}
                  placeholder={placeholderLables?.emailLabel ?? 'Email Address'}
                  type='email'
                  value={values.email}
                  error={errors.email}
                  required={true}
                  size='small'
                  width={283}
                  onChange={handleInputChange}
                />
              </Box>
            </Box>

            <Box display='flex' py={1}>
              <Box
                paddingRight={1}
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                  ? 26
                  : 27
                }
              >
                <Controls.WelloTextAreaAddress
                  name='address'
                  required={false}
                  label={formLabels?.addressLabel ?? 'ADDRESS'}
                  type='text'
                  placeholder={placeholderLables?.addressLabel ?? 'Your Home Address'}
                  value={values.address}
                  onChange={handleInputChange}
                  error={errors.address}
                  width={480}
                  size='small'
                  charsAllowed={100}
                />
              </Box>

              <Box paddingLeft={1}>
                <Controls.WelloType
                  name='addressType'
                  required={true}
                  label={formLabels?.addressTypeLabel ?? 'TYPE'}
                  type={placeholderLables?.addressTypeLabel ?? 'Type'}
                  value={values.addressType}
                  onChange={handleInputChange}
                  width={90}
                />
              </Box>
            </Box>
            <Box display='flex' py={1}>
              <Box
                paddingRight={1}
                paddingLeft={
                  values.gender.length !== 0 && values.dob.length !== 0
                  ? 26
                  : 27
                }
              >
                <Controls.WelloInput
                  name='fathersName'
                  required={false}
                  size='small'
                  label={formLabels?.fatherNameLabel ?? `FATHER'S NAME `}
                  type='text'
                  value={values.fathersName}
                  error={errors.fathersName}
                  placeholder={placeholderLables?.fatherNameLabel ?? 'First, Middle and Last Name'}
                  width={480}
                  onChange={handleInputChange}
                  charsAllowed={300}
                />
              </Box>
            </Box>
            {values.gender === 'female' &&
              values.maritalStatus === 'M' &&
              values.age >= 18 && (
                <Box display='flex' py={1}>
                  <Box
                    paddingRight={1}
                    paddingLeft={
                      values.gender.length !== 0 && values.dob.length !== 0
                      ? 26
                  : 27
                    }
                  >
                    <Controls.WelloInput
                      name='husbandName'
                      required={false}
                      size='small'
                      label={
                        formLabels?.husbandNameLabel ??
                        `Husband’s Name`
                      }
                      type='text'
                      value={values.husbandName}
                      error={errors.husbandName}
                      placeholder={placeholderLables?.husbandNameLabel ?? 'First, Middle and Last Name'}
                      width={480}
                      onChange={handleInputChange}
                      charsAllowed={300}
                    />
                  </Box>
                </Box>
              )}

          </Box>
        </Form>
      </DialogContent>
      <DialogActions>
        <Controls.WelloButton
          type='button'
          text={resetButtonText ?? 'Cancel (esc)'}
          size='small'
          variant='outlined'
          backgroundColor='#fefefe'
          color='#4543d9'
          onClick={cancel}
          id='cancel'
        />
        <Controls.WelloButton
          type='button'
          text={submitButtonText ?? 'Add Patient'}
          size='small'
          variant='outlined'
          backgroundColor='#4543d9'
          color='#cdcdcd'
          onClick={handleSubmit}
          id='add_patient'
        />
      </DialogActions>
    </Dialog>
  )
}
