import React, { useState } from 'react'
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  makeStyles,
  Grid,
  Paper,
  Typography,
  Box,
  Divider
} from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { Controls } from '../../../PrmitiveComponent/component/Controls'
import CloseIcon from '@material-ui/icons/Close'
import Geocode from 'react-geocode'
import PublishIcon from '@material-ui/icons/Publish'
Geocode.setApiKey('AIzaSyBub-Ru3cd4vj1TxkcC4anEEftL7JMXAiw')
Geocode.setLanguage('en')
Geocode.setRegion('in')
Geocode.enableDebug()

export interface IFormLabels {
  nameLabel?: string
  urlLabel?: string
  logoLabel?: string
  addressLabel?: string
  typeLabel?: string
  phoneNumberLabel?: string
  emailLabel?: string
}

export interface IUnitAdmin {
  children?: React.ReactNode
  count: number
  content: string[]
  buttonText?: string
  onClickContinue: () => void
  formLabels?: IFormLabels
  onValidSubmit?: (orgDetail: R4.IOrganization) => void
  preAddedOrg?: R4.IOrganization
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={0} variant='outlined' {...props} />
}

export const getType = () => [{ id: 'Main', title: 'Main' }]

const useStyles = makeStyles((theme) => ({}))

const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
  // eslint-disable-next-line no-empty
  if (reason === 'clickaway') {
  }
}

export const OrgSetup: React.FC<IUnitAdmin> = ({
  children,
  count,
  content,
  onClickContinue,
  onValidSubmit,
  preAddedOrg,
  formLabels = {}
}: IUnitAdmin) => {
  const classes = useStyles()
  const [contactList, setContactList] = useState([
    { type: '', phone: '', email: '' }
  ])
  const [latLong, setLatLong] = useState({ lat: 26.08, lng: 80.0004 })
  const [isMarker, setMarker] = useState(true)

  const handleInputChange = (
    e: { target: { name: any; value: any } },
    index: number
  ) => {
    const { name, value } = e.target
    const list = [...contactList]
    list[index][name] = value
    setContactList(list)
  }

  const handleAddressChange = (e: { target: { name: any; value: any } }) => {
    const { value } = e.target
    Geocode.fromAddress(value).then(
      (response: any) => {
        const { lat, lng } = response.results[0].geometry.location
        console.log(lat, lng)
        latLong.lat = lat
        latLong.lng = lng
        setLatLong(latLong)
      },
      (error: any) => {
        console.error(error)
      }
    )
  }

  const handleRemoveClick = (index: number) => {
    const list = [...contactList]
    list.splice(index, 1)
    setContactList(list)
  }

  const handleAddClick = () => {
    setContactList([...contactList, { type: '', phone: '', email: '' }])
  }

  const handleMarkerClick = () => {
    setMarker(true)
  }

  return (
    <Grid
      container
      spacing={0}
      alignContent='center'
      alignItems='center'
      justify='center'
    >
      <Grid item xs={8}>
        <Grid container justify='center' spacing={2} direction='column'>
          <Grid item>
            <Controls.WelloInputBox
              name='name'
              required={true}
              label={formLabels?.nameLabel ?? 'ORGANIZATION NAME'}
              placeholder='Organization Name'
            />
          </Grid>
          <Grid item alignContent='stretch' alignItems='stretch' xs={12}>
            <Controls.WelloInputBox
              name='url'
              required={true}
              label={formLabels?.urlLabel ?? 'ORGANIZATION URL'}
              placeholder='Organization URL'
            />
          </Grid>
          <Grid item alignContent='stretch' alignItems='stretch' xs={12}>
            <Alert variant='outlined' severity='warning' onClose={handleClose}>
              {' '}
              {content[0]}
            </Alert>
          </Grid>
          <Grid item>
            <Controls.WelloInputBox
              name='address'
              required={true}
              label={formLabels?.addressLabel ?? 'MAIN ADDRESS'}
              onChange={handleAddressChange}
              placeholder='Main Address'
            />
          </Grid>
          <Grid item>
            {/*  <Controls.WelloMapPage
                        isMarkerShown={isMarker}
                        onMarkerClick={handleMarkerClick}
                        currentLocation={latLong}
                      />
                      */}

            <Controls.WelloInputFile
              style={{ display: 'none' }}
              name='logo'
              label={formLabels?.logoLabel ?? 'UPLOAD LOGO'}
              required={true}
              icon={<PublishIcon />}
              fileType='Select Logo File'
              accept='image/x-png,image/gif,image/jpeg'
            />
          </Grid>
          <Grid item>
            <Typography>Contact Information </Typography>
            {contactList.map((x, i) => {
              return (
                <Grid key={i} item>
                  <Box display='flex' alignItems='center'>
                    <Box flexGrow={1}>
                      <Grid
                        container
                        direction='row'
                        xs={12}
                        justify='space-between'
                        spacing={1}
                      >
                        {/* <Controls.WelloSelect
                                displayField='type'
                                type='Type'
                                availableOptions={getType()}
                                required={true}
                                onSelectionChanged={(e: any) =>
                                  handleInputChange(e, i)
                                }
                                valueField='type'
                              /> */}
                        <Grid
                          item
                          xs={6}
                          alignContent='stretch'
                          alignItems='stretch'
                        >
                          <Controls.PhoneNumber
                            name='phone'
                            label={
                              formLabels?.phoneNumberLabel ?? 'PHONE NUMBER'
                            }
                            placeholder='Phone Number'
                            type='number'
                            value={x.phone}
                            required={true}
                            onChange={(e: any) => handleInputChange(e, i)}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          alignContent='stretch'
                          alignItems='stretch'
                        >
                          <Controls.WelloEmailInput
                            name='email'
                            label={formLabels?.emailLabel ?? 'EMAIL ADDRESS'}
                            type='email'
                            placeholder='Email Address'
                            required={true}
                            // className={classes.ml10}
                            onChange={(e: any) => handleInputChange(e, i)}
                            value={x.email}
                          />{' '}
                        </Grid>
                      </Grid>
                    </Box>
                    {contactList.length !== 1 && (
                      <React.Fragment>
                        <Controls.WelloActionButton
                          color='secondary'
                          onClick={() => handleRemoveClick(i)}
                        >
                          <CloseIcon />
                        </Controls.WelloActionButton>
                        <br></br>
                      </React.Fragment>
                    )}
                  </Box>
                </Grid>
              )
            })}
          </Grid>

          <div>
            <div onClick={handleAddClick}>
              <Box display='flex' alignItems='center'>
                <Box>
                  <Typography variant='h4'>+ Add More</Typography>
                </Box>
                <Box flexGrow={1}>
                  <Divider />
                </Box>
              </Box>
            </div>
          </div>
          <Grid item>
            <Alert
              icon={false}
              severity='success'
              variant='outlined'
              onClose={handleClose}
            >
              {content[1]}
            </Alert>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
