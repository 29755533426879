import React from 'react'
import { TextField, FormControl } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles((theme) => ({
  inputBoxStyle: {
    height: 2,
    '& ::-webkit-input-placeholder': {
      color: 'red !important'
    }
  }
}))

export interface IProps {
  name: string
  label?: string
  type?: 'text' | 'number' | 'date' | 'email'
  placeholder?: string
  value?: any
  error?: null | undefined
  onChange?: any
  required: boolean
  size?: 'small' | 'medium' | 'large'
  width?: number
}

export const WelloSearchFilter: React.FC<IProps> = ({
  name,
  label,
  type = 'text',
  value,
  error,
  onChange,
  placeholder,
  size,
  width
}: IProps) => {
  const classes = useStyles()
  return (
    <FormControl>
      <TextField
        underlineShow={false}
        variant='outlined'
        name={name}
        value={value}
        fullWidth
        color={useTheme().palette.primary.main}
        type={type}
        required
        size={size}
        className={classes.inputBoxStyle}
        placeholder={placeholder}
        InputProps={{
          style: {
            // borderRadius: 6,
            maxWidth: 480,
            width: width,
            // height: 39,
            color: useTheme().palette.text.primary,
            fontSize: 13,
            backgroundColor: '#ffffff',
            '&::placeholder': {
              color: useTheme().palette.text.primary,
              fontSize: 13
            },
            '&::input': {
              height: 2
            }
          },
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon height='16' width='19'></SearchIcon>
            </InputAdornment>
          )
        }}
        onChange={onChange}
        {...(error && { error: true, helperText: error })}
      />
    </FormControl>
  )
}
