import React from 'react'
import { Button as MuiButton, makeStyles, Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 3.5,
    boxShadow: '1'
  },
  label: {
    textTransform: 'none',
    fontSize: 12,
    fontWeight: 600,
    fontFamily: 'Open Sans Regular'
  }
}))

export interface IProps {
  text: string
  size: any
  color?: string
  variant?: any
  onClick?: any
  backgroundColor?: string
  [other: string]: any
  type?: string
  id?: string
}

export const WelloButtonTab: React.FC<IProps> = ({
  text,
  size,
  color,
  variant,
  onClick,
  other,
  backgroundColor,
  type,
  id
}: IProps) => {
  const classes = useStyles()
  return (
    <Box
      boxShadow={3}
      component={MuiButton}
      variant={variant || 'contained' || 'outlined'}
      size={size || 'large' || 'small'}
      type={type}
      id={id}
      style={{
        backgroundColor: backgroundColor,
        color: color
      }}
      onClick={onClick}
      {...other}
      classes={{ root: classes.root, label: classes.label }}
      focusRipple={false}
    >
      {text}
    </Box>
  )
}
