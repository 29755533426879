import React, { useState } from 'react'
import {
  makeStyles,
  Grid,
  Paper,
  Typography,
  Divider,
  Box
} from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { Controls } from '../../../../components/PrmitiveComponent/component/Controls'
import CloseIcon from '@material-ui/icons/Close'

export interface IFormLabels {
  unitLabel?: string
  roleLabel?: string
  nameLabel?: string
  emailLabel?: string
  phoneNumberLabel?: string
}

export interface IUnitAdmin {
  children?: React.ReactNode
  count: number
  backButtonText?: string
  buttonText?: string
  formLabels?: IFormLabels
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={0} variant='outlined' {...props} />
}

const getType = () => [
  { id: 'Lab', title: 'Lab' },
  { id: 'Hospital', title: 'Hospital' }
]
export const getRole = () => [{ id: 'Lab Admin', title: 'Lab Admin' }]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },

  mainPage: {
    maxWidth: 960,
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    overflow: 'Auto'
  },

  topHeader: {
    fontSize: 16,
    display: 'inline'
  },
  buttonStyle: {
    // marginLeft: 750,
    fontSize: 14,
    display: 'inline'
  },
  box: {
    marginBottom: 10,
    display: 'inline-block'
  },
  ml10: {
    marginLeft: 10
  },

  distance: {
    marginTop: 10
  },

  footer: {
    marginTop: 50
  },

  addMore: {
    backgroundColor: theme.palette.background.paper
  },

  inputBoxStyle: {
    borderRadius: 6,
    // boxShadow: "inset",
    height: 2,
    width: 135,
    boxShadow: 'inset 0 0 3 0 rgba(0, 0, 0, 0.41)',
    backgroundColor: '#ffffff'
    // color:"#4543d9"
  }
}))

/* const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
  if (reason === 'clickaway') {
    // return
  }
} */

export const UnitAdmins: React.FC<IUnitAdmin> = ({
  children,
  count,
  backButtonText,
  buttonText,
  formLabels
}: IUnitAdmin) => {
  const classes = useStyles()
  const [contactList, setContactList] = useState([
    { unit: 'Lab', role: 'Lab Admin', name: '', email: '', phone: '' }
  ])

  const handleInputChange = (e: any, index: any) => {
    const { name, value } = e.target
    const list = [...contactList]
    list[index][name] = value
    setContactList(list)
  }

  const handleRemoveClick = (index: any) => {
    const list = [...contactList]
    list.splice(index, 1)
    setContactList(list)
  }

  const handleAddClick = () => {
    setContactList([
      ...contactList,
      { unit: '', role: '', name: '', email: '', phone: '' }
    ])
  }

  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item xs={12}>
        <Grid container justify='center' spacing={0}>
          <Grid key={1} item>
            <Paper className={classes.mainPage}>
              {children}
              <Grid container className={classes.root} spacing={0}>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    {contactList.map((x, i) => {
                      return (
                        <Grid key={i} item>
                          <br></br>

                          <div className={classes.box}>
                            {/*  <Controls.WelloSelect
                              name='unit'
                              label={formLabels?.unitLabel ?? 'UNIT'}
                              type='Unit'
                              availableOptions={getType()}
                              required={true}
                              value={x.unit}
                              onSelectionChanged={(e: any) =>
                                handleInputChange(e, i)
                              }
                              width={152}
                            />
                            &nbsp;&nbsp;
                            <Controls.WelloSelect
                              name='role'
                              label={formLabels?.roleLabel ?? 'ROLE'}
                              type='role'
                              availableOptions={getRole()}
                              required={true}
                              value={x.role}
                              onSelectionChanged={(e: any) =>
                                handleInputChange(e, i)
                              }
                              width={152}
                            /> */}
                            &nbsp;&nbsp;
                            <Controls.WelloInput
                              name='name'
                              label={formLabels?.nameLabel ?? 'NAME'}
                              placeholder='Name'
                              type='text'
                              required={true}
                              value={x.name}
                              onChange={(e: any) => handleInputChange(e, i)}
                              width={152}
                              charsAllowed={30}
                            />
                            &nbsp;&nbsp;
                            <Controls.WelloEmailInput
                              name='email'
                              label={formLabels?.emailLabel ?? 'EMAIL ADDRESS'}
                              placeholder='Email'
                              type='email'
                              required={true}
                              value={x.email}
                              onChange={(e: any) => handleInputChange(e, i)}
                              width={152}
                            />
                            &nbsp;&nbsp;
                            <Controls.WelloInput
                              name='phone'
                              label={
                                formLabels?.phoneNumberLabel ?? 'PHONE NUMBER'
                              }
                              placeholder='Phone'
                              type='number'
                              required={true}
                              value={x.phone}
                              width={152}
                              onChange={(e: any) => handleInputChange(e, i)}
                              charsAllowed={10}
                            />
                            &nbsp;
                            {contactList.length !== 1 && (
                              <React.Fragment>
                                <Controls.WelloActionButton
                                  color='secondary'
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  <CloseIcon />
                                </Controls.WelloActionButton>
                                <br></br>
                              </React.Fragment>
                            )}
                            <div className={classes.ml10}>
                              {contactList.length - 1 === i && (
                                <div
                                  className={classes.addMore}
                                  onClick={handleAddClick}
                                >
                                  <Box alignItems='center' display='inline'>
                                    <Box>
                                      <Typography className={classes.addMore}>
                                        + Add Another User &nbsp; &nbsp; &nbsp;
                                        &nbsp;{' '}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      {' '}
                                      <Divider />{' '}
                                    </Box>
                                  </Box>
                                </div>
                              )}
                            </div>
                          </div>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
              </Grid>
              <div className={classes.footer}></div>
              <Divider />
              <div className={classes.distance}></div>
              <Grid container justify='flex-end'>
                <div className={classes.buttonStyle}>
                  <Controls.WelloButton
                    type='button'
                    text={backButtonText ?? 'Back'}
                    size='small'
                    backgroundColor='#ffffff'
                    color='#4543d9'
                  />
                  <Controls.WelloButton
                    type='submit'
                    text={buttonText ?? 'Invite Users'}
                    size='small'
                    backgroundColor='#4543d9'
                    color='#ffffff'
                  />
                </div>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
