import React from 'react'
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  useTheme,
  Paper
} from '@material-ui/core'
import { Controls } from '../PrmitiveComponent'

interface IProps {
  isSelected?: boolean
  textTitle?: string
  onClick?: () => void
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0
  }
}))

export const TimerTab: React.FC<IProps> = ({
  isSelected = true,
  textTitle = 'text',
  onClick
}: IProps) => {
  return (
    <div onClick={onClick}>
      {isSelected ? (
        <div style={{ cursor: 'pointer' }}>
          <Controls.WelloButtonTab
            type='button'
            text={textTitle}
            size='small'
            variant='outlined'
            backgroundColor='#ffffff'
            color='#3c3c3c'
          />
        </div>
      ) : (
        <Box p={1}>
          <Typography
            style={{
              fontSize: 12,
              fontWeight: 600,
              fontFamily: 'Open Sans Regular',
              color: '#3c3c3c',
              cursor: 'pointer'
            }}
          >
            {textTitle}
          </Typography>
        </Box>
      )}
    </div>
  )
}
