import { FormControl } from '@material-ui/core'
import React from 'react'

const Control = (props: React.PropsWithChildren<any>) => {
  return (
    <FormControl
      size='small'
      style={{
        ...(props.style ?? {}),
        display: props.hidden == true ? 'none' : undefined
      }}
      component={props.component}
    >
      {props.children}
    </FormControl>
  )
}

export default Control
