import { R4 } from '@ahryman40k/ts-fhir-types';
import { IInvoice, IInvoice_LineItem, IInvoice_PriceComponent, IServiceRequest, ITask } from '@ahryman40k/ts-fhir-types/lib/R4';
import { dinero, add, Currency , toDecimal, allocate, subtract, multiply} from 'dinero.js';
import { INR } from '@dinero.js/currencies';
// import { getDateDifferceinDays } from './fhir-time-utils';
// import { isRequestOpdConsultation, isRequestOpdDayCare, isTaskDischarged, isTaskFeedbackRcd, isTaskInitiateDischarge, lineItemPackageSequences } from './../fhirPath/fhirPathUtils'
/**
 * It takes an invoice resource, a new discount in Rs, and a new discount percentage, and returns a new
 * invoice resource with the discount updated
 * @param invoiceResource - The invoice resource that you want to update.
 * @param {number} [newDiscountRs] - The new discount in Rs.
 * @param {number} [newDiscountPercentage] - The new discount percentage that the user has entered.
 * @returns invoiceResource
 */
export function discountCalculationHelperFunction(
  invoiceResource: R4.IInvoice,
  newDiscountRs?: number,
  newDiscountPercentage?: number,
) {
  console.log('here')
  // return new invoice net based on percentage or discount in invoice resource
  // 1st priority is given for new user input discount percentage or discount number
  // If the user preference valueset is not there then by default discount number is taken
  // If the discount is not there then 0 is considered
  computeTotal(invoiceResource);
  try{
    const findIndex = getLineItemIndexPackage(invoiceResource.lineItem);
    // invoiceResource.lineItem.findIndex(
    //   // Assumption that discount is present in 1st sequence along with the package
    //   (lineItem) => lineItem.sequence == 1,
    // );
    if(newDiscountPercentage > 1 || newDiscountPercentage < 0){
      throw new Error(`Discount percentage cannot be more than 100% or less than 0%`);
    }
    
    if (newDiscountRs>=0) {
      if(newDiscountRs > invoiceResource.totalGross.value || newDiscountRs < 0 ){
        throw new Error(`Discount amount cannot be more than Gross total or less than 0`);
      }
      // Update the lineitem with the new discount in rs and now the user preference is fixed to discount in Rs
      const newDiscountPriceRounded = dineroFromFloat(newDiscountRs, INR,  2);
      invoiceResource.lineItem[findIndex].priceComponent[1].amount.value =
        newDiscountPriceRounded;
      // Update totalNet
      invoiceResource.totalNet.value = 
        dineroSubtract(invoiceResource.totalGross.value, newDiscountPriceRounded ,  INR,  2);

      // Update the valueset of user preference
      invoiceResource.lineItem[findIndex].priceComponent[1].code = {
        coding: [
          {
            system: 'http://unitsofmeasure.org',
            code: '[arb’U]',
            display: 'arbitrary unit',
          },
        ],
      };
      // Update the modified percentage
      const newDiscountPercentageCalculated = 
        dineroToDivide(newDiscountPriceRounded, invoiceResource.totalGross.value, INR,  2);
      invoiceResource.lineItem[findIndex].priceComponent[1].factor =
        newDiscountPercentageCalculated;
    } else if (newDiscountPercentage>=0) {
      const newDiscount = 
        getDiscountFfromPercentage(invoiceResource.totalGross.value,  INR,  2, newDiscountPercentage);
      // Update the discount percentage  
      invoiceResource.lineItem[findIndex].priceComponent[1].factor =
        dineroForPercentageDecimal( newDiscountPercentage, INR,  2);
      // Update totalNet
      invoiceResource.totalNet.value =
        dineroSubtract(invoiceResource.totalGross.value, newDiscount ,  INR,  2);
      // Update the valueset of user preference
      invoiceResource.lineItem[findIndex].priceComponent[1].code = {
        coding: [
          {
            system: 'http://unitsofmeasure.org',
            code: '%',
            display: 'percent',
          },
        ],
      };
      // Update the discount in Rs
      invoiceResource.lineItem[findIndex].priceComponent[1].amount.value =
        newDiscount;
    } else {
      if (invoiceResource.lineItem[findIndex].priceComponent[1].code) {
        // When User preference is present check whether it is in percentage or discount in Rs
        const percentagePrefCode = invoiceResource.lineItem[
          findIndex
        ].priceComponent[1].code.coding.find(
          (code) =>
            code.code == '%' && code.system == 'http://unitsofmeasure.org',
        );
        if (percentagePrefCode) {
          const discountPercentage =
            invoiceResource.lineItem[findIndex].priceComponent[1].factor;
          const discount = getDiscountFfromPercentage(invoiceResource.totalGross.value,  INR,  2, discountPercentage);
          // Update totalNet
          invoiceResource.totalNet.value =
            dineroSubtract(invoiceResource.totalGross.value, discount ,  INR,  2);
          // Update the discount in Rs.  
          invoiceResource.lineItem[findIndex].priceComponent[1].amount.value = discount;  
        } else {
          const discountPrice = 
            dineroFromFloat(invoiceResource.lineItem[findIndex].priceComponent[1].amount.value, INR,  2);
          // Update totalNet
          invoiceResource.totalNet.value =
            dineroSubtract(invoiceResource.totalGross.value, discountPrice ,  INR,  2);
          // Update the discount in %.  
          const newDiscountPercentageCalculated = 
            dineroToDivide(discountPrice, dineroFromFloat(invoiceResource.totalGross.value, INR,  2), INR,  2);
            // discountPrice / dineroFromFloat(invoiceResource.totalGross.value, INR,  2);
          invoiceResource.lineItem[findIndex].priceComponent[1].factor = newDiscountPercentageCalculated;  
        }
      } else {
        // Default discount in number is taken as preference if the code preference is not there
        // For old invoice records there is no user preference code and factor/discount percentage will not be there
        invoiceResource.lineItem[findIndex].priceComponent[1].code = {
          coding: [
            {
              system: 'http://unitsofmeasure.org',
              code: '[arb’U]',
              display: 'arbitrary unit',
            },
          ],
        };
        const discountPrice =
          invoiceResource.lineItem[findIndex].priceComponent[1].amount.value ?? 0;
        // Update totalNet
        invoiceResource.totalNet.value =
          dineroSubtract(invoiceResource.totalGross.value, discountPrice ,  INR,  2);
        // Update the discount
        invoiceResource.lineItem[findIndex].priceComponent[1].amount.value =
          discountPrice;
        // Update the discount Percentage
        const newDiscountPercentageCalculated =
            dineroToDivide(discountPrice, dineroFromFloat(invoiceResource.totalGross.value, INR,  2), INR,  2);
          // discountPrice / dineroFromFloat(invoiceResource.totalGross.value, INR,  2);
        invoiceResource.lineItem[findIndex].priceComponent[1].factor =
          newDiscountPercentageCalculated;
      }
    }
    return invoiceResource;
  } catch(error) {
    throw new Error(`Error while discount calculation ${error}`);
  }
}

/**
 * It takes a float, a currency, and a scale, and returns a float
 * @param {number} float - The float value that you want to convert to dinero.
 * @param currency - Currency<number>
 * @param {number} scale - number - The number of decimal places to round to.
 * @returns A number
 */
function dineroFromFloatForPercentage( float: number, currency: Currency<number>, scale: number ) {
  const inrBase = INR.base as number;  
  const factor = inrBase ** INR.exponent || scale;
  const amount = Math.round(float * factor * 100 );
  const dineroObj = dinero({ amount, currency, scale });
  return parseFloat(toDecimal(dineroObj)) * 0.01;
}
/**
 * It takes a float, a currency, and a scale, and returns a float
 * @param {number} float - The number you want to convert to dinero.
 * @param currency - Currency<number> - The currency object that you want to convert to.
 * @param {number} scale - The number of decimal places to round to.
 * @returns A float value of the dinero object.
 */

function dineroFromFloat( float: number, currency: Currency<number>, scale: number ) {
  const inrBase = INR.base as number;  
  const factor = inrBase ** INR.exponent || scale;
  const amount = Math.round(float * factor);
  const dineroObj = dinero({ amount, currency, scale });
  return parseFloat(toDecimal(dineroObj));
}
/**
 * `dineroADD` takes two numbers, a currency, and a scale, and returns the sum of the two numbers in
 * the given currency and scale
 * @param {number} float1 - number - The first number to add
 * @param {number} float2 - number - The amount to add to the first float.
 * @param currency - Currency<number> - The currency object that you want to use.
 * @param {number} scale - number - The number of decimal places to round to.
 * @returns A number
 */
function dineroADD( float1: number, float2: number, currency: Currency<number>, scale: number ) {
  const inrBase = INR.base as number;  
  const factor = inrBase ** INR.exponent || scale;
  const amount1 = Math.round(float1 * factor);
  const amount2 = Math.round(float2 * factor);
  const dineroObj = dinero({ amount: amount1, currency, scale });
  const dineroObj2 = dinero({ amount: amount2, currency, scale });
  return parseFloat(toDecimal(add(dineroObj, dineroObj2)));
}
/**
 * `dineroForPercentageDecimal` takes a float, a currency, and a scale, and returns a dinero object
 * with the amount of the float as a percentage of the currency's base
 * @param {number} float - number - The number you want to convert to dinero.
 * @param currency - Currency<number>
 * @param {number} scale - number of decimal places to round to
 * @returns A dinero object with the amount, currency and scale.
 */
function dineroForPercentageDecimal( float: number, currency: Currency<number>, scale: number ) {
  const inrBase = INR.base as number;  
  const factor = inrBase ** INR.exponent || scale;
  const amount = Math.round(float * factor * 100 );
  const dineroObj = dinero({ amount, currency, scale });
  return dineroMultiply(parseFloat(toDecimal(dineroObj)), 0.01, currency, scale);;
}

/**
 * It takes a number, a currency, a scale and a share and returns a number
 * @param {number} float - The amount to be discounted
 * @param currency - Currency<number> - The currency object.
 * @param {number} scale - number,
 * @param {number} share - The percentage of the discount.
 */

function getDiscountFfromPercentage(float: number, currency: Currency<number>, scale: number, share: number) {
//   const power = scale + 1;
//   const rest = 100 ** power - share;
  const rest = 100 - (share * 100);
  const inrBase = INR.base as number;  
  const factor = inrBase ** INR.exponent || scale;
  const amount = Math.round(float * factor);
  const dineroObj = dinero({ amount, currency, scale });
  const [chunk] = allocate(dineroObj, [ share*100, rest]);

  return  parseFloat(toDecimal(chunk));
}

/**
 * It takes two floats, a currency, and a scale, and returns a float
 * @param {number} float1 - number - The first number to be subtracted
 * @param {number} float2 - number - The number to be subtracted from float1
 * @param currency - Currency<number> - The currency object that you want to use.
 * @param {number} scale - number - The number of decimal places to round to.
 * @returns A function that takes in a float, a float, a currency, and a scale and returns a float.
 */
function dineroSubtract( float1: number, float2: number, currency: Currency<number>, scale: number ) {
  const inrBase = INR.base as number;  
  const factor = inrBase ** INR.exponent || scale;
  const amount1 = Math.round(float1 * factor);
  const amount2 = Math.round(float2 * factor);
  const dineroObj = dinero({ amount: amount1, currency, scale });
  const dineroObj2 = dinero({ amount: amount2, currency, scale });
  return parseFloat(toDecimal(subtract(dineroObj, dineroObj2)));
}

/**
 * It takes two numbers, a currency and a scale, and returns the product of the two numbers in the
 * given currency and scale
 * @param {number} float1 - number - The first number to multiply
 * @param {number} float2 - number - The number to multiply by.
 * @param currency - Currency<number> - The currency object that you want to use.
 * @param {number} scale - number - The number of decimal places to round to.
 * @returns A number
 */
function dineroMultiply( float1: number, float2: number, currency: Currency<number>, scale: number ) {
  const inrBase = INR.base as number;  
  const factor = inrBase ** INR.exponent || scale;
  const amount1 = Math.round(float1 * factor);
  const amount2 = Math.round(float2 * factor);
  const dineroObj1 = dinero({ amount: amount1, currency, scale });
//   const dineroObj2 = dinero();
  return parseFloat(toDecimal(multiply(dineroObj1, { amount: amount2, scale })));
}

/**
 * `dineroToDivide` takes two floats, a currency, and a scale, and returns a Dinero object with the
 * value of the first float divided by the second float
 * @param {number} float1 - The first number to divide
 * @param {number} float2 - The number you want to divide by
 * @param currency - Currency<number>
 * @param {number} scale - number - the number of decimal places you want to round to.
 * @returns A Dinero object with the currency and scale of the currency passed in.
 */
function dineroToDivide( float1: number, float2: number, currency: Currency<number>, scale: number ) {
  const divide = float1/float2;
  return dineroForPercentageDecimal(divide, currency,  scale);
}
/**
 * It adds line items to an invoice and calculates the gross total amount
 * @param invoice - R4.IInvoice - The invoice object that you want to add line items to.
 * @param {R4.IInvoice_LineItem[]} lineItems - R4.IInvoice_LineItem[]
 * @returns The invoice object with the line items added to it.
 */
export function addLineItems(
  invoice: R4.IInvoice,
  lineItems: R4.IInvoice_LineItem[],
) {
  // Add the line items to the invoice lineItems
  lineItems.forEach((lineItem) => {
    invoice.lineItem.push(lineItem);
  });

  computeTotal(invoice);
  discountCalculationHelperFunction(invoice);
  return invoice;
}

// if you are adding/deleting line item, then you will add to invoice.lineItem and
/**
 * It takes an invoice and an array of line items, and then deletes the line items from the invoice
 * @param invoice - R4.IInvoice - The invoice object that you want to update
 * @param {R4.IInvoice_LineItem[]} lineItems - R4.IInvoice_LineItem[]
 * @returns The invoice object with the line items deleted.
 */
export function deleteLineItems(
  invoice: R4.IInvoice,
  lineItems: R4.IInvoice_LineItem[],
) {
  // Add the line items to the invoice lineItems
  lineItems.forEach((lineItem) => {
    // find the index of line item of the deleted
    const findIndex = invoice.lineItem.indexOf(lineItem);
    // console.log(`find index is ${findIndex}`);
    if(findIndex > 0){
      invoice.lineItem.splice(findIndex, 1);
    }
  });
  computeTotal(invoice);
  discountCalculationHelperFunction(invoice);
  return invoice;
}

/**
 * It takes an invoice as an argument, loops through the lineItems, calculates the gross total amount,
 * and then updates the invoice's totalGross.value with the calculated gross total amount
 * @param invoice - R4.IInvoice - This is the invoice object that is passed to the function.
 * @returns The invoice is being returned.
 */
//  IF you are modifying the factor of multiple lineItems and call this function,
// you will get updated totalGross, totalNet and discount
export function modifyQuantityOfInvoice(invoice: R4.IInvoice) {
  computeTotal(invoice);
  discountCalculationHelperFunction(invoice);
  return invoice;
}

/**
 * It takes an invoice as an argument, loops through the line items, and calculates the total gross
 * amount
 * @param invoice - R4.IInvoice - This is the invoice object that is passed to the function.
 * @returns the invoice object.
 */
export function computeTotal(invoice: R4.IInvoice) {
  let grossTotalAmount = 0;
  let sequence = 1;
  try{
    invoice.lineItem.forEach((lineItem) => {
    // Add sequence for all the line items as there will be line items added and deleted
    // and sequence will be in different order
    lineItem.sequence = sequence;
    const lineItemPrice: IInvoice_PriceComponent[] = lineItem?.priceComponent;
    // Calculate the gross total amount from the updated lineItems
    grossTotalAmount +=
      dineroMultiply(lineItemPrice[0].amount?.value, lineItemPrice[0].factor ,  INR,  2);
      // lineItemPrice[0].amount?.value * lineItemPrice[0].factor;
    sequence = sequence + 1;
  });
  const invoiceGross = grossTotalAmount;
  // console.log('invoiceGross is ', invoiceGross);
  invoice.totalGross.value = invoiceGross;
  return invoice;
  } catch(error) {
    throw new Error(`Error while total computation ${error}`);
  }
  
}

export function updateInvoiceQuantityOverstay(days: number, actualDays: number, serviceRequest: IServiceRequest, invoice: IInvoice, mainTask: ITask, currentDate = new Date().toISOString()) {
    // const days = getDateDifferceinDays(serviceRequest.occurrencePeriod.end, serviceRequest.occurrencePeriod.start);
    // const actualDays = getDateDifferceinDays(currentDate, serviceRequest.occurrencePeriod.start);
    // console.log(`days is ${days} and actual days is ${actualDays}`);
    // console.log(`isTaskInitiateDischarge(mainTask)[0] ${isDischargeInitiated(mainTask)}`);
    // console.log(`isTaskDischarged(mainTask)[0] ${isDischarged(mainTask)}`);
    // console.log(`isTaskFeedbackRcd(mainTask)[0] ${isFeedbackRcd(mainTask)}`)
    // console.log(`isRequestOpdConsultation is ${isOPDForCheck(serviceRequest)} `)
    if(isOPDForCheck(serviceRequest)){
    // if it is opd
      return invoice;
    }
    if(isDischargeInitiated(mainTask) || isDischarged(mainTask) || isFeedbackRcd(mainTask)){
      // If he is under the flow of discharge
      return invoice;
    }
    if(actualDays <= days){
      return discountCalculationHelperFunction(invoice);
    }
    // console.log(`isPackageManualEntry is ${isPackageManualEntry(invoice)}`);
    
    if(isPackageManualEntry(invoice)){
      // Manual entry by grm
      return discountCalculationHelperFunction(invoice);
    }
    if(actualDays > days){
        // Modify quantity of Package and return updated invoice resource
        invoice.lineItem?.forEach((lineItem) =>{
          // console.log(`line item outside if is ${lineItem}`);
          if(isLineItemHasPackageOrAttendant(lineItem)){
            const priceComponents: IInvoice_PriceComponent[] = lineItem.priceComponent;
            priceComponents[0].factor = actualDays;
            lineItem.priceComponent = priceComponents;
            // console.log(`line item is ${lineItem}`);
          }
      });
      // console.log(JSON.stringify(invoice));
      return modifyQuantityOfInvoice(invoice);
    }
    return discountCalculationHelperFunction(invoice);
}

export function isLineItemHasPackageOrAttendant(lineItem: IInvoice_LineItem){
  let flag = false;
  lineItem?.priceComponent?.forEach((priceComponent) => {
    priceComponent.code?.coding?.forEach((coding) => {
      if (coding.code == '_DedicatedServiceDeliveryLocationRoleType' && coding.system == 'http://terminology.hl7.org/CodeSystem/v3-RoleCode'){
        // console.log(`coding inside if block is ${JSON.stringify(coding)}`);        
        flag = true;
      }
    })
  })
  if(flag){
    return true;
  }
  return false;
}

export function isLineItemHasPackage(lineItem: IInvoice_LineItem){
  let flag = false;
  lineItem?.priceComponent?.forEach((priceComponent) => {
    priceComponent.code?.coding?.forEach((coding) => {
      if (coding.code == '1681000175101' && coding.system == 'http://snomed.info/sct'){
        // console.log(`coding inside if block is ${JSON.stringify(coding)}`);        
        flag = true;
      }
    })
  })
  if(flag){
    return true;
  }
  return false;
}

export function isPackageManualEntry(invoice: IInvoice){
  let flag = false;
  const packageIndex = getLineItemIndexPackage(invoice.lineItem); 
  const lineItem = invoice.lineItem[packageIndex];
  lineItem?.priceComponent?.forEach((priceComponent) => {
    priceComponent.extension?.forEach((extension) => {
      if (extension?.url == 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-component-modification-time-ext'){
        // console.log(`coding inside if block is ${JSON.stringify(coding)}`);        
        flag = true;
      }
    })
  })
  if(flag){
    return true;
  }
  return false;
}

export function addPackageManualEntry(invoice: IInvoice){
  invoice.lineItem?.forEach((lineItem) =>{
        if(isLineItemHasPackageOrAttendant(lineItem)){
          const priceComponents: IInvoice_PriceComponent[] = lineItem.priceComponent;
          priceComponents[0].extension = [
            {
              url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-component-modification-time-ext',
              valueDateTime: new Date().toISOString(),
            },
          ];
          lineItem.priceComponent = priceComponents;
          // console.log(`line item is ${lineItem}`);
        }
})}

export function getLineItemIndexPackage(lineItems: IInvoice_LineItem[]){
  return lineItems.findIndex((lineItem) => {
    return isLineItemHasPackage(lineItem);
  })
}

export function isDischargeInitiated(task: R4.ITask) {
  let res = false
  const code = getCodeOfSystemFromCodableConcept(
    task.businessStatus!,
    'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status'
  )
  if (code) res = code.code === 'initiate-discharge'
  return res
}

export function isDischarged(task: R4.ITask) {
  let res = false
  const code = getCodeOfSystemFromCodableConcept(
    task.businessStatus!,
    'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status'
  )
  if (code) res = code.code === 'discharged'
  return res
}

export function isFeedbackRcd(task: R4.ITask) {
  let res = false
  const code = getCodeOfSystemFromCodableConcept(
    task.businessStatus!,
    'http://wellopathy.com/fhir/india/core/CodeSystem/service-business-status'
  )
  if (code) res = code.code === 'feedback-received'
  return res
}

export function getCodeOfSystemFromCodableConcept(
  codeableConcept: R4.ICodeableConcept,
  system: string
): R4.ICoding | undefined {
  if (codeableConcept) {
    if (codeableConcept.coding && codeableConcept.coding.length > 0) {
      return codeableConcept.coding.find((e) => e.system === system)
    }
  }
  return undefined
}

export function isOPDForCheck(serviceRequest: IServiceRequest): boolean {
  let isOPD: boolean = false
  if (serviceRequest.code) {
    const codingData: R4.ICoding[] =
      serviceRequest.code.coding ?? []
    if (codingData.length > 0) {
      for (let i = 0; i < codingData.length; i++) {
        if (
          codingData[i].code === '304903009' ||
          codingData[i].code === '33022008'
        )
          isOPD = true
      }
    }
  }
  return isOPD;
}