import React from 'react'
import { TextField, FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'

const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
  { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 }
]

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: 'blue',
    fontFamily: 'Roboto Mono',
    backgroundColor: '#f2f2f2',
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'blue'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'red'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '2px',
      borderColor: 'blue'
    }
  }
}))

export interface IProps {
  name?: string
  label?: string
  type?: 'text' | 'number' | 'date' | 'email'
  placeholder?: string
  value?: any
  error?: null | undefined
  onChange?: any
  required?: boolean
  size?: 'small' | 'medium' | 'large'
  width?: number
}

export const WelloAutoComplete: React.FC<IProps> = ({
  name,
  label,
  type = 'text',
  value,
  error,
  onChange,
  placeholder,
  size,
  width
}: IProps) => {
  const classes = useStyles()
  return (
    <FormControl {...(error && { error: true })}>
      <Typography style={{ color: '#4d4d4d', fontSize: 12, fontWeight: 600 }}>
        &nbsp;&nbsp;{label}
      </Typography>
      <Autocomplete
        id='combo-box-demo'
        classes={classes}
        options={top100Films}
        disableClearable
        forcePopupIcon={false}
        style={{ width: 300 }}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant='outlined'
              fullWidth
              InputProps={{
                ...params.InputProps
              }}
            />
          )
        }}
      />

      {/* <TextField
        underlineShow={false}
        variant="outlined"
        name={name}
        value={value}
        fullWidth
        color="#4543d9"
        type={type}
        required
        size={size}
        clasName={classes.inputBoxStyle}
        placeholder={placeholder}
        // InputProps={{ classes: { input: classes.inputBoxStyle,width:10  }}}
        InputProps={{
          style: {
            borderRadius: 6,
            maxWidth: 480,
            width: width,
            height: 39,
            color: "#4d4d4d",
            fontSize: 13,
            backgroundColor: "#ffffff",
            "&::placeholder": {
              color: "#4d4d4d",
              fontSize: 13,
            },
            "&::input": {
              height: 2,
            },
          },
        }}
        onChange={onChange}
        {...(error && { error: true, helperText: error })}
      /> */}
    </FormControl>
  )
}
