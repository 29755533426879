import {
  InputLabel,
  TextField,
  Select,
  Checkbox,
  MenuItem,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Input,
  Typography,
  Grid,
  Tooltip,
  IconButton,
  InputAdornment,
  FormGroup
} from '@material-ui/core'
import Control from './abstract/control'
import React from 'react'
import { RegisterField, useControlled } from 'react-cool-form'
import {
  displayConditionSatisfied,
  getConditionalValue,
  getOptionInformation,
  getOptionLabel,
  getOptionObjectByCode,
  getOptionValue,
  hasConditionalValueSet,
  hasDisplayConditions
} from './utils/fieldUtils'
import _ from 'lodash'
import { InfoOutlined, InfoRounded } from '@material-ui/icons'
import { getDefaultValueOfField } from './utils/observationFormUtils'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'

const FIELD_TYPE = {
  default: 'text',
  text: 'text',
  textarea: 'textarea',
  number: 'number',
  integer: 'number',
  boolean: 'boolean',
  date: 'date',
  'data-time': 'date',
  time: 'date',
  email: 'email',
  radio: 'radio',
  checkbox: 'checkbox'
}

const Field = ({ as, name, ...restProps }) => {
  const [fieldProps] = useControlled(name, restProps)
  const Component = as

  return <Component {...fieldProps} />
}

const getFieldType = (type?: string) => {
  if (type) {
    return FIELD_TYPE[type]
  }
  return FIELD_TYPE['default']
}

const buildField = (
  key: string,
  value: SchemaProp,
  index: number,
  formValues?: any,
  formPrefixName?: string,
  formPrefixIndex?: number,
  getState?: any,
  setValue?: any,
  formSchema?: Schema
) => {
  const formSchemaCodeLab = getOptionLabel(
    formSchema?.properties['code']?.options
  )
  let fieldValue = undefined
  if (hasConditionalValueSet(value.conditions)) {
    fieldValue = getConditionalValue(
      value.conditions,
      formValues,
      formSchema,
      formPrefixName,
      formPrefixIndex
    )
    console.log('------------fieldValue---------------', fieldValue)

    if (fieldValue && getState(key) !== fieldValue) {
      setValue(key, fieldValue)
    }
  }

  if (value.name === 'code') {
    return (
      <Control key={key + '_control'}>
        {!value.hideField && (
          <Box display='flex' flexDirection='row'>
            <Typography
              variant='subtitle1'
              style={{
                fontSize: '12px',
                fontWeight: 'bolder'
              }}
            >
              {getOptionLabel(value.options)}
            </Typography>
            {getOptionInformation(value.options) && (
              <Tooltip
                title={getOptionInformation(value.options)}
                style={{
                  verticalAlign: 'super'
                }}
              >
                <IconButton
                  size='small'
                  disabled={false}
                  style={{
                    padding: '0px',
                    margin: '0px',
                    marginLeft: '2px',
                    marginTop: '-12px'
                  }}
                >
                  <InfoOutlined
                    fontSize='small'
                    scale='0.25'
                    color='primary'
                    style={{ fontSize: '15px' }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
        <input
          value={getOptionValue(value.options)}
          disabled={true}
          name={key}
          hidden={true}
        />
      </Control>
    )
  }

  if (value.name === 'effectiveDateTime') {
    return (
      <Control key={index}>
        <input
          value={new Date().toISOString()}
          disabled={true}
          name={key}
          hidden={true}
        />
      </Control>
    )
  }
  if (value.name === 'status') {
    return (
      <Control key={index}>
        <input value='final' disabled={true} name={key} hidden={true} />
      </Control>
    )
  }
  if (hasDisplayConditions(value.conditions)) {
    if (
      !displayConditionSatisfied(
        value.conditions,
        formValues,
        formSchema,
        formPrefixName,
        formPrefixIndex
      )
    ) {
      return <> </>
    }
  } else if (value.hideField) {
    return <></>
  }

  if (value.type === 'boolean') {
    return (
      <Grid
        item
        key={key}
        zeroMinWidth
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          margin: '0px',
          padding: '8px 0px'
        }}
      >
        {value.title && !value.title.includes('value') && (
          <Box px={1} minWidth='120px'>
            <Typography variant='body1' color='initial'>
              {value.title}
            </Typography>
          </Box>
        )}

        <Control
          key={index}
          style={{
            width: '100%'
          }}
        >
          <RadioGroup
            name={key}
            aria-label='mood'
            row
            value={getState(key) ?? null}
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              justifyItems: 'flex-start'
            }}
            onChange={(event) => {
              setValue(key, event.target.value)
            }}
          >
            <FormControlLabel
              control={
                <Radio
                  size='small'
                  id={'radio_' + _.snakeCase(formSchemaCodeLab + '_yes')}
                  style={{
                    padding: '0px 8px 0px 8px',
                    color: 'primary',
                    fontSize: '12px',
                    transform: 'scale(0.75)'
                  }}
                />
              }
              value={'true'}
              label={
                <Typography
                  style={{
                    padding: '0px',
                    color: 'primary',
                    fontSize: '12px'
                  }}
                >
                  {' '}
                  Yes{' '}
                </Typography>
              }
              style={{
                padding: '0px',
                color: 'primary',
                fontSize: '12px',
                maxWidth: '200px',
                width: '200px'
              }}
            />
            <FormControlLabel
              control={
                <Radio
                  size='small'
                  id={'radio_' + _.snakeCase(formSchemaCodeLab + '_no')}
                  style={{
                    padding: '0px 8px 0px 8px',
                    color: 'primary',
                    fontSize: '12px',
                    transform: 'scale(0.75)'
                  }}
                />
              }
              value={'false'}
              label={
                <Typography
                  style={{
                    padding: '0px',
                    color: 'primary',
                    fontSize: '12px'
                  }}
                >
                  {' '}
                  No{' '}
                </Typography>
              }
              style={{
                padding: '0px',
                color: 'primary',
                fontSize: '12px',
                maxWidth: '200px',
                width: '200px'
              }}
            />
          </RadioGroup>
        </Control>
      </Grid>
    )
  } else if (
    value.type === 'radio' &&
    value.options &&
    value.options.length > 0
  ) {
    return (
      <Grid
        key={key + '_' + 'grid_item'}
        item
        zeroMinWidth
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          margin: '0px',
          padding: '4px 0px'
        }}
      >
        <Control
          key={key + '_' + 'control'}
          style={{
            display: 'flex'
          }}
        >
          <RadioGroup
            name={key}
            row
            value={fieldValue ?? getState(key) ?? null}
            style={{
              display: 'flex',

              justifyItems: 'flex-start'
            }}
            onChange={(event) => {
              setValue(key, event.target.value)
            }}
          >
            {value.options.map((option, index) => {
              return (
                <FormControlLabel
                  style={{
                    maxWidth: '200px',
                    width: '200px',
                    paddingTop: '2px',
                    paddingBottom: '2px'
                  }}
                  name={key}
                  control={
                    <Radio
                      size='small'
                      id={
                        'radio_' +
                        _.snakeCase(
                          formSchemaCodeLab + '_' + getOptionLabel(option)
                        )
                      }
                      style={{
                        padding: '0px 8px 0px 8px',
                        color: 'primary',
                        fontSize: '12px',
                        transform: 'scale(0.75)'
                      }}
                    />
                  }
                  value={getOptionValue(option)}
                  label={
                    <Box display='flex' flexDirection='row'>
                      <Typography
                        style={{
                          padding: '0px',
                          color: 'primary',
                          fontSize: '12px'
                        }}
                      >
                        {getOptionLabel(option)}
                      </Typography>
                      {getOptionInformation(option) && (
                        <Tooltip
                          title={getOptionInformation(option)}
                          style={{
                            verticalAlign: 'super'
                          }}
                        >
                          <IconButton
                            size='small'
                            style={{
                              padding: '0px',
                              margin: '0px',
                              marginLeft: '2px',
                              marginTop: '-12px'
                            }}
                          >
                            <InfoOutlined
                              fontSize='small'
                              scale='0.5'
                              color='primary'
                              style={{ fontSize: '15px' }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  }
                  key={index}
                />
              )
            })}
          </RadioGroup>
        </Control>
      </Grid>
    )
  } else if (
    value.type === 'checkbox' &&
    value.options &&
    value.options.length > 0
  ) {
    return (
      <Grid
        key={key + '_' + 'grid_item'}
        item
        zeroMinWidth
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          margin: '0px',
          padding: '8px 0px'
        }}
      >
        <Control
          key={key + '_' + 'control'}
          style={{
            flexGrow: 1,
            display: 'flex'
          }}
        >
          <FormGroup
            row
            style={{
              display: 'flex',

              justifyItems: 'flex-start'
            }}
          >
            {value.options.map((option, index) => {
              return (
                <FormControlLabel
                  style={{
                    flexGrow: 1,
                    maxWidth: '200px',
                    width: '200px'
                  }}
                  name={key}
                  control={
                    <Checkbox
                      id={
                        'check_' +
                        _.snakeCase(
                          formSchemaCodeLab + '_' + getOptionLabel(option)
                        )
                      }
                      size='small'
                      checked={(getState(key) ?? []).includes(
                        getOptionValue(option)
                      )}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setValue(key, [
                            ...(getState(key) ?? []),
                            event.target.value
                          ])
                        } else {
                          setValue(
                            key,
                            (getState(key) ?? []).filter(
                              (value) => value !== event.target.value
                            )
                          )
                        }
                        // setValue(key, event.target.value)
                      }}
                      color='primary'
                      style={{
                        padding: '0px 8px 0px 8px',
                        color: 'primary',
                        fontSize: '12px',
                        transform: 'scale(0.75)'
                      }}
                    />
                  }
                  value={getOptionValue(option)}
                  label={
                    <Box display='flex' flexDirection='row'>
                      <Typography
                        style={{
                          padding: '0px',
                          color: 'primary',
                          fontSize: '12px'
                        }}
                      >
                        {getOptionLabel(option)}
                      </Typography>
                      {getOptionInformation(option) && (
                        <Tooltip
                          title={getOptionInformation(option)}
                          style={{
                            verticalAlign: 'super'
                          }}
                        >
                          <IconButton
                            size='small'
                            style={{
                              padding: '0px',
                              margin: '0px',
                              marginLeft: '2px',
                              marginTop: '-12px'
                            }}
                          >
                            <InfoOutlined
                              fontSize='small'
                              scale='0.5'
                              color='primary'
                              style={{ fontSize: '15px' }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  }
                  key={index}
                />
              )
            })}
          </FormGroup>
        </Control>
      </Grid>
    )
  } else if (value.type === 'readonly') {
    return (
      <Control key={index}>
        <Typography variant='body1' color='initial'>
          {' '}
          {getOptionLabel(value.options)}
        </Typography>
        <input
          value={getOptionValue(value.options)}
          disabled={true}
          name={key}
          hidden={true}
        />
      </Control>
    )
  } else if (value.type === 'textarea' || value.type === 'object') {
    return (
      <Grid
        item
        zeroMinWidth
        key={key + '_grid_item'}
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '0px',
          flexGrow: 1,

          marginRight: '8px',
          padding: '8px 0px'
        }}
      >
        <Control
          key={key + '_grid_control'}
          style={{
            flexGrow: 1
          }}
        >
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='row'
            alignContent='center'
            alignItems='center'
            justifyContent='flex-end'
          >
            {!value.title.includes('value') && (
              <Box px={0} minWidth='120px'>
                <Typography
                  variant='body1'
                  color='initial'
                  style={{
                    padding: '0px',
                    color: 'primary',
                    fontSize: '12px'
                  }}
                >
                  {value.title}
                </Typography>
              </Box>
            )}

            <TextField
              name={key}
              id={
                'text_area_' +
                _.snakeCase(formSchemaCodeLab + '_' + value.title ?? value.name)
              }
              title={value.description}
              fullWidth
              type={FIELD_TYPE['text']}
              multiline={true}
              size='small'
              variant='outlined'
              value={fieldValue ?? getState(key) ?? null}
              onChange={(event) => {
                setValue(key, event.target.value)
              }}
              InputLabelProps={{
                style: {
                  fontSize: '12px'
                }
              }}
              InputProps={{
                style: {
                  fontSize: '12px',
                  padding: '8px'
                }
              }}
              style={{
                fontSize: '8px'
              }}
            />
          </Box>
        </Control>
      </Grid>
    )
  } else if (value.type === 'date' || value.type === 'object') {
    return (
      <Grid
        item
        zeroMinWidth
        key={key + '_grid_item'}
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '0px',
          flexGrow: 1,
          marginRight: '8px',
          padding: '8px 0px'
        }}
      >
        <Control
          key={key + '_grid_control'}
          style={{
            flexGrow: 1
          }}
        >
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='row'
            alignContent='center'
            alignItems='center'
            justifyContent='flex-start'
          >
            {!value.title.includes('value') && (
              <Box px={1} minWidth='120px'>
                <Typography
                  variant='body1'
                  color='initial'
                  style={{
                    padding: '0px',
                    color: 'primary',
                    fontSize: '12px'
                  }}
                >
                  {value.title}
                </Typography>
              </Box>
            )}

            <TextField
              name={key}
              id={
                'date_' +
                _.snakeCase(formSchemaCodeLab + '_' + value.title ?? value.name)
              }
              title={value.description}
              type='date'
              size='small'
              variant='outlined'
              value={fieldValue ?? getState(key) ?  moment(getState(key)).isValid() ? moment(getState(key)).format("YYYY-MM-DD") : getState(key): null}
              onChange={(event) => {
                setValue(key, event.target.value)
              }}
              onBlur={(e) => {
                e.preventDefault()
              }}
              InputProps={{inputProps: {max:moment().format('YYYY-MM-DD')} }}
              InputLabelProps={{
                style: {
                  fontSize: '12px'
                }
              }}
            />
          </Box>
        </Control>
      </Grid>
    )
  } else if (value.type === 'number') {
    return (
      <Grid
        item
        zeroMinWidth
        key={key + '_grid_item'}
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '0px',
          flexGrow: 1,
          marginRight: '8px',
          padding: '8px 0px'
        }}
      >
        <Control
          key={key + '_grid_control'}
          style={{
            flexGrow: 1
          }}
        >
          <Box
            display='flex'
            flexGrow={1}
            flexDirection='row'
            alignContent='center'
            alignItems='center'
            justifyContent='flex-start'
          >
            <TextField
              name={key}
              id={
                'number_' +
                _.snakeCase(formSchemaCodeLab + '_' + value.title ?? value.name)
              }
              type='number'
              size='small'
              variant='outlined'
              inputProps={{ inputMode: 'numeric', step: 'any' }}
              value={fieldValue ?? getState(key) ?? null}
              onChange={(event) => {
                setValue(key, event.target.value)
              }}
              InputProps={{
                style: {
                  fontSize: '12px',
                  padding: '0px',
                  paddingRight: '8px'
                }
              }}
              style={{
                fontSize: '8px'
              }}
            />
            {value.unit && value.unit.unit && (
              <Box px={1}>
                <Typography
                  variant='body2'
                  color='inherit'
                  style={{
                    fontSize: '11px',
                    color: 'GrayText'
                  }}
                >
                  {value.unit.unit}
                </Typography>
              </Box>
            )}
          </Box>
        </Control>
      </Grid>
    )
  } else if (value.type === 'group') {
    // multi-value
    return <fieldset></fieldset>
  } else if (value.type === 'forms') {
    return value.forms.map((form, formIndex) => {
      return Object.entries(form.properties).map(
        (entry: [string, object], index) => {
          const key: string = (entry[1] as SchemaProp).name
          const subValue: SchemaProp = entry[1] as SchemaProp

          return buildField(
            `${value.name}[${formIndex}].${subValue.name}`,
            subValue,
            index
          )
        }
      )
    })
  } else {
    // default text
    return (
      <Control key={index}>
        <input
          id={
            'default_' +
            _.snakeCase(formSchemaCodeLab + '_' + value.title ?? value.name)
          }
          value={value.options.map((e) => {
            return getOptionValue(e)
          })}
          disabled={true}
          name={key}
          hidden={true}
        />
      </Control>
    )
  }
}

export default buildField

function interpretPulse(
  pulseValue: number,
  operations: '=' | '<' | '>' | '!=',
  threshold: number,
  resultingFunctionToBeExecuted: Function
) {
  switch (operations) {
    case '=':
      if (pulseValue === threshold) {
        resultingFunctionToBeExecuted()
      }
      break
    case '<':
      if (pulseValue < threshold) {
        resultingFunctionToBeExecuted()
      }
      break
    case '>':
      if (pulseValue > threshold) {
        resultingFunctionToBeExecuted()
      }
      break
    case '!=':
      if (pulseValue !== threshold) {
        resultingFunctionToBeExecuted()
      }
      break
  }
}
