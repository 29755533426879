/* eslint-disable react/jsx-key */
import React from 'react'
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Paper,
  Divider,
  useTheme
} from '@material-ui/core'

interface IProps {
  height: string | number
}
const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'left',
    backgroundColor: '#999999',
    marginLeft: 10
  }
}))

export const NoAppointmentCard: React.FC<IProps> = ({ height }: IProps) => {
  const classes = useStyles()

  return (
    <Paper
      className={classes.paper}
      style={{ height: height }}
      square={true}
      variant='outlined'
    ></Paper>
  )
}
