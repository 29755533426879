/* eslint-disable react/jsx-key */
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    makeStyles,
    Theme,
    CircularProgress
  } from '@material-ui/core'
  import React, { useState } from 'react'
  import { useForm } from 'react-cool-form'
  import Requestor from '../api/requestor'
  import { convertFormOutputWithSchema } from '../utils/fieldUtils'
  import { getFormDataFromObservations } from '../utils/observationFormUtils'
  import ObservationForm from '../component/observationForm'
  import ObservationFormForDialog from './observationFormForDialog'
  import _, { pad } from 'lodash'
  
  interface Props {
    open: boolean
    formSchema: GeneratedFormProps
    onClose: () => void
  }
  export const EditFormInDialog: React.FC<Props> = ({
    formSchema,
    open,
    onClose
  }) => {
    const [formValues, setFormValues] = useState<any>(formSchema.data)
    const [displayProcessing, setDisplayProcessing] = useState<boolean>(false)
    const [isDirty, setIsDirty] = useState<boolean>(false)
  
    const { form, use, submit, getState, field, reset, setValue } = useForm({
      defaultValues: formValues,
      onStateChange: (formState) => {
        setIsDirty(formState.isDirty)
  
        setFormValues(formState.values)
      },
  
      onSubmit: (values) => {
        setDisplayProcessing(true)
        console.log('onSubmit: upper', values)
        const convertedValue = convertFormOutputWithSchema(
          values,
          formSchema.schema
        )
        console.log(
          '--------onSubmit: convertedValue--------------',
          convertedValue
        )
        Requestor.mapFormDataToMappingOverlay(
          formSchema.OCAFormProviderURL + '/' + 'mapFormOutput',
          formSchema.OCAFormProviderHeader,
          convertedValue
        )
          .then((schema) => {
            formSchema.onEdit(
              schema,
              (data) => {
                if (data['id'] !== undefined && data['id'] !== null) {
                  setFormValues({ ...formValues, id: data['id'] })
                }
  
                setIsDirty(false)
              },
              (data: any) => {
                console.log('--Error--- ', 'error')
              }
            )
          })
          .catch((e) => {
            console.log(`Error: ${e.message}`)
            setDisplayProcessing(false)
          })
      },
      onReset: (values, options, e) => {
        setFormValues(getFormDataFromObservations([], formSchema.schema))
      },
      onError: (errors) => console.log('onError: ', errors)
    })
    return (
      <Dialog
        open={open}
        onClose={() => {
          onClose()
        }}
      >
        <DialogTitle
          id='scroll-dialog-title'
          style={{ backgroundColor: 'primary' }}
        >
          {(formSchema.schema.formNameDisplay ?? '').trim()}
        </DialogTitle>
        <DialogContent
          dividers={true}
          style={{
            margin: '0px',
            padding: '0px'
          }}
        >
          {formSchema.schema !== null && formSchema.schema !== undefined ? (
            <form ref={form}>
              <ObservationFormForDialog
                schema={formSchema.schema}
                formValues={formValues}
                displayDelete={
                  formSchema.data !== undefined &&
                  formSchema.data !== null &&
                  getState('id') !== undefined &&
                  getState('id') !== null
                }
                onDelete={(onSuccess, onError) => {
                  formSchema.onDelete(
                    formSchema.data['id'],
                    (data: any) => {
                      console.log('-----------sucess--------------', data)
  
                      reset(getFormDataFromObservations([], formSchema.schema))
                    },
                    (data: any) => {
                      onError(data)
                    }
                  )
                }}
                getState={getState}
                setValue={setValue}
              />
            </form>
          ) : (
            <Typography component='p' style={{ color: 'red' }} className='error'>
              Schema is missing!
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Box display='flex' flexDirection='row'>
            {displayProcessing && (
              <Box
                px={1}
                display='flex'
                alignContent='center'
                justifyContent='center'
                justifyItems='center'
                alignItems='center'
              >
                <CircularProgress color='primary' size='20px' />
              </Box>
            )}
            <Box px={2}>
              <Button
                onClick={() => {
                  onClose()
                }}
                style={{
                  backgroundColor: '#ffffff'
                }}
                id={
                  'btn_cancel_' + _.snakeCase(formSchema.schema.formNameDisplay)
                }
                variant='outlined'
                disableElevation
                color='primary'
                disabled={displayProcessing}
              >
                Cancel
              </Button>
            </Box>
            <Button
              onClick={() => {
                submit()
              }}
              id={'btn_add_' + _.snakeCase(formSchema.schema.formNameDisplay)}
              variant='contained'
              color='primary'
              disableElevation
              disabled={ displayProcessing}
            >
              Update
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    )
  }
  