import { createTheme } from '@material-ui/core/styles'
export const kPrimaryMain: string = '#4543d9'
export const textMain: string = '#007AFF'
export const kPrimaryLight: string = '#4643D93A'
export const kPrimaryDark: string = '#4543d9'
export const kContrastText: string = '#ffffff'
export const kBackgroundPaper: string = '#ffffff'
export const kBackgroundDefault: string = '#dcdcdc'
export const kDialogueBackground: string = '#ededed'
export const kSelectedTabBackground: string = '#3c3c3c'
export const kBackGround: string = '#ff3b30'
export const kPrimaryErrorMain: string = '#f44336'
export const settingsBackgroundColor: string = '#e7e7e7'

const kErrorMain: string = '#f44336'
const kErrorLight: string = '#e57373'
const kErrorDark: string = '#d32f2f'
const kErrorText: string = '#fff'

const kSecondaryMain: string = '#f50057'
const kSecondaryLight: string = '#ff4081'
const kSecondaryDark: string = '#c51162'
const kSecondaryText: string = '#fff'

export const theme = createTheme({
  palette: {
    action: {
      selected: '#B2B2D1',
      hover: '#B3BCE7'
    },

    common: {
      black: '#000',
      white: '#fff'
    },
    background: {
      paper: kBackgroundPaper,
      default: kBackgroundDefault
    },
    primary: {
      light: kPrimaryLight,
      main: kPrimaryMain,
      dark: kPrimaryDark,
      contrastText: kContrastText
    },
    secondary: {
      light: kSecondaryLight,
      main: kSecondaryMain,
      dark: kSecondaryDark,
      contrastText: kSecondaryText
    },
    error: {
      light: kErrorLight,
      main: kErrorMain,
      dark: kErrorDark,
      contrastText: kErrorText
    },
    text: {
      primary: '#4d4d4d',
      secondary: 'rgba(155, 155, 155, 1)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    }
  },

  typography: {
    fontFamily: ['Open Sans', 'Oswald'].join(','),
    allVariants: {
      fontFamily: ['Open Sans', 'Oswald'].join(',')
    },
    h1: {
      fontFamily: 'Open Sans',
      fontWeight: 'lighter',
      fontSize: 96
    },

    h2: {
      fontFamily: 'Open Sans',
      fontSize: 60,

      lineHeight: 'normal',
      fontWeight: 'bold'
    },

    h3: {
      fontFamily: 'Open Sans',
      fontSize: 48,

      fontWeight: 'bold'
    },

    h4: {
      fontFamily: 'Open Sans',
      fontSize: 34,

      lineHeight: 'normal',
      fontWeight: 'bold'
    },

    h5: {
      fontFamily: 'Open Sans',
      fontSize: 24,
      fontWeight: 'bold',

      lineHeight: 'normal'
    },
    h6: {
      fontFamily: 'Open Sans',
      fontSize: 16,
      fontWeight: 600,

      lineHeight: 'normal',
      color: '#333333'
    },
    subtitle1: {
      fontFamily: 'Open Sans',
      fontSize: 14,

      fontWeight: 600,
      lineHeight: 'normal'
    },
    subtitle2: {
      fontFamily: 'Open Sans',
      fontSize: 12,

      fontWeight: 600,
      lineHeight: 'normal'
    },
    body1: {
      fontFamily: 'Open Sans',
      fontSize: 14,

      color: '#4d4d4d',
      lineHeight: 'normal'
    },
    body2: {
      fontFamily: 'Open Sans',
      fontSize: 12,

      lineHeight: 'normal'
    },
    button: {
      fontFamily: 'Open Sans',
      fontSize: 14,
      lineHeight: 'normal',
      fontWeight: 600,
      textTransform: 'none',
      fontStyle: 'normal'
    },
    caption: {
      fontFamily: 'Open Sans',
      fontSize: 12,

      lineHeight: 'normal'
    },
    overline: {
      fontFamily: 'Open Sans',
      fontSize: 10,
      fontWeight: 600,
      lineHeight: 'normal'
    }
  },
  props: {
    MuiDialogTitle: {
      disableTypography: true,
      style: {
        backgroundColor: kPrimaryMain,
        color: 'white',
        alignContent: 'center',
        textAlign: 'center',
        height: 48,
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 'bold'
      }
    },
    MuiTab: {
      style: { minWidth: 0 }
    },
    MuiListItemIcon: {
      style: {
        minWidth: 0
      }
    }
  },
  overrides: {
    MuiIcon: {},

    MuiRadio: {
      root: {
        '& .MuiSvgIcon-root': {
          height: 15,
          width: 15
        }
      },
      colorSecondary: {
        '&$checked': {
          color: kPrimaryMain
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: 'white'
      },
      input: {
        backgroundColor: 'white'
      },
      adornedEnd: {
        padding: 0,
        paddingLeft: 0,
        paddingRight: 10
      }
    },
    MuiInputBase: {
      input: {
        backgroundColor: 'white'
      }
    },
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: 'red'
        },
        '&$active': {
          color: 'green'
        },
        '&$inactive': {
          color: 'grey'
        }
      },
      active: {},
      completed: {}
    },

    MuiButton: {
      root: {
        margin: '2px'
      },
      outlined: {
        color: kPrimaryMain,
        backgroundColor: 'white'
      }
    },

    MuiDialogTitle: {
      root: {
        backgroundColor: kPrimaryMain,
        color: 'white',
        alignContent: 'center',
        textAlign: 'center',
        height: 48,
        fontFamily: 'Open Sans',
        fontSize: 16,
        fontWeight: 'bold'
      }
    },
    MuiDialogContent: {
      root: {
        backgroundColor: kDialogueBackground,
        padding: 0,
        overflow: 'hidden',
        margin: 0
      }
    },
    MuiDialogActions: {
      root: {
        backgroundColor: kDialogueBackground
      }
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#ededed'
      }
    },

    MuiCssBaseline: {
      '@global': {
        root: {
          '.MuiAutocomplete-option': {
            '&$selected': {
              backgroundColor: kPrimaryMain,
              color: 'white',
              '&:hover': {
                backgroundColor: kPrimaryMain,
                color: 'white'
              }
            }
          }
        },
        /* ".MuiAutocomplete-option": {
            "&$selected": {
              backgroundColor: kPrimaryMain,
              color: "white",
              "&:hover": {
                backgroundColor: kPrimaryMain,
                color: "white",
              },
            },
          }, */
        '.MuiAlert-outlinedInfo': {
          backgroundColor: 'white'
        }
      }
    },
    MuiButtonGroup: {},
    MuiSelect: {
      root: {
        '&$selected': {
          backgroundColor: kPrimaryMain,
          color: 'white',
          '&:hover': {
            backgroundColor: kPrimaryMain,
            color: 'white'
          }
        },
        '&$paper': {
          top: 200
        }
      }
    },
    // MuiCheckbox: {
    //   root: {
    //     '&$checked': {
    //       color: ` ${kPrimaryMain} !important`,
    //       '&:hover': {
    //         backgroundColor: kPrimaryMain,
    //         color: 'white',
    //       },
    //       '&:colorSecondary': {
    //         backgroundColor: kPrimaryMain,
    //         color: 'white',
    //       },
    //     },
    //     colorSecondary: {
    //       color: 'blue',
    //     },
    //     // checked: {
    //     //   color: 'kPrimaryMain',
    //     // },
    //   },
    // },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: kPrimaryMain,
          color: 'white',
          '&:hover': {
            backgroundColor: kPrimaryMain,
            color: 'white'
          }
        }
      }
    }
  }
})
