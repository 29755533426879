import React, { useState } from 'react'
import {
  Grid,
  Divider,
  Paper,
  makeStyles,
  Box,
  Typography,
  useTheme
} from '@material-ui/core'
import { Controls } from '../../PrmitiveComponent/component/Controls'
import { LeftFilterOptions } from '../Subcomponent/LeftFilterOptions'
import { MultiSelectWithSearch } from '../../PrmitiveComponent/component/MultiSelectWithFilter'
import moment from 'moment'
import { DoctorListview } from '../Subcomponent/DoctorListview'
import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'

export interface IUnitAdmin {
  children?: React.ReactNode
  count: number
}

export const dateOptionsList = [
  { id: `${moment()}`, title: 'Today' },
  { id: `${moment(moment(), 'DD-MM-YYYY').add(1, 'days')}`, title: 'Tomorrow' },
  {
    id: 'other',
    title: 'Other'
  }
]

export const availableAppointmentTypes = [
  { id: 'In-Person', title: 'In-Person' },
  { id: 'telephone', title: 'Telephone' },
  { id: 'video_call', title: 'Video Call' }
]

export const WelloDoctorAvailability: React.FC<IUnitAdmin> = ({}: IUnitAdmin) => {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [showDatePicker, showSetDatePicker] = useState(false)
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box borderRadius={8}>
        <Paper>
          <Grid
            container
            justify='space-evenly'
            alignItems='stretch'
            direction='column'
          >
            <Grid item xs={12} alignContent='center'>
              <Box
                bgcolor={useTheme().palette.primary.main}
                style={{
                  borderTopLeftRadius: '4px',
                  borderTopRightRadius: '4px'
                }}
                padding={1}
              >
                <Typography
                  align='center'
                  style={{
                    color: useTheme().palette.common.white
                  }}
                >
                  New Patient {'>'} Check Availability
                </Typography>
              </Box>
            </Grid>
            <Grid item style={{ padding: 8 }}>
              <Grid
                container
                spacing={1}
                direction='row'
                justify='space-around'
                alignItems='center'
              >
                <Grid item xs={3}>
                  <Grid
                    container
                    justify='center'
                    spacing={1}
                    direction='column'
                  >
                    <Grid item>
                      <Controls.WelloSelect
                        label='DATE'
                        availableOptions={dateOptionsList}
                        required={true}
                        type='Date'
                        displayField='title'
                        valueField='id'
                        preSelectedOption={dateOptionsList[0]}
                        onSelectionChanged={(value) => {
                          console.log(value)
                          if (value['id'] === 'other') {
                            showSetDatePicker(true)
                          }
                        }}
                      />
                    </Grid>

                    <Grid item>
                      <Controls.WelloSelect
                        label='APPOINTMENT TYPE'
                        availableOptions={availableAppointmentTypes}
                        required={true}
                        type='Appointment Type'
                        displayField='title'
                        valueField='id'
                        preSelectedOption={availableAppointmentTypes[0]}
                        onSelectionChanged={(value) => {
                          console.log(value)
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <MultiSelectWithSearch
                        title='Doctors'
                        displayField='name'
                        valueField='id'
                        listHeight={250}
                        availableOptions={[
                          { id: 1, name: 'Raman' },
                          { id: 2, name: 'Raman3' }
                        ]}
                        preSelectedOptions={[{ id: 2, name: 'Raman3' }]}
                        onValuesChanged={(changedValues: any[]) => {
                          console.log('Selected doctors list')
                          console.log(changedValues)
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <MultiSelectWithSearch
                        title='Specialties'
                        availableOptions={['Height', 'Weight', 'Dumb']}
                        preSelectedOptions={['Weight']}
                        listHeight={250}
                        onOptionsClicked={() => {
                          console.log('Options clicked')
                        }}
                        onValuesChanged={(changedValues: any[]) => {
                          console.log(changedValues)
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={9}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <DatePicker
          hidden={true}
          style={{ visibility: 'hidden' }}
          value={selectedDate}
          open={showDatePicker}
          onChange={(val) => {
            console.log(val)
            showSetDatePicker(false)
          }}
        />
      </Box>
    </MuiPickersUtilsProvider>
  )
}
