import { Box, Divider, Grid, Typography,makeStyles } from '@material-ui/core'
import _ from 'lodash'
import React from 'react'
import {
  getFlattenFormValuesOfObservation,
  rowSeparatedData
} from '../utils/observationFormUtils'
import { NoteDialog, NotesProps } from './noteDialog'
import OverflowTypography from './overflowTypography'

interface ObservationFormProps {
  namePrefix?: string
  schema: Schema
  formValues?: any
  name?: string
  index?: number
  singleLineDisplay?: boolean
  onDelete?: (
    onSuccess: (data: any) => void,
    onError: (data: any) => void
  ) => void

  displayDelete?: boolean
}

const useStyles = makeStyles({
  Grid: {
    '& .MuiGrid-container': {
    flexWrap:'nowrap'
    },
  },
})

export const ObservationViewForSubjectiveTemplate: React.FC<ObservationFormProps> =
  (props: ObservationFormProps) => {
    const results = rowSeparatedData(
      getFlattenFormValuesOfObservation(props.schema, props.formValues),
      2
    )

    const [showTextNotes, setShowTextNotes] = React.useState<NotesProps>({
      id: 'dialog_item.code',
      open: false,
      onClose: () => {
        setShowTextNotes({
          ...showTextNotes,
          open: false
        })
      },
      notesData: ''
    })
    const classes = useStyles()

    return (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='flex-start'
        width='100%'
        border={4}
        style={{
          boxShadow: '0px 0px 4px #ccc',
          margin: '0px 0px',
          borderRadius: '1px',
          borderColor: '#F8F8F8',
          height: 'auto',
          backgroundColor: '#F8F8F8'
        }}
      >
        <Grid container direction='column' spacing={1} wrap='nowrap' >
          {results.map((result: any, index: number) => {
            return (
              <Grid
                container
                className={classes.Grid}
                direction='row'
                style={{
                  backgroundColor: index % 2 == 0 ? 'white' : '#F8F8F8',
                  borderBottom: '1px solid #ccc',
                  flexWrap:'nowrap'
                }}
                 wrap='nowrap'
              >
                {result.map((item: any, index: number) => {
                  return (
                    <Grid
                      item
                      xs={6}
                     
                      style={{
                        borderLeft: index % 2 == 1 ? '1px solid #ccc' : 'none'
                      }}
                    >
                      <Grid container direction='row'
                          className={classes.Grid}
                    >
                        <Grid item xs={6} >
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                         
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              width='100%'
                             
                            >
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                flexGrow
                                height={30}
                                alignItems='center'
                                px={1}
                              >
                                <Typography
                                  color='initial'
                                  variant='subtitle1'
                                  id={
                                    'txt_code_form_' +
                                    _.snakeCase(
                                      props.name ?? props.schema.title
                                    ) +
                                    '_item_' +
                                    _.snakeCase(item.code)
                                  }
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,

                                    fontWeight: 'bolder',
                                    lineHeight: 'normal'
                                  }}
                                >
                                  {item.code}
                                </Typography>
                              </Box>
                            </Box>
                            <Box display='flex' width='2%' paddingTop={0.1}>
                              <Divider
                                orientation='vertical'
                                flexItem
                                style={{
                                  height: '30px',
                                  width: '2px'
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                        
                              <Box
                                justifyContent='flex-start'
                                display='flex'
                                height={30}
                                alignItems='center'
                                px={1}
                                 width='100%'
                                 flexWrap='noWrap'
                              >
                                <OverflowTypography
                                  text={item.value ?? ''}
                                  id={
                                    'txt_val_form_' +
                                    _.snakeCase(
                                      props.name ?? props.schema.title
                                    ) +
                                    '_item_' +
                                    _.snakeCase(item.code)
                                  }
                                  typographyProps={{
                                    variant: 'subtitle2',
                                    color: 'initial'
                                  }}
                                />

                                {/* <Typography
                                  color='initial'
                                  id={
                                    'txt_val_form_' +
                                    _.snakeCase(
                                      props.name ?? props.schema.title
                                    ) +
                                    '_item_' +
                                    _.snakeCase(item.code)
                                  }
                                  style={{
                                    fontFamily: 'Open Sans',
                                    fontSize: 12,

                                    lineHeight: 'normal'
                                  }}
                                  onClick={() => {
                                    if (item.value.length > 30) {
                                      setShowTextNotes({
                                        ...showTextNotes,
                                        open: true,
                                        notesData: item.value,
                                        dialogText: item.code
                                      })
                                    }
                                  }}
                                >
                                  {(item.value ?? '').toString().slice(0, 30) +
                                    ((item.value ?? '').length > 30
                                      ? '...'
                                      : '')}
                                </Typography> */}
                             
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            )
          })}
        </Grid>
        <NoteDialog {...showTextNotes} />
      </Box>
    )
  }

export default ObservationViewForSubjectiveTemplate
