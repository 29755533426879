import React, { useState } from 'react'
import {
  makeStyles,
  Paper,
  Grid,
  Typography,
  Box,
  Divider
} from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { Controls } from '../../../../components/PrmitiveComponent/component/Controls'
import CloseIcon from '@material-ui/icons/Close'

export interface IUnitAdmin {
  children?: React.ReactNode
  count: number
  backButtonText?: string
  buttonText?: string
  onClickContinue: () => void
  onClickBack: () => void
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={0} variant='outlined' {...props} />
}

export const getType = () => [
  { id: 'Lab', title: 'Lab' },
  { id: 'Hospital', title: 'Hospital' }
]
export const getContactInfo = () => [{ id: 'Main', title: 'Main' }]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },

  mainPage: {
    maxWidth: 960,
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    overflow: 'Auto'
  },

  topHeader: {
    fontSize: 16,
    display: 'inline'
  },
  buttonStyle: {
    fontSize: 14,
    display: 'inline'
  },
  box: {
    marginBottom: 10,
    display: 'inline-block'
  },
  ml10: {
    marginLeft: 10
  },

  distance: {
    marginTop: 10
  },

  footer: {
    marginTop: 33.5
  },

  addMore: {
    backgroundColor: theme.palette.background.paper
  },

  specialOutline: {
    borderColor: 'grey',
    borderWidth: 1,
    maxWidth: 470
  }
}))

const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
  if (reason === 'clickaway') {
    /* return */
  }
}

export const AddUnit: React.FC<IUnitAdmin> = ({
  children,
  count,
  backButtonText,
  buttonText,
  onClickContinue,
  onClickBack
}: IUnitAdmin) => {
  const classes = useStyles()
  const [contactList, setContactList] = useState([
    { unitType: '', name: '', contactInfo: '' }
  ])

  const handleInputChange = (e: any, index: any) => {
    const { name, value } = e.target
    const list = [...contactList]
    list[index][name] = value
    setContactList(list)
  }

  const handleRemoveClick = (index: any) => {
    const list = [...contactList]
    list.splice(index, 1)
    setContactList(list)
  }

  const handleAddClick = () => {
    setContactList([
      ...contactList,
      { unitType: '', name: '', contactInfo: '' }
    ])
  }

  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item xs={12}>
        <Grid container justify='center' spacing={0}>
          <Grid key={1} item>
            <Paper className={classes.mainPage}>
              {children}
              <Grid container className={classes.root} spacing={0}>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    {contactList.map((x, i) => {
                      return (
                        <Grid key={i} item>
                          <br></br>

                          <div className={classes.box}>
                            {/*  <Controls.WelloSelect
                              name='unitType'
                              label='UNIT TYPE'
                              availableOptions={getType()}
                              required={true}
                              type='Unit Type'
                              value={x.unitType}
                              onSelectionChanged={(e: any) =>
                                handleInputChange(e, i)
                              }
                              width={160}
                            /> */}
                            &nbsp;&nbsp;
                            <Controls.WelloInput
                              name='name'
                              label='NAME'
                              placeholder='Name'
                              type='text'
                              required={true}
                              value={x.name}
                              onChange={(e: any) => handleInputChange(e, i)}
                              width={160}
                              charsAllowed={30}
                            />
                            &nbsp;&nbsp;
                            {/*  <Controls.WelloSelect
                              name='contactInfo'
                              label='CONTACT INFORMATION'
                              type='Contact Information'
                              availableOptions={getContactInfo()}
                              required={true}
                              value={x.contactInfo}
                              onSelectionChanged={(e: any) =>
                                handleInputChange(e, i)
                              }
                              width={170}
                            /> */}
                            &nbsp;
                            {contactList.length !== 1 && (
                              <React.Fragment>
                                <Controls.WelloActionButton
                                  color='secondary'
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  <CloseIcon />
                                </Controls.WelloActionButton>
                                <br></br>
                              </React.Fragment>
                            )}
                            <div className={classes.ml10}>
                              {contactList.length - 1 === i && (
                                <div
                                  className={classes.addMore}
                                  onClick={handleAddClick}
                                >
                                  <Box alignItems='center' display='inline'>
                                    <Box>
                                      <Typography
                                        variant='h4'
                                        className={classes.addMore}
                                      >
                                        + Add More &nbsp; &nbsp; &nbsp; &nbsp;{' '}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      {' '}
                                      <Divider />{' '}
                                    </Box>
                                  </Box>
                                </div>
                              )}
                            </div>
                          </div>
                        </Grid>
                      )
                    })}
                    <div className={classes.distance}></div>
                    <Alert
                      className={classes.specialOutline}
                      variant='outlined'
                      severity='info'
                      onClose={handleClose}
                    >
                      Please add all the departments or units of your
                      organization here. The unit types included in your package
                      are available to you. You can add more units later as
                      well.
                    </Alert>
                  </Grid>
                </Grid>
              </Grid>
              <div className={classes.footer}></div>
              <Divider />
              <div className={classes.distance}></div>
              <Grid container justify='flex-end'>
                <div className={classes.buttonStyle}>
                  <Controls.WelloButton
                    type='button'
                    text={backButtonText ?? 'Back'}
                    size='small'
                    backgroundColor='#ffffff'
                    color='#4543d9'
                    onClick={onClickBack}
                  />
                  <Controls.WelloButton
                    type='submit'
                    text={buttonText ?? 'Continue'}
                    size='small'
                    backgroundColor='#4543d9'
                    color='#ffffff'
                    onClick={onClickContinue}
                  />
                </div>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
