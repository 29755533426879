import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'

export interface TitleProps {
  SearchLabel: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'none'
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  display: {
    backgroundColor: '#dcdcdc'
  },
  pageStyle: {
    padding: theme.spacing(2),
    color: theme.palette.background.default
  },
  paper: {
    display: 'flex',
    padding: theme.spacing(3),
    backgroundColor: '#ececec',
    height: 580,
    width: '103%',
    overflow: 'none'
  },

  topPaper: {
    display: 'inline',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    margin: theme.spacing(1),
    width: theme.spacing(23),
    height: theme.spacing(4)
  },

  search: {
    position: 'relative',
    borderRadius: 6,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white'
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    borderRadius: '1',
    [theme.breakpoints.up('sm')]: {
      width: '14ch',
      '&:focus': {
        width: '14ch'
      }
    }
  }
}))

export const Search: React.FC<TitleProps> = ({ SearchLabel }: TitleProps) => {
  const classes = useStyles()
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon} style={{ color: '#5f6061' }}>
        <SearchIcon />
      </div>
      <InputBase
        style={{ color: '#5f6061' }}
        placeholder={'Search ' + SearchLabel}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput
        }}
        inputProps={{ 'aria-label': 'Search Patients' }}
      />
    </div>
  )
}
