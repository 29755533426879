/* eslint-disable react/jsx-key */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { SettingMenu } from './SettingMenu'

interface TabProps {
  preSelectedTab?: string
  onTabChange: (selectedTab: string) => void
  menu: string[]
}

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 2
  },
  display: {
    padding: theme.spacing(0)
  }
}))

export const SettingsMenus: React.FC<TabProps> = ({
  preSelectedTab,
  onTabChange,
  menu
}: TabProps) => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = React.useState(preSelectedTab)
  const handleChange = (selectedTab: string) => {
    onTabChange(selectedTab)
    setSelectedTab(selectedTab)
  }

  return (
    <div className={classes.title}>
      <Grid container direction='column'>
        {menu.map((value, i) => (
          <Grid item>
            <SettingMenu
              textTitle={value}
              isSelected={selectedTab === value}
              onClick={() => handleChange(value)}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
