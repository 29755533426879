/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import {
  Grid,
  Divider,
  Paper,
  Typography,
  makeStyles,
  Avatar
} from '@material-ui/core'
import { Controls } from '../../../../components/PrmitiveComponent/component/Controls'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import PublishIcon from '@material-ui/icons/Publish'
import Geocode from 'react-geocode'
Geocode.setApiKey('AIzaSyBub-Ru3cd4vj1TxkcC4anEEftL7JMXAiw')
Geocode.setLanguage('en')
Geocode.setRegion('in')
Geocode.enableDebug()

export interface IUnitAdmin {
  children?: React.ReactNode
  count: number
}

export const GenderList = () => [
  { id: 'male', title: 'Male' },
  { id: 'female', title: 'Female' },
  { id: 'other', title: 'Other' },
  { id: 'unknown', title: 'Unknown' }
]

export const AddressType = () => [
  { id: 'home', title: 'Home' },
  { id: 'office', title: 'Ofiice' }
]

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },

  mainPage: {
    width: 960,
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    overflow: 'Auto'
  },

  buttonStyle: {
    marginLeft: 650,
    fontSize: 14,
    fontWeight: 600,
    display: 'inline',
    marginTop: 60
  },

  distance: {
    marginTop: 10
  },

  footer: {
    marginTop: 20
  },

  cameraContianer: {
    color: '#333333',
    fontSize: 10,
    marginLeft: 275
  },
  contactInfoHeader: {
    marginTop: 20,
    color: '#4d4d4d',
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 260
  },
  large: {
    width: 60,
    height: 60
  },
  ageLabel: {
    height: 39,
    width: 59,
    borderRadius: 6,
    backgroundColor: '#ffffff',
    marginTop: 16,
    textAlign: 'center'
  },
  overlappimg: {
    height: 20,
    display: 'inline-flex',
    align: 'center',
    marginLeft: 5,
    marginTop: 14,
    fontSize: 12,
    fontWeight: 600
  }
}))

export const ExistingCustomer: React.FC<IUnitAdmin> = ({
  children,
  count
}: IUnitAdmin) => {
  const classes = useStyles()
  const [gender, setGender] = useState('male')
  const [addressType, setAddressType] = useState('home')
  const [phone, setPhone] = useState('8147910531')
  const [latLong, setLatLong] = useState({ lat: 26.08, lng: 80.0004 })
  const [isMarker, setMarker] = useState(true)
  const [fileValue, setFileValue] = useState('Driver License.jpg')

  const handleMarkerClick = () => {
    setMarker(true)
  }

  const handleAddressChange = (e: { target: { name: any; value: any } }) => {
    const { value } = e.target
    Geocode.fromAddress(value).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location
        console.log(lat, lng)
        latLong.lat = lat
        latLong.lng = lng
        setLatLong(latLong)
      },
      (error) => {
        console.error(error)
      }
    )
  }

  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item xs={12}>
        <Grid container justify='center' spacing={0}>
          <Grid key={1} item>
            <Paper className={classes.mainPage}>
              {children}
              <div className={classes.distance}></div>
              <div className={classes.distance}></div>
              <br></br>
              <Grid container className={classes.root} spacing={0}>
                <Grid item xs={12}>
                  <Grid container justify='center'>
                    {/* &nbsp; &nbsp;&nbsp; */}
                    {/* <Avatar
                      alt='Remy Sharp'
                      src='pankaj.jpg'
                      className={classes.large}
                    /> */}
                    &nbsp; &nbsp;
                    <Controls.WelloInput
                      name='name'
                      required={true}
                      label='NAME'
                      type='text'
                      placeholder='Name'
                      value='Kanti Shah'
                      width={280}
                      charsAllowed={30}
                    />
                    &nbsp; &nbsp;
                    <Paper className={classes.ageLabel}>
                      {gender === 'male' && (
                        <Typography
                          color='primary'
                          className={classes.overlappimg}
                        >
                          <img src='m.png' alt='' height={15.3} width={5.33} />{' '}
                          &nbsp;{25}y
                        </Typography>
                      )}

                      {gender === 'female' && (
                        <Typography
                          color='primary'
                          className={classes.overlappimg}
                        >
                          <img src='f.png' alt='' height={15.3} width={5.33} />{' '}
                          &nbsp;{25}y
                        </Typography>
                      )}
                    </Paper>
                    &nbsp; &nbsp;
                  </Grid>
                </Grid>

                <br></br>
                <Grid item xs={12}>
                  <div className={classes.distance}></div>
                  <Grid container justify='center'>
                    <Controls.PhoneNumber
                      name='phone'
                      label='PHONE NUMBER'
                      placeholder='Phone Number'
                      type='number'
                      required={true}
                      width={200}
                      value={phone}
                    />
                    &nbsp; &nbsp;
                    <Controls.WelloEmailInput
                      name='email'
                      label='EMAIL ADDRESS'
                      placeholder='Email Address'
                      type='email'
                      required={true}
                      width={200}
                      value='kantishah@gmail.com'
                    />
                  </Grid>
                </Grid>

                <br></br>
                <Grid item xs={12}>
                  <div className={classes.distance}></div>
                  <Grid container justify='center'>
                    <Controls.WelloDate
                      name='dob'
                      label='DATE OF BIRTH'
                      placeholder='dd/mm/yyyy'
                      required={true}
                      width={200}
                      value='1995-12-12'
                    />
                    &nbsp; &nbsp;
                    {/*  <Controls.WelloSelect
                      name='gender'
                      label='GENDER'
                      availableOptions={GenderList()}
                      required={true}
                      type='Gender'
                      value={gender}
                      width={200}
                    /> */}
                  </Grid>
                  <div className={classes.distance}></div>
                  <Grid container justify='center'>
                    {/* <div className={classes.distance}></div> */}
                    <Controls.WelloInput
                      name='address'
                      required={true}
                      label='YOUR CURRENT HOME ADDRESS'
                      type='text'
                      placeholder='Your Home Address'
                      width={320}
                      value='Flat No-304,Sobha Dreams ,Gottigere 560083'
                      onChange={handleAddressChange}
                      charsAllowed={100}
                    />
                    &nbsp; &nbsp;
                    {/*  <Controls.WelloSelect
                      name='addressType'
                      label='TYPE'
                      availableOptions={AddressType()}
                      required={true}
                      type='Type'
                      value={addressType}
                    /> */}
                  </Grid>

                  <Grid container justify='center'>
                    <div className={classes.distance}></div>

                    {/*  <Controls.WelloMapPage
                      isMarkerShown={isMarker}
                      onMarkerClick={handleMarkerClick}
                      currentLocation={latLong}
                    /> */}
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <div className={classes.distance}></div>
                  <Grid container>
                    <div
                      style={{
                        marginLeft: 265,
                        marginTop: 10,
                        display: 'inline-flex'
                      }}
                    >
                      <Paper
                        style={{
                          backgroundColor: '#999999',
                          width: 350,
                          height: 43
                        }}
                      >
                        <Controls.WelloInputFile
                          style={{ display: 'none' }}
                          name='idProff'
                          label='IDENTITY PROOF'
                          icon={<PublishIcon />}
                          required={true}
                          type='image/x-png,image/gif,image/jpeg'
                          fileType={fileValue}
                        />
                      </Paper>

                      <div style={{ marginLeft: 20 }}>
                        <Paper
                          style={{ color: '#ff3b30', width: 46, height: 38 }}
                        >
                          <DeleteOutlineIcon
                            style={{
                              display: 'block',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              paddingTop: 7
                            }}
                          />
                        </Paper>
                      </div>
                    </div>
                    <Typography className={classes.cameraContianer}>
                      Must show your Photo, Name, Date of Birth and Current Home
                      Address. Such as Driver’s License, Ration Card etc.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <div className={classes.footer}></div>
              <Divider />
              <div className={classes.distance}></div>

              <div className={classes.buttonStyle}>
                <Controls.WelloButton
                  type='button'
                  text='Cancel (esc)'
                  size='small'
                  variant='outlined'
                  backgroundColor='#f8ecec'
                  color='#4543d9'
                />
                <Controls.WelloButton
                  type='button'
                  text='Add Customer (Alt+S)'
                  size='small'
                  variant='outlined'
                  backgroundColor='#4543d9'
                  color='#cdcdcd'
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ExistingCustomer
