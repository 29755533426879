import React from 'react'
import { Box, Grid, makeStyles, useTheme } from '@material-ui/core'
import grid from '../../../assets/images/grid.png'
import tableDisabled from '../../../assets/images/table_disabled.png'
import cards from '../../../assets/images/cards.png'
import cardDisabled from '../../../assets/images/carddisabled.png'
interface IProps {
  isSelectd?: boolean
  textTitle?: string
  onClick?: () => void
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: '#dcdcdc'
  },

  activePaper: {
    // padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.primary.main
    // backgroundColor: "#dcdcdc",
  },

  paperNotification: {
    padding: theme.spacing(0),
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: 35
  },

  disablePaperNotification: {
    padding: theme.spacing(0),
    textAlign: 'center',
    // bgcolor:"text.hint",
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.white,
    width: 35
  },
  display: {
    padding: theme.spacing(0),
    marginTop: 10,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.white
  },

  indicator: {
    padding: theme.spacing(0),
    backgroundColor: theme.palette.primary.main,
    maxHeight: 3,
    width: 25,
    height: 2,
    color: theme.palette.common.white
  },

  cardImg: {
    height: 23,
    width: 25,
    color: theme.palette.common.white
  }
}))

export const DisplayTab: React.FC<IProps> = ({
  isSelectd = true,
  textTitle = 'text',
  onClick
}: IProps) => {
  const classes = useStyles()

  return (
    <div onClick={onClick}>
      <Box style={{ color: useTheme().palette.primary.main }}>
        <Grid
          container
          direction='column'
          justify='center'
          alignItems='center'
          spacing={1}
        >
          <Grid
            container
            direction='row'
            justify='center'
            alignItems='center'
            spacing={1}
          >
            {textTitle === 'Grid' && isSelectd === true && (
              <Grid item spacing={1}>
                {' '}
                <img alt='calender' src={grid} className={classes.cardImg} />
              </Grid>
            )}

            {textTitle === 'Grid' && isSelectd === false && (
              <Grid item spacing={1}>
                {' '}
                <img
                  alt='calender'
                  src={tableDisabled}
                  className={classes.cardImg}
                />
              </Grid>
            )}

            {textTitle === 'Cards' && isSelectd === true && (
              <Grid item spacing={1}>
                {' '}
                <img alt='calender' src={cards} className={classes.cardImg} />
              </Grid>
            )}

            {textTitle === 'Cards' && isSelectd === false && (
              <Grid item spacing={1}>
                {' '}
                <img
                  alt='calender'
                  src={cardDisabled}
                  // src='card_disabled.png'
                  className={classes.cardImg}
                />
              </Grid>
            )}
            <br></br>
            <br></br>
          </Grid>
          <Grid container direction='row' justify='center' alignItems='center'>
            <Grid item>
              {isSelectd && (
                <div className={classes.activePaper}>{textTitle}</div>
              )}

              {isSelectd === false && <Box paddingTop={3} />}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}
