import React from 'react'
import { TextField, FormControl } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import dotIcon from '../../../assets/images/dot_icon.png'

export interface IProps {
  name: string
  label?: string
  placeholder?: string
  value?: any
  error?: null | undefined
  onChange?: any
  required: boolean
  size?: 'small' | 'medium' | 'large'
  [other: string]: any
  width?: number
  min?: any
  max?: any
}

export const WelloTimePicker: React.FC<IProps> = ({
  name,
  label,
  value,
  error,
  onChange,
  placeholder,
  size,
  width,
  other,
  required = false,
  min,
  max
}: IProps) => {
  return (
    <FormControl
      {...(error && { error: true })}
      // className={classes.select}
    >
      <Typography
        style={{
          color: useTheme().palette.text.primary,
          textTransform: 'uppercase'
        }}
        variant='h4'
      >
        &nbsp;&nbsp;{label} &nbsp;
        {value !== undefined && value.length > 0 && (
          <img src={dotIcon} alt='icon' height='10'></img>
        )}
      </Typography>
      <TextField
        underlineShow={false}
        id={name}
        variant='outlined'
        name={name}
        value={value}
        color={useTheme().palette.primary.main}
        type='time'
        required={required}
        size={size}
        placeholder={placeholder}
        max='15:00'
        InputProps={{
          max: '15:00',
          style: {
            borderRadius: 6,
            maxWidth: 480,
            width: width,
            height: 39,
            color: useTheme().palette.text.primary,
            fontSize: 13,
            backgroundColor: useTheme().palette.common.white,
            '&::placeholder': {
              color: useTheme().palette.text.primary,
              fontSize: 13
            },
            '&::input': {
              height: 2
            }
          }
        }}
        onChange={onChange}
        {...other}
        {...(error && { error: true, helperText: error })}
      />
    </FormControl>
  )
}
