import React from 'react'
import { Typography, Paper, makeStyles, Grid } from '@material-ui/core'

export interface TitleProps {
  title?: string
  height: number
  width: number
  backgroundColor: string
}

const useStyles = makeStyles((theme) => ({
  tile: {
    color: '#333333',
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 10,
    paddingTop: 10
  },

  innerOrder: {
    color: '#333333',
    fontSize: 12,
    fontWeight: 'normal',
    marginLeft: 10,
    paddingTop: 10,
    textAlign: 'left'
  },

  rightOrder: {
    color: '#333333',
    fontSize: 12,
    fontWeight: 'bold',
    // marginRight: 10,
    paddingTop: 10,
    textAlign: 'right'
  },

  service: {
    color: '#333333',
    fontSize: 12,
    fontWeight: 'bold',
    marginRight: 4,
    // paddingTop: 10,
    textAlign: 'right'
  },

  OrderTotalLabel: {
    color: '#4543d9',
    fontSize: 24,
    fontWeight: 600,
    marginLeft: 10,
    paddingTop: 20,
    textAlign: 'left'
  },

  OrderTotal: {
    color: '#4543d9',
    fontSize: 24,
    fontWeight: 600,
    marginRight: 4,
    paddingTop: 20,
    textAlign: 'right'
  }
}))

export const WelloOrderCard: React.FC<TitleProps> = ({
  title,
  width,
  height,
  backgroundColor
}: TitleProps) => {
  const classes = useStyles()
  return (
    <Paper
      style={{
        height: height,
        width: width,
        backgroundColor: backgroundColor,
        borderRadius: 6
      }}
    >
      <Typography className={classes.tile}>{title}</Typography>
      <Grid container direction='row' alignItems='center'>
        <Grid item>
          <Typography className={classes.innerOrder}>
            {' '}
            In-person cardiologist visit{' '}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.rightOrder}> ₹1,000.00 </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.innerOrder}> Service Fee </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography className={classes.service}> ₹1,00.00 </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.innerOrder}>
            {' '}
            GST &nbsp;&nbsp;&nbsp;
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography className={classes.service}> ₹1,00.00 </Typography>
        </Grid>

        <Grid item>
          <Typography className={classes.OrderTotalLabel}>
            {' '}
            Order Total &nbsp;
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.OrderTotal}> ₹1200.00 </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
