import React from 'react'
import {
  FormControl,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  makeStyles,
  useTheme,
  Box
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography/Typography'

const AddressType = [
  { id: 'home', title: 'Home' },
  { id: 'office', title: 'Office' }
]
export interface IProps {
  name?: 'addressType'
  label: string
  type?: any
  value?: any
  error?: null | undefined
  onChange?: any
  options?: any
  required: boolean
  width?: number
}

export const WelloType: React.FC<IProps> = ({
  name,
  label,
  type,
  value,
  error,
  onChange,
  options,
  width,
  required
}: IProps) => {
  return (
    <FormControl
      variant='outlined'
      {...(error && { error: true })}
      size='small'
      // className={classes.select}
    >
      <Box py={1} px={0.25} display='flex' flexDirection='row'>
      <Typography
        style={{
          color: useTheme().palette.text.primary,
          textTransform: 'uppercase'
        }}
        variant='subtitle2'
      >
        &nbsp;&nbsp;{label}
      </Typography>
      </Box>
      <MuiSelect
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        required={false}
        style={{
          borderRadius: 6,
          color: useTheme().palette.text.primary,
          width: width,
          fontSize: 13,
          backgroundColor: useTheme().palette.common.white,
          padding: 2
        }}
        placeholder='Select Options'
        displayEmpty
      >
        <MenuItem dense value='Other' disabled>
          {type}
        </MenuItem>
        {AddressType.map((addressType: any) => (
          <MenuItem dense key={addressType.id} value={addressType.id}>
            {addressType.title}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}
