import React from 'react'
import {
  FormControl,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  makeStyles,
  useTheme
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography/Typography'
import dotIcon from '../../../assets/images/dot_icon.png'

const Nationality = [{ id: 'india', title: 'India' }]

export interface IProps {
  name?: 'nationality'
  label: string
  type?: any
  value?: any
  error?: null | undefined
  onChange?: any
  options?: any
  required: boolean
  width?: number
}

export const WelloSelectNationality: React.FC<IProps> = ({
  name,
  label,
  type,
  value,
  error,
  onChange,
  options,
  width,
  required
}: IProps) => {
  return (
    <FormControl
      variant='outlined'
      {...(error && { error: true })}
      size='small'
    >
      <Typography
        style={{
          color: useTheme().palette.text.primary,
          textTransform: 'uppercase'
        }}
        variant='subtitle2'
      >
        &nbsp;&nbsp;{label} &nbsp;{' '}
        {value !== undefined && value.length > 0 && (
          <img src={dotIcon} alt='icon' height='10'></img>
        )}
      </Typography>
      <MuiSelect
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        required={false}
        style={{
          borderRadius: 6,
          color: useTheme().palette.text.primary,
          maxWidth: 480,
          width: width,
          fontSize: 13,
          backgroundColor: useTheme().palette.common.white,
          padding: 1
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        displayEmpty
      >
        <MenuItem value='' disabled>
          {type}
        </MenuItem>
        {Nationality.map((national: any) => (
          <MenuItem key={national.id} value={national.id}>
            <div style={{ display: 'inline-flex' }}>
              <img
                src='https://image.similarpng.com/very-thumbnail/2020/06/India-flag-icon-on-transparent-background-PNG.png'
                alt='img'
                height='13'
                width='30'
              />{' '}
              &nbsp; {national.title}
            </div>
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}
