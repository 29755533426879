import React from 'react'
import { makeStyles, Paper, Typography } from '@material-ui/core'

interface TopProps {
  label: string
  count?: number
  onSelectChange: (count: number) => void
}

const useStyles = makeStyles((theme) => ({
  disablePaperNotification: {
    padding: theme.spacing(0),
    paddingTop: 3,
    textAlign: 'center',
    // bgcolor:"text.hint",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: 35,
    minHeight: 10
  },

  recent: {
    color: theme.palette.primary.main
  }
}))

export const PatientListTopComponent: React.FC<TopProps> = ({
  label = 'Patients',
  count = 0,
  onSelectChange
}: TopProps) => {
  const classes = useStyles()
  const [menuCount, setMenuCount] = React.useState(50)
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setMenuCount(event.target.value as number)
    onSelectChange(event.target.value as number)
  }

  return (
    <div style={{ display: 'flex' }}>
      &nbsp; &nbsp;{' '}
      <Paper className={classes.disablePaperNotification}>{count}</Paper>
      <Typography align='left' variant='h6' className={classes.recent}>
        &nbsp;{label} &nbsp;
        {/* &nbsp;&nbsp; <Chip icon={<HighlightOffIcon />} label="This Week" variant="default" color="default"/> <Chip icon={<AddIcon />} label="Add Filter" variant="outlined" color="primary"/> */}
      </Typography>
    </div>
  )
}
