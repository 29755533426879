/* eslint-disable react/jsx-key */
// eslint-disable-next-line no-use-before-define
import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { ScheduleView } from './ScheduleView'

interface patientListProps {
  scheduleData: any[]
  date?: string
}

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1
  },
  display: {
    padding: theme.spacing(1)
  },
  paperNotification: {
    padding: 3,
    textAlign: 'center',
    backgroundColor: '#4543d9',
    color: '#FFFFFF'
  },
  root: {
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    minWidth: 1305,
    minHeight: 500
  },
  distance: {
    marginTop: 10
  }
}))

export const DoctorListview: React.FC<patientListProps> = ({
  scheduleData,
  date
}: patientListProps) => {
  const classes = useStyles()

  return (
    <div>
      <Typography align='left' variant='h6' color='textSecondary'>
        &nbsp;&nbsp;{date}{' '}
      </Typography>
      {scheduleData.map((value, i) => (
        <React.Fragment>
          <ScheduleView
            time={
              scheduleData[i].start < 12
                ? `Morning`
                : scheduleData[i].start >= 12 && scheduleData[i].start <= 17
                ? `Afternoon`
                : scheduleData[i].time >= 17 && scheduleData[i].time <= 24
                ? `Evening`
                : `Evening`
            }
            doctorData={scheduleData[i].drData}
          />
          <div className={classes.distance}></div>
        </React.Fragment>
      ))}
    </div>
  )
}
