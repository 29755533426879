import React from 'react'
import { Typography, Paper, makeStyles } from '@material-ui/core'

export interface TitleProps {
  title?: string
  height: number
  width: number
}

const useStyles = makeStyles((theme) => ({
  tile: {
    color: '#ffffff',
    fontSize: 16,
    fontWeight: 600,
    marginLeft: 10
  }
}))

export const LeftOptions: React.FC<TitleProps> = ({
  title,
  width,
  height
}: TitleProps) => {
  const classes = useStyles()
  return (
    <Paper
      style={{
        maxHeight: height,
        maxWidth: width,
        minHeight: height,
        minWidth: width,
        backgroundColor: '#dcdcdc'
      }}
    >
      <br></br>
      <Typography className={classes.tile}>{title}</Typography>
    </Paper>
  )
}
