import React from 'react'
import { makeStyles, Box, Grid, Typography, Paper } from '@material-ui/core'

interface IProps {
  isSelected?: boolean
  textTitle?: string
  onClick?: () => void
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0
  },

  selectedPaper: {
    padding: theme.spacing(0),
    textAlign: 'center',
    backgroundColor: '#ffffff',
    height: 29.4,
    width: 82,
    display: 'table-cell',
    verticalAlign: 'middle'
  },

  disabledPaper: {
    padding: theme.spacing(0),
    textAlign: 'center',
    backgroundColor: '#cccccc',
    height: 29.4,
    width: 82,
    display: 'table-cell',
    verticalAlign: 'middle'
  },

  menuStyle: {
    fontWeight: 'normal',
    fontSize: 12,
    height: 17,
    textTransform: 'uppercase',
    color: '#3c3c3c'
  },

  disableMenuStyle: {
    fontWeight: 'normal',
    fontSize: 12,
    height: 17,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#3c3c3c'
  },
  distance: {
    marginTop: 6
  }
}))

export const TabComp: React.FC<IProps> = ({
  isSelected = true,
  textTitle = 'text',
  onClick
}: IProps) => {
  const classes = useStyles()

  return (
    <div onClick={onClick}>
      <Grid
        container
        direction='column'
        justify='center'
        alignItems='center'
        spacing={2}
      >
        <Grid
          container
          direction='row'
          justify='center'
          alignItems='center'
          spacing={0}
        >
          <Grid item>
            <Paper style={{ width: 200 }}>
              {isSelected && (
                <React.Fragment>
                  <div className={classes.distance}></div>
                  <Paper className={classes.selectedPaper}>
                    <Typography className={classes.menuStyle}>
                      <Box
                        borderRight={0}
                        borderColor='#cccccc'
                        style={{ height: 38, paddingTop: 2 }}
                      >
                        {textTitle}{' '}
                      </Box>
                    </Typography>
                  </Paper>
                </React.Fragment>
              )}

              {isSelected === false && (
                <React.Fragment>
                  <div className={classes.distance}></div>
                  {textTitle === 'Schedule' && (
                    <Paper className={classes.disabledPaper}>
                      <Typography className={classes.disableMenuStyle}>
                        <Box
                          borderRight={0}
                          borderColor='grey.500'
                          style={{ height: 38, paddingTop: 3 }}
                        >
                          {textTitle}
                        </Box>
                      </Typography>
                    </Paper>
                  )}
                  {textTitle !== 'Schedule' && (
                    // <Box
                    //   borderRight={0}
                    //   borderColor='grey.500'
                    //   style={{ height: 15, paddingTop: 3 }}
                    // >
                    <Paper className={classes.disabledPaper}>
                      <Typography className={classes.disableMenuStyle}>
                        <Box
                          borderRight={2}
                          borderColor='#cccccc'
                          style={{ height: 38, paddingTop: 2 }}
                        >
                          {textTitle}
                        </Box>
                      </Typography>
                    </Paper>
                    // </Box>
                  )}
                </React.Fragment>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
