import { Box, TextField, Typography, TextFieldProps } from '@material-ui/core'

import React from 'react'
import WelloFormItemLabel from './WelloFormItemLabel'

interface WelloEmailFieldProps {
  title: string
  textProps: TextFieldProps
  displayDoneLabel?: boolean
}

export const WelloEmailField: React.FunctionComponent<WelloEmailFieldProps> = (
  props: WelloEmailFieldProps
) => {
  return (
    <Box display={'flex'} flexDirection='column' flexGrow={1}>
      <WelloFormItemLabel
        title={props.title}
        displayDoneLabel={props.displayDoneLabel}
      />
      <Box>
        <TextField
          variant='outlined'
          size='small'
          fullWidth
          type='email'
          {...props.textProps}
        ></TextField>
      </Box>
    </Box>
  )
}
