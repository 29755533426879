import React from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles
} from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'

interface Data {
  name: string | undefined
  gender: string
  medicalQualification: string[]
  yearsOfExperience: string
  profileDescription: string
  testimonials: string
  languages: any[]
  regNo: string
}

type Order = 'asc' | 'desc'

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.primary.main,
      wordWrap: 'break-word'
    },
    column: {
      wordWrap: 'break-word'
    },
    body: {
      fontSize: 20,
      fontWeight: 'bold',
      fontFamily: 'OpenSans-Bold'
    }
  })
)(TableCell)

const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: true,
    disablePadding: true,
    label: 'Name'
  },
  {
    id: 'gender',
    numeric: false,
    disablePadding: false,
    label: 'Gender'
  },

  {
    id: 'medicalQualification',
    numeric: false,
    disablePadding: false,
    label: 'Medical Qualification'
  },

  {
    id: 'yearsOfExperience',
    numeric: false,
    disablePadding: false,
    label: 'Years Of Experience'
  },

  {
    id: 'profileDescription',
    numeric: false,
    disablePadding: false,
    label: 'Description'
  },

  {
    id: 'testimonials',
    numeric: false,
    disablePadding: false,
    label: 'Testimonials'
  },

  {
    id: 'regNo',
    numeric: false,
    disablePadding: false,
    label: 'Registration Number'
  },
  {
    id: 'languages',
    numeric: false,
    disablePadding: false,
    label: 'Languages'
  }
]

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void
  order: Order
  orderBy: string
}

function TableHeader(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'desc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label} &nbsp;
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const useStyles = makeStyles({
  table: {
    minWidth: 1305
  },
  dotIcon: {
    height: 12
  },

  image: {
    width: 30,
    height: 30
  },
  overlappimg: {
    height: 20,
    // width: 20,
    align: 'center'
  },
  container: {
    maxHeight: 425
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
})

export default TableHeader
