import React, { useState } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Popup from '../../PrmitiveComponent/component/Helper/Popup'
import { WelcomeModal } from '../../LayoutComponent/Welcome/SubComponent/WelcomeModal'
import { theme } from '../../Common/Theme/theme'

export interface IwelcomePageProps {
  appTitle: string
  unitType?: string
  orgName?: string
  imageName?: string
  logoName?: string
  title: string
  listData: string[]
  onClickContinue: () => void
}

const useStyles = makeStyles((theme) => ({
  control: {
    padding: theme.spacing(2)
  },
  paperContainer: {
    maxWidth: 1325,
    maxHeight: 600
    // backgroundImage: `url($imageName)`
  }
}))

export const Welcome: React.FC<IwelcomePageProps> = ({
  appTitle,
  unitType,
  orgName,
  imageName,
  title,
  listData,
  logoName,
  onClickContinue
}: IwelcomePageProps) => {
  const classes = useStyles()

  return (
    <WelcomeModal
      appTitle={appTitle}
      unitType={unitType}
      orgName={orgName}
      title={title}
      listData={listData}
      onClickContinue={onClickContinue}
    />
  )
}
