import React from 'react'
import {
  FormControl,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  makeStyles,
  useTheme
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography/Typography'
import dotIcon from '../../../assets/images/dot_icon.png'

const GenderList = [
  { id: 'male', title: 'Male' },
  { id: 'female', title: 'Female' },
  { id: 'other', title: 'Other' },
  { id: 'unknown', title: 'Unknown' }
]

export interface IProps {
  name?: string
  label: string
  type?: any
  value?: any
  error?: null | undefined
  onChange?: any
  options?: any
  required: boolean
  width?: number
}

const useStyles = makeStyles((theme) => ({
  select: {
    margin: theme.spacing(1),
    minWidth: 145,
    width: 'auto'
  },
  label: {
    color: '#4d4d4d',
    fontSize: '0.8em'
  },

  inputBoxStyle: {
    borderRadius: 6,
    color: '#4d4d4d',
    // height: 38,
    fontSize: 13,
    backgroundColor: '#ffffff'
  }
}))

export const WelloSelectDoctor: React.FC<IProps> = ({
  name,
  label,
  type,
  value,
  error,
  onChange,
  options,
  width,
  required
}: IProps) => {
  const classes = useStyles()
  return (
    <FormControl
      variant='outlined'
      {...(error && { error: true })}
      // className={classes.select}
    >
      <Typography
        style={{
          color: useTheme().palette.text.primary,
          textTransform: 'uppercase'
        }}
        variant='h4'
      >
        &nbsp;&nbsp;{label} &nbsp;
        {value !== undefined && value.length > 0 && (
          <img src={dotIcon} alt='icon' height='10'></img>
        )}
      </Typography>
      <MuiSelect
        name={name}
        id={name}
        value={value}
        onChange={onChange}
        required={false}
        style={{
          borderRadius: 6,
          color: useTheme().palette.text.primary,
          maxWidth: 480,
          // height: 38,
          width: width,
          fontSize: 13,
          backgroundColor: useTheme().palette.common.white
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        displayEmpty
      >
        <MenuItem dense value=''>
          {type}
        </MenuItem>
        {options.map((option: any) => (
          <MenuItem dense key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}
