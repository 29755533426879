import React from 'react'
import { TextField, FormControl, Box } from '@material-ui/core'
import { makeStyles, ThemeProvider, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { theme } from '../../Common/Theme/theme'
import dotIcon from '../../../assets/images/dot_icon.png'

const useStyles = makeStyles((theme) => ({
  inputBoxStyle: {
    height: 2
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        backgroundColor: 'white',
        padding: '1px 14px'
      },
      input: {
        backgroundColor: 'white',
        padding: '1px 14px'
      }
    },
    MuiInputBase: {
      input: {
        backgroundColor: 'white'
      }
    }
  }
}))

export interface IProps {
  name: string
  label?: string
  type?: 'text' | 'number' | 'date' | 'email'
  placeholder?: string
  value?: any
  error?: null | undefined
  onChange?: any
  required: boolean
  size?: 'small' | 'medium' | 'large'
  width?: number
  disabled?: boolean
  charsAllowed: number
}

export const WelloInput: React.FC<IProps> = ({
  name,
  label,
  type = 'text',
  value,
  error,
  onChange,
  placeholder,
  size,
  width,
  required = false,
  disabled,
  charsAllowed
}: IProps) => {
  const classes = useStyles()
  return (
    <FormControl {...(error && { error: true })}>
      <Box py={1} px={0.25} display='flex' flexDirection='row'>
        <Typography
          style={{
            color: useTheme().palette.text.primary,
            fontFamily: 'Open Sans',
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 'normal',
          }}
          variant='subtitle2'
        >
          &nbsp;&nbsp;{label} &nbsp;
          {value !== undefined &&
            value.length > 0 &&
            type === 'text' &&
            value.length <= charsAllowed &&
            !value.trim() === false && (
              <img src={dotIcon} alt='icon' height='10'></img>
            )}
          {value !== undefined && value > 0 && type === 'number' && (
            <img src={dotIcon} alt='icon' height='10'></img>
          )}
        </Typography>
      </Box>
      <TextField
        hintText='For example, Coca-Cola'
        underlineShow={false}
        id={name}
        variant='outlined'
        name={name}
        value={value}
        disabled={disabled}
        fullWidth
        color={useTheme().palette.primary.main}
        type={type}
        required={required}
        size={size}
        rows={3}
        placeholder={placeholder}
        className={classes.overrides}
        // InputProps={{ classes: { input: classes.inputBoxStyle,width:10  }}}
        InputProps={{
          style: {
            borderRadius: 6,
            maxWidth: 1500,
            fullWidth: true,
            width: width,
            // height: 39,
            padding: '1px',
            color: useTheme().palette.text.primary,

            spellcheck: true,
            backgroundColor: useTheme().palette.common.white,
            '&::placeholder': {
              color: useTheme().palette.text.primary,
              fontSize: 12
            },
            '&::input': {
              height: 2
            }
          }
        }}
        onChange={onChange}
        {...(error && { error: true, helperText: error })}
      />
    </FormControl>
  )
}
