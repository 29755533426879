import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

interface TopProps {
  label: string
  count?: number
}
const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1
  },
  display: {
    padding: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.primary.main,
    textAlign: 'center',
    color: theme.palette.common.white
  },
  disablePaperNotification: {
    padding: theme.spacing(0),
    paddingTop: 5,
    textAlign: 'center',
    // bgcolor:"text.hint",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: 35,
    minHeight: 10
  },

  recent: {
    color: theme.palette.primary.main
  },
  right: {
    marginLeft: 'auto'
  },

  underline: {
    '&&&:before': {
      borderBottom: 'none',
      alignContent: 'center',
      textAlign: 'center'
    },
    '&&:after': {
      borderBottom: 'none'
    }
  }
}))

export const TopComponent: React.FC<TopProps> = ({
  label = '',
  count = 0
}: TopProps) => {
  const classes = useStyles()

  return (
    <div style={{ display: 'flex' }}>
      &nbsp; &nbsp;{' '}
      <Paper className={classes.disablePaperNotification}>{count}</Paper>
      <Typography align='left' variant='h6' className={classes.recent}>
        &nbsp;{label} &nbsp;
      </Typography>
    </div>
  )
}
