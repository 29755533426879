/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Divider,
  Grid,
  makeStyles,
  Paper,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme
} from '@material-ui/core'
import React from 'react'
import {
  validateDoctorName,
  validateStartDate,
  validateEndTime,
  validateStartTime,
  validateSlotDuration,
  validateEndDate,
  validateTimeLimit
} from '../../../../utils/formValidators/formValidators'
import { Controls } from '../../../PrmitiveComponent/component/Controls'
import {
  Form,
  UseForm
} from '../../../PrmitiveComponent/component/Helper/UseForm'
import moment from 'moment'

export const slotDuration = () => [
  { id: '15', title: '15 min' },
  { id: '30', title: '30 min' },
  { id: '45', title: '45 min' },
  { id: '60', title: '1 hr 0 min' }
]

export const docList = () => [{ id: '1', title: 'Dr Raman Jha' }]

export interface IFormLabels {
  nameLabel?: string
  startDateLabel?: string
  endDateLabel?: string
  slotDurationLabel?: string
  startTimeLabel?: string
  endTImeLabel?: string
}
export interface IUnitAdmin {
  children?: React.ReactNode
  count: number
  submitButtonText?: string
  resetButtonText?: string
  doctorList?: any[]
  onValidSubmit?: (doctorSchedule: any[]) => void
  onClickToCancel: (val: boolean) => void
  formLabels?: IFormLabels
  popupSate: boolean
}

const errorValues = {
  doctorId: '',
  startDate: '',
  endDate: '',
  slotDuration: '',
  startTime: '',
  endTime: ''
}

function getInitializeValues() {
  const initialFValues = {
    id: '0',
    doctorId: '',
    dummyStart: '',
    dummyEnd: '',
    startDate: '',
    endDate: '',
    dummyStartTime: '',
    dummyEndTime: '',
    startTime: '',
    endTime: '',
    slotDuration: '',
    services: ['consultation', 'telephone', 'video_call']
  }
  return initialFValues
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },

  mainPage: {
    maxWidth: 960,
    height: 'auto',
    backgroundColor: '#cdcdcd',
    borderRadius: 6,
    overflow: 'Auto'
  },

  buttonStyle: {
    fontSize: 14,
    fontWeight: 600,
    display: 'inline'
  },

  distance: {
    marginTop: 10
  },
  topHeader: {
    color: theme.palette.common.white,
    marginBottom: 5
  }
}))

export const CreateDoctorSchedule: React.FC<IUnitAdmin> = ({
  children,
  count,
  submitButtonText,
  resetButtonText,
  onClickToCancel,
  onValidSubmit,
  doctorList,
  formLabels = {},
  popupSate = false
}: IUnitAdmin) => {
  const classes = useStyles()
  const initialFValues: any = getInitializeValues()

  function validate(fieldValues = values) {
    const temp = { ...errors }
    console.log('data' + values)
    if ('doctorId' in fieldValues) {
      temp.doctorId = validateDoctorName(fieldValues.doctorId, true)
    }
    if ('dummyStart' in fieldValues) {
      temp.dummyStart = validateStartDate(fieldValues.dummyStart, true)
    }
    if ('dummyEnd' in fieldValues)
      temp.dummyEnd = validateEndDate(fieldValues.dummyEnd, true)

    if ('dummyStartTime' in fieldValues)
      temp.dummyStartTime = validateStartTime(fieldValues.dummyStartTime, true)

    if ('dummyEndTime' in fieldValues)
      temp.dummyEndTime = validateEndTime(fieldValues.dummyEndTime, true)

    if ('dummyEndTime' in fieldValues && 'dummyStartTime' in fieldValues) {
      temp.dummyEndTime = validateTimeLimit(
        fieldValues.dummyEndTime,
        fieldValues.dummyStartTime,
        true
      )
    }

    if ('slotDuration' in fieldValues)
      temp.slotDuration = validateSlotDuration(fieldValues.slotDuration, true)

    setErrors({
      ...temp
    })
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '')
    console.log('here 5')
  }

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    handleStartDateChangeForSchedule,
    handleEndDateChangeForSchedule,
    handleStartTimeChangeForSchedule,
    handleEndTimeChangeForSchedule,
    resetForm
  } = UseForm(initialFValues, errorValues, true, validate)

  function handleSubmit(e: any) {
    console.log('In validation')
    e.preventDefault()
    if (validate()) {
      console.log('validation success')

      const mappingData: any[] = []
      const start = new Date(values.dummyStart) //YYYY-MM-DD
      const end = new Date(values.dummyEnd) //YYYY-MM-DD
      const arr: any[] = []
      const dt = new Date(start)
      while (dt <= end) {
        arr.push(new Date(dt))
        dt.setDate(dt.getDate() + 1)
      }
      for (let i = 0; i < arr.length; i++) {
        const stDate = moment(arr[i]).format('YYYY-MM-DD')
        const dateFormat = stDate + 'T' + '00:00:00.000Z'
        const formData = {
          date: dateFormat,
          doctorId: values.doctorId,
          sessions: [
            {
              id: '',
              startTime: values.startTime,
              endTime: values.endTime,
              slotDuration: values.slotDuration,
              services: ['consultation', 'telephone', 'video_call']
            }
          ]
        }
        mappingData.push(formData)
      }
      console.log('Converted form value')
      console.log(mappingData)
      if (mappingData) {
        if (onValidSubmit) onValidSubmit(mappingData)
        resetForm()
      } else {
        console.log('validation error')
      }
    }
  }

  const cancel = () => {
    onClickToCancel(false)
    resetForm()
  }
  const handleClose = () => {
    onClickToCancel(false)
    resetForm()
  }

  return (
    <Dialog
      open={popupSate}
      maxWidth='md'
      fullWidth
      onClose={handleClose}
      disableBackdropClick
    >
      <DialogTitle
        id='scroll-dialog-title'
        style={{
          backgroundColor: useTheme().palette.primary.main,
          color: useTheme().palette.common.white,
          alignContent: 'center',
          textAlign: 'center',
          height: 48
        }}
      >
        <Typography variant='h6' className={classes.topHeader}>
          Add Schedule
        </Typography>
      </DialogTitle>
      <DialogContent
        dividers={true}
        style={{
          backgroundColor: useTheme().palette.background.default,
          padding: 0,
          overflowWrap: 'normal',
          overscrollBehaviorX: 'none',
          margin: 0
        }}
      >
        <Paper className={classes.mainPage} square={true} variant='outlined'>
          <div className={classes.distance}></div>
          <Form onSubmit={handleSubmit} title='Add Schedule'>
            <Grid container className={classes.root} spacing={0}>
              <Grid item xs={12}>
                <Grid container justify='center' spacing={0}>
                  <Grid key={1} item>
                    <Grid container className={classes.root} spacing={0}>
                      <Grid item xs={12}>
                        <Grid container justify='center'>
                          <Controls.WelloSelectDoctor
                            name='doctorId'
                            required={true}
                            label={formLabels?.nameLabel ?? 'Doctor Name'}
                            type='Select Doctor Name'
                            options={doctorList}
                            value={values.doctorId}
                            error={errors.doctorId}
                            width={480}
                            onChange={handleInputChange}
                          />
                        </Grid>
                      </Grid>

                      <br></br>
                      <Grid item xs={12}>
                        <div className={classes.distance}></div>
                        <Grid container justify='center'>
                          <Controls.WelloDatePicker
                            name='dummyStart'
                            label={formLabels?.startDateLabel ?? 'Start Date'}
                            placeholder='dd/mm/yyyy'
                            required={false}
                            value={values.dummyStart}
                            width={480}
                            min={moment().format('YYYY-MM-DD')}
                            max={'2023-01-01'}
                            error={errors.dummyStart}
                            onChange={handleStartDateChangeForSchedule}
                          />
                        </Grid>
                      </Grid>

                      <br></br>
                      <Grid item xs={12}>
                        <div className={classes.distance}></div>
                        <Grid container justify='center'>
                          {values.dummyStart.length === 0 && (
                            <Controls.WelloDatePicker
                              name='dummyEnd'
                              label={formLabels?.endDateLabel ?? 'End Date'}
                              placeholder='dd/mm/yyyy'
                              required={false}
                              value={values.dummyEnd}
                              width={480}
                              min={values.dummyStart}
                              max={'2023-01-01'}
                              disabled={true}
                              error={errors.dummyEnd}
                              onChange={handleEndDateChangeForSchedule}
                            />
                          )}
                          {values.dummyStart.length !== 0 && (
                            <Controls.WelloDatePicker
                              name='dummyEnd'
                              label={formLabels?.endDateLabel ?? 'End Date'}
                              placeholder='dd/mm/yyyy'
                              required={false}
                              value={values.dummyEnd}
                              width={480}
                              min={values.dummyStart}
                              max={'2023-01-01'}
                              error={errors.dummyEnd}
                              onChange={handleEndDateChangeForSchedule}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <div className={classes.distance}></div>
                    <Grid container className={classes.root} spacing={0}>
                      <Grid item xs={12}>
                        <Grid container justify='center'>
                          <Controls.WelloSelectForm
                            name='slotDuration'
                            required={true}
                            label={
                              formLabels?.slotDurationLabel ?? 'Slot Duration'
                            }
                            type='Select Slot Duration'
                            options={slotDuration()}
                            value={values.slotDuration}
                            error={errors.slotDuration}
                            width={236}
                            onChange={handleInputChange}
                          />
                          &nbsp; &nbsp;
                          <Controls.WelloTimePicker
                            name='dummyStartTime'
                            label={formLabels?.startTimeLabel ?? 'Start Time'}
                            placeholder='Start Time'
                            type='time'
                            value={values.dummyStartTime}
                            error={errors.dummyStartTime}
                            required={true}
                            width={236}
                            onChange={handleStartTimeChangeForSchedule}
                          />
                          &nbsp; &nbsp;
                          <Controls.WelloTimePicker
                            name='dummyEndTime'
                            label={formLabels?.endTImeLabel ?? 'End Time'}
                            placeholder='End Time'
                            type='time'
                            value={values.dummyEndTime}
                            error={errors.dummyEndTime}
                            required={true}
                            width={236}
                            onChange={handleEndTimeChangeForSchedule}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <div className={classes.footer}></div>
                <Divider />
                <div className={classes.distance}></div>
                <Grid container justify='flex-end'>
                  <div className={classes.buttonStyle}>
                    <Controls.WelloButton
                      type='button'
                      text={resetButtonText ?? 'Cancel (esc)'}
                      size='small'
                      variant='outlined'
                      backgroundColor='#fefefe'
                      color='#4543d9'
                      onClick={cancel}
                    />
                    <Controls.WelloButton
                      type='submit'
                      text={submitButtonText ?? 'Add Schedule (Alt+S)'}
                      size='small'
                      variant='outlined'
                      backgroundColor='#4543d9'
                      color='#cdcdcd'
                    />
                  </div>
                </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#cdcdcd' }}>
        <Controls.WelloButton
          type='button'
          text={resetButtonText ?? 'Cancel (esc)'}
          size='small'
          id='cancel'
          variant='outlined'
          backgroundColor='#fefefe'
          color='#4543d9'
          onClick={cancel}
        />
        <Controls.WelloButton
          type='button'
          text={submitButtonText ?? 'Add Schedule (Alt+S)'}
          size='small'
          id='add_schedule'
          variant='outlined'
          backgroundColor='#4543d9'
          color='#cdcdcd'
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  )
}
