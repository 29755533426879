/* eslint-disable react/jsx-key */
import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { AppointmentTypeMenu } from './AppointmentTypeMenu'
import { Paper, Typography, Box } from '@material-ui/core'

interface TabProps {
  preSelectedOptions: any[]
  onTabChanges: (selectedTab: string) => void
  menu: string[]
  menuLabel: string
  onValuesChanged: (selectedSpecializations: any[]) => void
}
const useStyles = makeStyles((theme) => ({
  paperHeight: {
    height: 36
  }
}))

export const AppType: React.FC<TabProps> = ({
  preSelectedOptions,
  onTabChanges,
  menu,
  menuLabel,
  onValuesChanged
}: TabProps) => {
  const classes = useStyles()
  const [selectedOptions, setSelectedOptions] = React.useState(
    preSelectedOptions
  )

  const handleChange = (selectedTab: string) => {
    onTabChanges(selectedTab)
    if (selectedOptions.includes(selectedTab)) {
      if (selectedOptions.length === 1) {
      } else {
        const index = selectedOptions.indexOf(selectedTab)
        selectedOptions.splice(index, 1)
      }
    } else {
      selectedOptions.push(selectedTab)
    }
    onValuesChanged(selectedOptions)
  }

  return (
    <div>
      <Box>
        <Typography
          style={{
            fontSize: 12,
            fontWeight: 600,
            color: useTheme().palette.common.black,
            textTransform: 'uppercase'
          }}
        >
          &nbsp; {menuLabel}
        </Typography>
      </Box>
      <Paper
        className={classes.paperHeight}
        style={{ backgroundColor: useTheme().palette.grey[300] }}
      >
        <Grid container direction='row'>
          {menu.map((value, i) => (
            <Grid item>
              <AppointmentTypeMenu
                textTitle={value}
                isSelected={selectedOptions.some(function (el: any) {
                  return el === value
                })}
                onClick={() => handleChange(value)}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </div>
  )
}
