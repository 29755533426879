import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import {
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Icon,
  Typography,
  Box,
  Card,
  CardContent,
  Grid
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  padding: {
    paddingBottom: '30px'
  }
}))

export const TopMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const classes = useStyles()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        // aria-controls='simple-menu'
        // aria-haspopup='true'
        onClick={handleClick}
        style={{
          color: useTheme().palette.common.black
        }}
        endIcon={<ArrowDropDownIcon></ArrowDropDownIcon>}
      >
        <Typography variant='subtitle1'>All Doctors</Typography>
      </Button>
    </div>
  )
}
