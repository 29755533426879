import React from 'react'
import { TextField, FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  inputBoxStyle: {
    borderRadius: 6,
    width: '100%',
    spellcheck: true,
    boxShadow: 'inset 0 0 3 0 rgba(0, 0, 0, 0.41)',
    backgroundColor: '#ffffff',
    color: '#4d4d4d',
    '&::placeholder': {
      color: '#4d4d4d'
    }
  },

  label: {
    color: '#4d4d4d'
  }
}))

export interface IProps {
  name: string
  label: string
  type?: 'text' | 'number' | 'date' | 'email'
  placeholder?: any
  value?: any
  error?: null | undefined
  onChange?: any
  required: boolean
  size?: 'small' | 'medium' | 'large'
  [other: string]: any
}

export const WelloInputBox: React.FC<IProps> = ({
  name,
  label,
  type = 'text',
  value,
  error,
  onChange,
  placeholder,
  size,
  other
}: IProps) => {
  const classes = useStyles()
  return (
    <FormControl fullWidth>
      <Typography className={classes.label} variant='subtitle2'>
        &nbsp;&nbsp;{label}
      </Typography>
      <TextField
        underlineShow={false}
        id={name}
        variant='outlined'
        name={name}
        placeholder={placeholder}
        value={value}
        color='#4543d9'
        required
        size='large'
        InputProps={{ classes: { input: classes.inputBoxStyle } }}
        onChange={onChange}
        {...other}
        {...(error && { error: true, helperText: error })}
      />
    </FormControl>
  )
}
